import { useHistory } from "react-router-dom/cjs/react-router-dom";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  UilAngleDown,
  UilAngleUp,
  UilSearchMinus,
  UilSearchPlus,
  UilLocationPinAlt
} from "@iconscout/react-unicons";
import axios from "axios";
import loading from "../../assets/ecommerce/loading.gif";
import { branches } from "../../helpers/branches";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Vimeo from "@u-wave/react-vimeo";
const API = "https://labopat-5792e.appspot.com/";

const StyledForDesktop = styled.div`
  position: relative;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  @media only screen and (max-width: 700px) {
    padding: 25px;
  }
`;

const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-top: 20px;
  @media only screen and (min-width: 993px) {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const RegularP = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const PostalInput = styled(Form.Control)`
  position: unset !important;
  width: 150px;
  height: 61px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 12px;
  background: #f6f6f6 !important;
  border: none;
  border-radius: 0px 12px 12px 0px !important;
  height: 50px;
  margin-right: 16px;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
  }
`;

const PostalButton = styled(Button)`
  z-index: 0 !important;
  font-weight: 800;
  width: 130px;
  height: 48px;
  background: linear-gradient(51deg, rgba(52,165,166,1) 0%, rgba(58,179,142,1) 23%, rgba(67,201,147,1) 56%, rgba(73,218,152,1) 72%, rgba(123,234,96,1) 92%, rgba(131,236,87,1) 96%);
  border: none;
  font-weight: 500;
  color: #fff;
  margin-left: 15px;
  font-size: 16px;
  transition: all 0.4s ease;
  border-radius: 10px !important;
  :hover {
    border: 2px solid #30DF99;
    transition: all 0.4s ease;
    background: #fff;
    color: #30DF99;
  }
  :disabled {
    border: none;
    background: #e2e2e2;
    color: #A6A6A6;
  }
  :focus {
    background: #00ad90;
  }
`;

const Nearest = styled.div`
  background: #dbf1ff;
  border-radius: 12px;
  padding: 12px 16px;
  > p {
    margin-bottom: 4px;
  }
  > :nth-child(1) {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #3b3b3b;
  }
  > :nth-child(2) {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #3b3b3b;
  }
  > div div p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline;
    > a {
      color: #00ad90;
    }
  }
  > div div p:hover {
    text-decoration: underline;
    color: #3eb8b2;
  }
  > div {
    padding-left: 0;
    > div {
      padding-left: 0;
    }
  }
`;

const ButtonMenu = styled.button`
  width: 100%;
  text-align: left;
  color: black;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  border: none;
  margin-bottom: 20px;
`;

const StyledPlaces = styled.div`
cursor: pointer;
  .simple {
    background: none !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  a {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0cac8c;
    :hover {
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 576px) {
    #desc {
      padding-left: 0px;
      padding-right: 20px;
    }
    #col-llegar {
      padding-left: 0px !important;
      padding-right: 20px;
    }
  }
  > div > div > div {
    position: relative;
    @media only screen and (max-width: 576px) {
      padding-bottom: 80px;
    }
  }
  .phone-link {
    color: #0cac8c;
    font-weight: 700;
    padding-left: 0px;
    @media only screen and (max-width: 576px) {
      margin-top: 5px;
    }
    @media only screen and (min-width: 576px) {
      position: absolute;
      top: 3px;
      right: 20px;
      width: auto;
      display: flex;
    }
  }
  .form-check-input {
    display: none !important;
  }
  .form-check {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    padding-left: 0;
  }
`;

const StyledImg = styled.div`
  padding: 12px;
  position: absolute;
  top: ${(props) => (props.photo ? "-60" : "-470")}px;
  right: -100px;
  z-index: 1;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
  transition: all ease 0.4;
  width: auto;
  img {
    width: 426px;
  }
  @media only screen and (max-width: 600px) {
    display: ${(props) => (props.photo ? "block" : "none")};
    right: 15px;
    top: -20px;
    img {
      width: 100%;
    }
  }
  #plus-icon {
    width: 41px;
    height: 41px;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    right: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
    cursor: pointer;
  }
`;

const MiniImage = styled.div`
  cursor: pointer;
  @media only screen and (max-width: 576px) {
    width: auto;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  #mini-image {
    position: relative;
    width: 70px;
    height: 72px;
    background: #f6f6f6;
    border-radius: 12px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #plus-icon {
    width: 41px;
    height: 41px;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 58px;
    height: 58px;
    border-radius: 10px;
  }
`;

const VideoContainer = styled.div`
    widht: 100%;
    max-width: 1920px;
    margin: 0 auto;
`;

export const Branches = () => {
  const [postal, setPostal] = useState("");
  const [nearestPlace, setNearestPlace] = useState();
  const [showPlaces, setShowPlaces] = useState(true);
  const [checking, setChecking] = useState(false);
  const [isShown, setIsShown] = useState(["", ""]);
  const [isShownPhoto, setIsShownPhoto] = useState(["", ""]);
  const { width } = useWindowDimensions();

  const findNearestPlace = async (zipCode) => {
    setChecking(true);
    let distance = 100000000;
    try {
      let request = axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=mexico+${zipCode}&key=AIzaSyD8p1E3vVMWsjaCpWxnqHQDb-2cktQSk4I`
      );
      let { data } = await request;
      const location = data.results[0].geometry.location;
      branches.map(async (branch) => {
        let response = await axios.get(
          API +
            `distance?place1x=${branch.coordinates[0]}&place1y=${branch.coordinates[1]}&place2x=${location.lat}&place2y=${location.lng}`
        );
        const tempDistance =
          response.data.data.rows[0].elements[0].distance.value;
        console.log(
          branch.name +
            " Distance: " +
            response.data.data.rows[0].elements[0].distance.value
        );
        if (tempDistance < distance) {
          distance = response.data.data.rows[0].elements[0].distance.value;
          setNearestPlace(branch);
        }
      });
      setChecking(false);
    } catch (error) {
      console.log(error);
      // dispatch(updateSpinner(false));
    }
  };
  const history = useHistory();
  const openBranchDetails = (branch) => {
    const { link } = branch
    history.push(`/sucursales${link}`);
    console.log("diste click en la branch", link);
  }
  return (
    <>
      <HelmetTitle title="Sucursales" name="description" content="content" />
      <StyledForDesktop>
        {checking && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: "100%",
              position: "absolute",
              background: "rgba(256, 256, 256, 0.8)",
            }}
          >
            <img src={loading} alt="loading" width={75} />
          </div>
        )}
        <Title>Sucursales</Title>
        {/*<RegularP>Encuentra la sucursal más cercana</RegularP>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <div className="input-group">
              <span
                className="input-group-text"
                id="basic-addon1"
                style={{
                  backgroundColor: "#f6f6f6",
                  border: "none",
                  borderBottomLeftRadius: "10px",
                  height: "50px",
                }}
              >
                <UilLocationPinAlt style={{ color: "#0CAC8C" }} />
              </span>

              <PostalInput
                type="number"
                onChange={(e) => {
                  setPostal(e.target.value);
                }}
                placeholder="Ingresa tu código postal aquí"
              />
              <PostalButton
                disabled={postal.length !== 5}
                onClick={() => findNearestPlace(postal)}
              >
                Aplicar
              </PostalButton>
            </div>
          </Row>
        </Form.Group>*/}
        {nearestPlace && (
          <Nearest>
            <p>{nearestPlace.name}</p>
            <p>{nearestPlace.address}</p>
            <p>
              <a
                href={nearestPlace.mapURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Abrir dirección en google maps
              </a>
            </p>
          </Nearest>
        )}
        {/*<Row style={{ marginTop: "10px" }}>
          <Col xs={5}>
            <hr />
          </Col>
          <Col
            xs={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            ó
          </Col>
          <Col xs={5}>
            <hr />
          </Col>
        </Row>*/}
        <ButtonMenu variant="light" onClick={() => setShowPlaces(!showPlaces)}>
          <Row style={{ marginTop: "10px" }}>
            <Col sm={10} xs={10} style={{ paddingLeft: "5px" }}>
              <p style={{ fontSize: "16px" }}> Ver sucursales disponibles</p>
            </Col>
            <Col sm={2} xs={2}>
              {showPlaces ? (
                <UilAngleUp size="32" color="#00AD90" />
              ) : (
                <UilAngleDown size="32" color="#00AD90" />
              )}
            </Col>
          </Row>
        </ButtonMenu>
        {showPlaces ? (
          <StyledPlaces >
            {branches.map((branch) => (
              <>
                <Row>
                  <Col xs={12} sm={10} onClick={() => openBranchDetails(branch)}>
                    <Form.Group controlId={`place${branch.id}`}>
                      <Form.Check
                        value={branch.id}
                        aria-label="radio 3"
                        label={branch.name}
                      />
                      <p
                        id="desc"
                        style={{
                          color: "#6A6A6A",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "0px",
                        }}
                      >
                        {branch.address}
                      </p>
                      <Row>
                        <a className="phone-link" href={`tel:${branch.phone}`}>
                          {branch.phone}
                        </a>
                        <Col
                          xs={12}
                          sm={6}
                          md={8}
                          style={{ position: "relative", paddingLeft: "0" }}
                        >
                          <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                            <a
                              href={branch.mapURL}
                              target="_blank"
                              rel="noopener noreferrer"
                              onMouseEnter={() => setIsShown([branch.name, 0])}
                              onMouseLeave={() => setIsShown(["", ""])}
                            >
                              Abrir dirección en google maps
                            </a>
                          </p>
                          {branch.image &&
                            isShown[0] === branch.name &&
                            isShown[1] === 0 && (
                              <StyledImg>
                                <img
                                  src={branch.image}
                                  alt="ubicacion sucursal"
                                />
                              </StyledImg>
                            )}
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={4}
                          id="col-llegar"
                          style={{ position: "relative", paddingLeft: "20px" }}
                        >
                          <p
                            style={{
                              marginTop: "5px",
                              marginBottom: "0px",
                              color: "#0CAC8C",
                            }}
                          >
                            <a
                              href={`https://www.waze.com/ul?ll=${branch.coordinates[0]},${branch.coordinates[1]}&navigate=yes&zoom=17`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onMouseEnter={() => setIsShown([branch.name, 1])}
                              onMouseLeave={() => setIsShown(["", ""])}
                            >
                              ¿Cómo llegar?
                            </a>
                          </p>
                          {branch.image &&
                            isShown[0] === branch.name &&
                            isShown[1] === 1 && (
                              <StyledImg style={{ right: "5px" }}>
                                <img
                                  src={branch.image}
                                  alt="ubicacion sucursal"
                                />
                              </StyledImg>
                            )}
                          {branch.photo &&
                            isShownPhoto[0] === branch.name &&
                            isShownPhoto[1] === 1 && (
                              <StyledImg photo={true}>
                                <img
                                  src={branch.photo}
                                  alt="ubicacion sucursal"
                                />
                                <div
                                  id="plus-icon"
                                  onClick={() => setIsShownPhoto(["", ""])}
                                >
                                  <UilSearchMinus size={28} color="#00AC90" />
                                </div>
                              </StyledImg>
                            )}
                        </Col>
                        {width < 585 && (
                          <MiniImage
                            onClick={() => setIsShownPhoto([branch.name, 1])}
                          >
                            <div id="mini-image">
                              <img alt="foto sucursal" src={branch.photo} />
                              <div id="plus-icon">
                                <UilSearchPlus size={28} color="#00AC90" />
                              </div>
                            </div>
                          </MiniImage>
                        )}
                      </Row>
                    </Form.Group>
                  </Col>
                  {width >= 585 && (
                    <Col sm={2}>
                      <MiniImage
                        onClick={() => setIsShownPhoto([branch.name, 1])}
                        style={{ marginTop: "10px" }}
                      >
                        <div id="mini-image">
                          <img alt="foto sucursal" src={branch.photo} />
                          <div id="plus-icon">
                            <UilSearchPlus size={28} color="#00AC90" />
                          </div>
                        </div>
                      </MiniImage>
                      <p
                        style={{
                          color: "#00AC90",
                          fontSize: "16px",
                          lineHeight: "24px",
                          marginTop: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsShownPhoto([branch.name, 1])}
                      >
                        Ver foto
                      </p>
                    </Col>
                  )}
                </Row>
                <hr />
              </>
            ))}
          </StyledPlaces>
        ) : null}
      </StyledForDesktop>
      <VideoContainer>
          {/* Video */}
          <Vimeo
              color='ffbc45'
              video='https://vimeo.com/735511086'
              responsive={true}
              autoplay loop muted
          />
      </VideoContainer>
    </>
  );
};
