import React from "react";
import { OurServices } from "./ourServices";
import { VideoAboutUs } from "./videoAboutUs";
import { MisionVision } from "./misionVision";
import MapComponent from "./mapComponent";
import OurValues from "./ourValues";
import ServicesNetwork from "./servicesNetwork";
import { Doubts } from "../doubts/doubts";
import { HelmetTitle } from '../../helpers/HelmetTitle';

const AboutUs = () => {
  return (
    <div style={{backgroundColor: "#F2F7F9"}}>
      <HelmetTitle
        title="Nosotros"
        name="description"
        content="content"
      />
      <VideoAboutUs />
      <OurServices />
      <MisionVision />
      <OurValues />
      <ServicesNetwork />
      <MapComponent/>
      <Doubts/>
    </div>
  )
};

export default AboutUs;