import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'react-bootstrap';
import { UilInfoCircle, UilHunting, UilEye } from '@iconscout/react-unicons';

const StyledRow = styled(Row)`
  img {
    height: auto !important;
    max-width: 95%;
    margin-top: -20%;
  }
  .item {
    padding: 19px 0 19px 53px;
    position: relative;
    width: 90%;
    max-width: 450px;
  }
  #second {
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
  }
  h1 {
    color: #474A4B;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: #474A4B;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
  }
  span {
    font-weight: 600;
  }
  .icon {
    position: absolute;
    left: 0;
    top: 19px;
  }
  .col-12 {
    display: flex;
    justify-content: center;
    align-items: start;
  }
  #text-col {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 992px) {
    p {
      font-size: 16px;
    }
    img {
      max-width: 85%;
      margin-top: 0;
    }
  }
`;

export const MisionVision = () => {
	return (
		<div style={{ background: "#F9F9F9", paddingBottom: "40px" }}>
			<Container style={{ padding: '40px 0 0 0' }}>
        <StyledRow>
          <Col xs={{span: 12, order: 2}} lg={{span: 6, order: 1}} xl={6} className='d-flex justify-content-center'>
            <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FFOTO.png?alt=media&token=fe2b71ab-a30c-444b-ad6d-bfeb8011a6d5" alt="Doctora sonriendo" />
          </Col>
          <Col xs={{span: 12, order: 1}} lg={{span: 6, order: 2}} xl={6} id='text-col'>
            <div className="item">
              <UilInfoCircle className="icon" size={40}/>
              <h1>¿Quiénes somos?</h1>
              <p>Una empresa de servicios de <span>diagnóstico de alta especialidad con más de 30 años de experiencia</span> en el sector. Ofrecemos un amplio catálogo de estudios y tecnología de vanguardia para brindar un diagnóstico confiable.</p>
            </div>
            <div className="item" id="second">
              <UilHunting className="icon" size={40}/>
              <h1>Nuestra misión</h1>
              <p>Dar el mejor servicio de diagnóstico a pacientes, médicos e instituciones, con la mejor atención al cliente, tecnología y siempre de la mano de la ética.</p>
            </div>
            <div className="item">
              <UilEye className="icon" size={40}/>
              <h1>Nuestra visión</h1>
              <p>Ser la empresa de salud líder en diagnóstico especializado en México, que marque <span>el nuevo estándar de calidad y atención al paciente, médico y cliente,</span> así como los procesos prácticos de vanguardia en nuestra rama de la medicina.</p>
            </div>
          </Col>
        </StyledRow>
        <StyledRow>
        <Col xs={{span: 12, order: 1}} lg={{span: 6, order: 2}} xl={12} id='text-col'>
            <div className="">
              <h1>Política de calidad</h1>
              <p style={{textAlign: 'justify'}}>En Labopat ® nos comprometemos a brindar a todos nuestros clientes y pacientes un diagnóstico oportuno y confiable a través de los servicios de Patología Quirúrgica, Citología, Inmunohistoquímica, Biología Molecular, Servicios Genéticos, Imagenología, Laboratorio Clínico y demás servicios de diagnóstico que en el futuro podamos ofertar. <br />
                 Trabajamos teniendo como fundamento la mejora continua, con un firme compromiso por el cumplimiento de los requisitos éticos, legales y normativos. <br />
                 Al mismo tiempo nos comprometemos al desarrollo profesional de nuestro personal, estructurando un equipo organizado y orientado al desarrollo de sus competencias a través de la capacitación continua, contribuyendo así con la satisfacción de nuestros clientes y pacientes.</p>
            </div>
            <div className="">
              <h1>Objetivos de calidad</h1>
              <p style={{textAlign: 'justify'}}>
              1.- Garantizar la satisfacción de nuestros clientes y pacientes, asegurando la fiabilidad y precisión de nuestros servicios, resultados y diagnósticos. <br /><br />
              2.- Cumplir con los requisitos del Sistema de Gestión de la Calidad para asegurar su eficacia, estandarización de los tiempos de entrega de los informes, además de evaluar periódicamente los indicadores y metas planteadas. <br /><br />
              3.- Establecer y mantener una tasa de reducción significativa de salidas no conformes y no conformidades, mediante la implementación de acciones correctivas y preventivas efectivas. <br /><br />
              </p>
            </div>
          </Col>
        </StyledRow>
			</Container>
		</div>
	);
};
