import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import NotFoundImg from '../../assets/not-found/notfound.png'

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  min-height: 75vh;
  @media only screen and (max-width: 600px) {
    min-height: 55vh;
  }
`;

const Title = styled.h1`
  color: #00AC90;
  font-size: 60px;
  margin-bottom: 0px;
  @media only screen and (max-width: 600px) {
    font-size: 40px;
  }
`;

const StyledImg = styled.img`
  width: 90%;
  margin: 40px 0 0 5%;
`;

export const NotFound = () => {
  return (
    <StyledContainer>
      <Row>
        <Col xs={12} lg={5} className="d-flex align-items-center">
          <Title>Página no encontrada...</Title>
        </Col>
        <Col xs={12} lg={7}>
          <StyledImg src={NotFoundImg} alt="Página no encontrada"/>
        </Col>
      </Row>
    </StyledContainer>
  );
}