import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import { useHistory } from "react-router-dom";
import {
  UilClipboardAlt,
  UilTrashAlt,
  UilTimes,
} from "@iconscout/react-unicons";
import { UserResults } from "./userResults";
import { TableResults } from "./resultsTable";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  getTotalPrice,
  removeItemFromCart,
  setTotalPrice,
} from "../../actions/shoppingCart";
import { updateCartDB } from "../../helpers/api";
import { ListItemCart } from "../shopping-cart/listItemCart";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  font-style: normal;
  color: #697077;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const TabIndicatorCol = styled(Col)`
  height: 10px;
  border-radius: 5px;
  margin-top: 10px;
  background: ${(props) =>
    props.selected && props.$isDoctor
      ? "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)"
      : props.selected &&
        "linear-gradient(83.81deg, #F7EE1B -32.78%, #35E19B 24.37%, #00AE8E 75.27%, #5097E3 138.67%)"};
`;

const TabTitle = styled.a`
  display: inline-block;
  width: 100%;
  margin-top: -10px;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  color: ${(props) => (props.$isDoctor ? "#4F90BA" : "#00AC90")};
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  transition: 200ms;
  :hover {
    color: ${(props) => (props.$isDoctor ? "#5097E3 " : "#35E19B")};
    transition: 200ms;
  }
`;

const StyledNav = styled.nav`
  border-bottom: 1px solid #c1d9d8;
`;

const StyledModal = styled(Modal)`
  .modal-header {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #4f90ba;
  }
  .btn-close {
    left: 93%;
  }
  .modal-content {
    padding: 10px;
  }
  .modal-title {
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    margin-right: auto;
    color: #4f90ba;
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-primary {
    background: #4f90ba;
    border: none;
  }
  .foot-btn {
    max-width: 368px;
    width: 90%;
    margin: 0 auto;
    border: 2px solid #4f90ba;
    border-radius: 18px;
  }
  .row {
    width: 100%;
  }
  span {
    font-weight: 800;
  }
`;

const NoResultsDiv = styled.div`
  width: 100%;
  height: 518px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: #f3f3f3;
    width: 100px;
    height: 100px;
    margin-bottom: 32px;
  }
  p {
    color: #474a4b;
    text-align: center;
    font-size: 18px;
  }
`;

const EmptyCart = styled.div`
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    color: #474a4b;
    margin-bottom: 60px;
  }
`;

const CardCont = styled.div`
  max-width: 100%;
  max-height: auto;
  padding: 0rem;
  //margin-left: 100px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
  border: 10 px;
  border: 2px solid transparent;
  border-radius: 12px;
  position: absolute;
`;

const WhiteButton1 = styled.button`
  width: 100%;
  //margin-left: 20%;
  height: 40px;
  background: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  margin-top: -5px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;

const ListDiv = styled.div`
  overflow-y: auto;
  //padding-right: 7px;
  margin-right: 5px;
  max-height: 50vh;
  @media only screen and (max-width: 1600px) {
    max-height: 40vh;
  }
  @media only screen and (max-width: 1400px) {
    max-height: 68vh;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const CardDiv = styled.div`
  padding: 10px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
  border-radius: 10px;
  border: 2px solid transparent;
  overflow-y: auto;
  margin: 5px;
  max-height: 40vh;
  max-width: 20vw;
`;

const StyledDrawer = styled.div`
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(
    90deg,
    #5097e3 -2.05%,
    #0cac8c 72.3%,
    #30df99 101.61%,
    #cef719 133.72%,
    #f5ec1d 211.91%
  );
  overflow: hidden;
  width: 100%;
  padding: 0.1rem;
  border-radius: 10px;
  border: 2px solid transparent;
  margin-top: -2px;
  margin-bottom: 20px;
  height: 90px;
  p {
    color: #fff;
    font-weight: 800;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 25px;
    line-height: 20px;
    margin-top: 0px;
    text-align: initial;
    margin-bottom: 12px;
    padding: 2rem;
  }
`;

const Bottom = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px;
  @media only screen and (max-width: 1900px) {
    //bottom: 100px;
  }
  p {
    text-align: center;
    //margin-botton: 24px;
    .disabled {
      pointer-events: none;
      color: #c4c4c4;
    }
  }
  #genOrder {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: ${(props) => props.mainColor};
    text-decoration: underline;
    cursor: pointer;
  }
  #noItems {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #a6a6a6;
    span {
      font-weight: 800;
    }
  }
`;

const Total = styled.h5`
  color: #6a6a6a;
  font-weight: 800;
  font-size: 15px;
  margin-top: 16px;
  position: relative;
  span {
    position: absolute;
    font-weight: 500;
    right: 0px;
    color: ${(props) => props.mainColor};
    text-align: right;
  }
  #black {
    color: #6a6a6a;
  }
`;

const BlueButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: ${(props) =>
    props.mainColor === "#4D99E5"
      ? "linear-gradient(84.3deg, #759ABD 15.24%, #97C6C0 97.95%)"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)"};
  border-radius: 16px;
  border: 2px solid
    ${(props) => (props.mainColor === "#4D99E5" ? "#fff" : "#30DF99")};
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: ${(props) => props.mainColor};
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
    background: #fff;
  }
`;

export const Results = (props) => {
  const { items, totalPrice } = useSelector((state) => state.shoppingCart);
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const history = useHistory();

  const dispatch = useDispatch();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  const [results, setResults] = useState([]);
  const user = useSelector((state) => state.user?.data?.data);
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const uid = useSelector((state) => state?.auth?.uid);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const isDesktop = width > 1366;
  const mainColor =
    PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";

  const [totalSucursal, setTotalSucursal] = useState();
  const gaEventTracker = useAnalyticsEventTracker("Cart");
  var isOrder = urlParams.get("doctor");

  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );

  useEffect(() => {
    getTotal();
  }, [items]);

  const [showCallModal, setShowCallModal] = useState(false);

  const getTotal = () => {
    const pricesArray = items?.map((item) => item.oldPrice);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setTotalSucursal(final);
  };

  useEffect(() => {
    dd();
  }, []);

  const dd = async () => {
    try {
      //setLoading(true);
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/misResultadosDR?user=${uid}`
      );
      setResults(requestPending.data);
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.log(error);
    }
  };

  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    console.log(currentItem);
    if (currentItem.howMany > 1) {
      console.log("IF");
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      console.log("DATA: ", data);
    } else {
      console.log("else 3");
    }
    // console.log("get total 4: ", data)
    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  const vaciarCarrito = () => {
    // Eliminar todos los elementos del carrito
    dispatch(removeItemFromCart([])); // Pasar un array vacío elimina todos los elementos
    // Actualizar el precio total a cero
    dispatch(setTotalPrice(0));
    // Actualizar el carrito en la base de datos
    dispatch(updateCartDB([], 0)); // Pasar un array vacío y cero como precio
  };

  const someNotAvailableOnline = () => {
    return items.some(
      (study) =>
        study.categoria === 10 ||
        study.categoria === 8 ||
        study.ventaOnline === 0
    );
  };

  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para:` +
      items.map((study) => " " + study.name);
  };

  const handleOrder = () => {
    const patientInfo = JSON.parse(localStorage?.getItem("patientInfo"));

    gaEventTracker("carrito-generar-order");
    if (window.location.pathname === "/checkout" && !isOrder) {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
      window.location.reload();
    } else {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
    }
  };

  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <Container
      className={isMobile ? "mt-5 pt-4" : ""}
      style={{ minHeight: isMobile ? "190vh" : "150vh" !== isDesktop ? "110vh" : ""}}
    >
      <HelmetTitle title="Resultados" name="description" content="content" />
      <Row className="mb-5">
        <HeaderSection>
          <HeaderTitle className="mb-5">Mis Resultados</HeaderTitle>
          {results?.length > 0 && (
            <StyledNav>
              <Row className="m-0 p-0">
                <Col xs={6} md={3} lg={3} xl={2} style={{ paddingLeft: "0" }}>
                  <TabTitle
                    onClick={() => setTab(1)}
                    selected={tab === 1 ? true : false}
                    $isDoctor={isDoctor}
                  >
                    {isDoctor ? "Solicitudes generadas" : "Historial"}
                    <TabIndicatorCol
                      onClick={() => setTab(1)}
                      selected={tab === 1 ? true : false}
                      $isDoctor={isDoctor}
                    />
                  </TabTitle>
                </Col>
              </Row>
            </StyledNav>
          )}
        </HeaderSection>
      </Row>
      <Row className="justify-content-flex-start mb-5">
        <Col md={7}>
          {results?.length > 0 ? (
            <>
              {tab === 0 && (
                <Row className="mb-5">
                  {isDoctor ? (
                    <TableResults data={[]} isDoctor={isDoctor} />
                  ) : (
                    <UserResults data={[]} tab={tab} isDoctor={isDoctor} />
                  )}
                </Row>
              )}
              {tab === 1 && (
                <Row className="mb-5">
                  {isDoctor ? (
                    <TableResults data={results} isDoctor={isDoctor} />
                  ) : (
                    <UserResults data={results} tab={tab} isDoctor={isDoctor} />
                  )}
                </Row>
              )}
            </>
          ) : (
            <NoResultsDiv>
              <div>
                <UilClipboardAlt size={50} color="#C4C4C4" />
              </div>
              <p>No hay resultados pendientes.</p>
            </NoResultsDiv>
          )}
        </Col>

        <Col md={4}>
          <CardCont style={{ marginLeft: isMobile ? "0px" : "100px" }}>
            <StyledDrawer>
              <p>Estudios solicitados</p>
            </StyledDrawer>
            {items?.length > 0 ? (
              <div style={{ position: "relative", paddingBottom: "106px" }}>
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Col lg={6} style={{ padding: "2px" }}>
                      <WhiteButton1
                        style={{
                          color: "rgb(0, 172, 144)",
                          border: "2px solid rgb(0, 172, 144)",
                        }}
                        onClick={vaciarCarrito}
                      >
                        <UilTrashAlt size={20} />
                        Vaciar lista
                      </WhiteButton1>
                    </Col>
                  </div>
                </>

                <br />
                <ListDiv>
                  {items.map((item) => (
                    <>
                      <CardDiv style={{ maxWidth: isMobile ? "83vw" : "22vw" }}>
                        <ListItemCart
                          key={item.id}
                          item={item}
                          mainColor={mainColor}
                          onDelete={handleRemoveItemFromCart}
                          idClienteLabopat={idClienteLabopat}
                        />
                      </CardDiv>
                    </>
                  ))}
                </ListDiv>

                <br />
              </div>
            ) : (
              <EmptyCart>
                <div id="empty">
                  <UilClipboardAlt color="#A6A6A6" size={100} />
                </div>
                <p>No hay estudios agregados</p>
              </EmptyCart>
            )}

            <Bottom minColor={mainColor}>
              {items?.length > 0 ? (
                <>
                  {idClienteLabopat && idClienteLabopat !== 0 ? (
                    <>
                      <Total
                        mainColor={
                          mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                        }
                      >
                        Total a pagar :{" "}
                        <span>{currency(totalSucursal)} MXN</span>
                      </Total>
                    </>
                  ) : (
                    <>
                      <Total
                        mainColor={
                          mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                        }
                      >
                        Total a pagar :{" "}
                        <span>{currency(totalSucursal)} MXN</span>
                      </Total>
                    </>
                  )}
                  {isDoctor && (
                    <>
                      <BlueButton
                        id="genOrder"
                        onClick={handleOrder}
                        mainColor={
                          mainColor === "#4F90BA" ? "#4D99E5" : "#4D99E5"
                        }
                      >
                        Genera una orden aquí
                      </BlueButton>
                    </>
                  )}
                </>
              ) : (
                isDoctor && (
                  <p id="noItems">
                    Para generar una orden{" "}
                    <span>necesitas agregar al menos 1 estudio.</span>
                  </p>
                )
              )}
            </Bottom>
            <StyledModal
              show={showCallModal}
              onHide={() => setShowCallModal(false)}
              mainColor={mainColor}
            >
              <div onClick={() => setShowCallModal(false)}>
                <UilTimes color="#fff" style={{ cursor: "pointer" }} />
              </div>
              <Modal.Header closeButton>
                <Modal.Title>
                  Estudios no disponibles para reserva en linea
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Los siguientes estudios solo se pueden agendar mediante
                  llamada:
                </p>
                <ul>
                  {items.map((study) => {
                    if (
                      study.categoria === 10 ||
                      study.categoria === 8 ||
                      study.ventaOnline === 0
                    ) {
                      return <li>{study.name}</li>;
                    }
                  })}
                </ul>
                <ContinueButton
                  onClick={() => callToSchedule()}
                  mainColor={mainColor}
                >
                  Llamar para agendar
                </ContinueButton>
              </Modal.Body>
            </StyledModal>
          </CardCont>
        </Col>
      </Row>
    </Container>
  );
};