import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import agsTwoDk from '../../../assets/aguascalientes/agsBannerTwoDesk.png';
import agsTwoMb from '../../../assets/aguascalientes/agsBannerTwoMB.png';
import { OUTLINE_CARDS, TEXT_BLACK } from "../../../helpers/colors";

const BackBanner = styled.div`
  height: 460px;
  display: flex;
  align-items: center;
  position: relative;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%), url("${agsTwoDk}") right center/contain no-repeat;
  @media only screen and (max-width: 992px) {
    height: 520px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1400px) {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%), url("${agsTwoDk}") right 0px center/contain no-repeat;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1480px) {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%), url("${agsTwoDk}") right -100px center/contain no-repeat;
  }
  @media only screen and (max-width: 992px) {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%), url("${agsTwoMb}") right bottom 0px no-repeat;
    align-items: start;
    padding: 35px 15px;
  }
`;

const ColTitle = styled(Col)`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  overflow: show;
  h1 {
    font-size: 52px;
    font-weight: 600;
    line-height: normal;
    background: ${OUTLINE_CARDS};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h3 {
    color: ${TEXT_BLACK};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  p {
    color: ${TEXT_BLACK};
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    span {
      font-weight: 500;
    }
  }
  #btn-container {
    height: 64px;
    width: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  .btn {
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: linear-gradient(79deg, #5097E3 -41.6%, #0CAC8C 26.14%, #30DF99 84.04%, #CEF719 107.01%, #F5EC1D 132.38%);
    cursor: pointer;
  }
  .btn::before {
    content: "";
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    border-radius: 4px;
    background-color: white;
    z-index: -1;
    transition: 300ms;
  }
  .btn::after {
    content: attr(data);
    background: linear-gradient(79deg, #5097E3 -41.6%, #0CAC8C 26.14%, #30DF99 84.04%, #CEF719 107.01%, #F5EC1D 132.38%);
    -webkit-background-clip: text;
    color: transparent;
    transition: 200ms;
    text-align: center;
    font-size: 29px;
    font-weight: 400;
    line-height: normal;
  }
  .btn:hover::before {
    opacity: 1%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .btn:hover::after{
    color: white;
  }
  @media only screen and (width <= 768px) {
    h3 {
      font-size: 18px;
    }
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 16px;
    }
    .btn::after {
      font-size: 20px;
    }
    #btn-container {
      border-radius: 4px;
      width: 105px;
      height: 38px;
      margin: 0 auto;
    }
  }
`;

export const AguascalientesBannerTwo = () => {
  const phoneNumber = '4497102251';
  return (
    <BackBanner>
      <Container>
        <Row>
          <ColTitle xs={{span: 12, order: 2}} lg={{span: 9, order: 1}} xl={8}>
            <h3>Estamos siempre cerca de ti</h3>
            <h1>Sede Labopat Aguascalientes</h1>
            <p><span>Servicios:</span><br/>Patología quirúrgica / Citología / Pruebas genéticas / Pruebas de biología molecular / Inmunohistoquímica / Inmunofluorescencia</p>
            <div id="btn-container">
              <button onClick={() => window.open(`tel:${phoneNumber}`, '_blank')} className="btn" data="Contacto"/>
            </div>
          </ColTitle>
        </Row>
      </Container>
    </BackBanner>
  )
};