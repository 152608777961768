import React from 'react';
import '../../App.css'
import { TrackGoogleAnalitycsEvent } from '../../analytics/google-analytics';

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    TrackGoogleAnalitycsEvent('WhatsApp', 'WhatsApp', 'click en boton whatsapp');
    window.open('https://wa.me/522212311313', '_blank')

  };
  return (
    <a href="https://wa.me/522212311313" className="float" target="_blank" id="whatsapp-in-public" onClick={handleWhatsAppClick}>
      <i className="fa fa-whatsapp my-float"></i>
    </a>
  )
}

export default WhatsAppButton
