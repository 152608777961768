import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

const StyledContainer = styled(Container)`
    padding-top: 112px;
    padding-bottom: 96px;
    @media only screen and (max-width: 768px) {
        padding-top: 49px;
        padding-bottom: 57px;
    }
`;

const Title = styled.h1`
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 57px;
    text-align: center;
    color: #474A4B;
    margin-bottom: 25px;
    @media only screen and (max-width: 768px) {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 26px;
    }
`;
    
const RegularP = styled.p`
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #474A4B;
    max-width: 524px;
    margin: 0 auto 34px;
    @media only screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.01em;
    }
`;

const ChatButton = styled.a`
    width: 80%;
    max-width: 300px;
    height: 48px;
    font-family: 'IBM Plex Sans';
    border: 2px solid #30DF99;
    border-radius: 10px;
    box-sizing: border-box;
    background: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #30DF99;
    margin-top: 10px;
    margin-bottom: 24px;
    transition: all 0.3s ease;
    padding-top: 11px;
    :hover {
        color: #fff;
        background: #30DF99;
    }
    @media only screen and (min-width: 768px) {
        width: 300px;
    }
`;

const LittleP = styled.p`
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #474A4B;
    margin-bottom: 0;
`;

const FormButton = styled(Button)`
    width: 80%;
    max-width: 300px;
    height: 48px;
    color: #FFF;
    background: #30DF99;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    margin-bottom: 10px;
    font-family: 'IBM Plex Sans';
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    @media only screen and (min-width: 768px) {
        margin-top: 31px;
        width: 300px;
    }
`;

const StyledForm = styled(Form)`
    input, textarea {
        font-family: 'IBM Plex Sans';
        background: rgba(48, 223, 153, 0.05);
        border-radius: 10px;
        border: none;
    }
    .form-control:focus{
        border-color: #30DF99;
        box-shadow: 0px 0px 8px 2px #30DF99;
    }
    @media only screen and (min-width: 1200px) {
        width: 90%;
        margin-left: 5%;
    }
`;

const InputStyled = styled(Form.Check)`
    > input {
        cursor: pointer;
        border: 1px solid #30DF99 !important;
        :checked {
            border: 1px solid #30DF99 !important;
            background-color: #30DF99 !important
        }
    }
`;

export const Doubts = () => {
    const [buttonEnable, setButtonEnable] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [token, setToken] = useState("");
    const number = '+522212311313';
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        setButtonEnable(false);
        const id = toast.loading("Enviando...");
        axios
            .get(`https://web-service-email.uc.r.appspot.com/contact?nombre=${data.name}&apellido=${data.lastName}&email=${data.email}&telefono=${data.phone}&msg=${data.message}&to=contacto@labopat.com`)
            .then(res => {
                toast.dismiss(id);
                setTimeout(() => {
                    toast.success('Mensaje enviado!', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                        document.getElementById("name").value = "";
                        document.getElementById("lastName").value = "";
                        document.getElementById("email").value = "";
                        document.getElementById("phone").value = "";
                        document.getElementById("message").value = "";
                        setTimeout(()=>{
                            setButtonEnable(true);
                        }, 2000);
                }, 200);
            })
            .catch((err) => {
                console.log(err);
                toast.dismiss(id);
                setTimeout(() => {
                    toast.error('Ha ocurrido un error :(', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                        setTimeout(()=>{
                            setButtonEnable(true);    
                        }, 2000);
                }, 200);
            })
        console.log(data);
    }

    var printChange = (data) => {
        setButtonEnable(true);
        setToken(data);
        console.log(data);
    }

    function gtag_report_conversion(url) {
        var callback = function () {
            if (typeof(url) != 'undefined') {
                window.location = url;
            }
        };
        window.gtag('event', 'conversion', {
            'send_to': 'AW-647753402/7wYECJPGx8UDELrd77QC',
            'event_callback': callback
        });
        return false;
    }

    return (
        <StyledContainer>
            <Title>¿Tienes dudas?</Title>
            <RegularP>Nuestro equipo está capacitado para responder cualquier duda y darte la asistencia necesaria.</RegularP>
            <Row>
                <Col xs={12} md={{span: 10, offset: 1}} lg={{span: 8, offset: 2}}>
                    <StyledForm onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3" controlId="name">
                            {/* <Form.Label>Nombre*</Form.Label> */}
                            <Form.Control type="text" placeholder="Nombre*" 
                                {...register('name', {
                                    required: true,
                                })} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="lastName">
                            {/* <Form.Label>Apellido</Form.Label> */}
                            <Form.Control type="text" placeholder="Apellido" 
                                {...register('lastName', {
                                })} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            {/* <Form.Label>Email address</Form.Label> */}
                            <Form.Control type="email" placeholder="Email*" 
                                {...register('email', {
                                    required: true,
                                    pattern: {
                                    value:
                                        /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                                    message: 'El correo no cumple con el formato',
                                    },
                                })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="phone">
                            {/* <Form.Label>Email address</Form.Label> */}
                            <Form.Control type="number" placeholder="Teléfono" 
                                {...register('phone', {
                                })} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="message">
                            {/* <Form.Label>Example textarea</Form.Label> */}
                            <Form.Control as="textarea" rows={3} placeholder="Escribe tu mensaje aquí*"
                                {...register('message', {
                                    required: true,
                                })}
                            />
                        </Form.Group>
                        <div className='d-flex justify-content-center'>
                            <LittleP style={{marginTop: '15px', marginBottom: '20px'}}>
                                <b>Aviso legal: </b>
                                Los datos recabados en este formulario 
                                son con la finalidad de dar atención a
                                la solicitud requerida, por lo tanto, 
                                le informamos que puede consultar nuestro
                                {' '}
                                <a 
                                    style={{
                                    textDecoration: 'underline',
                                    color: '#717171'}}
                                    target='_blank'
                                    rel='noreferrer'
                                    href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FAvisodeprivacidad.pdf?alt=media&token=32a816cc-89a7-4a07-b4ea-1a148cfcf7a4'> 
                                    aviso de privacidad.
                                </a>
                            </LittleP>
                        </div>
                        <div className='d-flex justify-content-center mb-4'>
                            <InputStyled 
                                type='checkbox'
                                label='He leído y acepto los términos y condiciones y aviso de privacidad de Labopat'
                                onChange={() => setShowCaptcha(!showCaptcha)}
                            />
                        </div>
                        <div className='d-flex justify-content-center mb-0'>
                            <ReCAPTCHA
                                sitekey="6LdyCCAdAAAAAN-aYVFJArnyj1TE7bgi6uu0NQNT"
                                render="explicit"
                                onChange={printChange}
                                onExpired={()=>{setToken(undefined); setButtonEnable(false);}}
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <FormButton variant="secondary" type="submit" 
                                disabled={buttonEnable && showCaptcha === true ? false : true} 
                                onClick={() => gtag_report_conversion()}>
                                Enviar
                            </FormButton>
                        </div>
                    </StyledForm>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        />
                </Col>
            </Row>
            <p style={{textAlign: 'center', marginBottom: '0px'}}>o</p>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <ChatButton href={`https://wa.me/${number}`}
                    target='_blank'
                    rel="noreferrer noopener">Chatea con nosotros</ChatButton>
            </div>
            <LittleP>Al continuar se abrirá una ventana para<br/>iniciar una conversación de Whatsapp</LittleP>
        </StyledContainer>
    );
};
