import React, { useEffect, useState } from "react";
import { Academy } from './academy';
import { Activities } from './activities';
import { Banner } from "./banner";
import { Contact } from "./contact";
import { HowToApply } from './howToApply';
import { ImportantDates } from './importantDates';
import { Navbar } from "./navbar";
import { PlanEstudios } from "./planEstudios";
import { HelmetTitle } from '../../helpers/HelmetTitle';

export const CursoAltaEspecialidad = () => {
  const [showRequirements, setShowRequirements] = useState(false);
  const [showDates, setShowDates] = useState(false);
  // const [showApplyModal, setShowApplyModal] = useState(false);
  useEffect(()=>{
    const navbar = document.getElementById('topNavBar');
    navbar.style.display = "none";
  },[])
  return (
    <>
      <HelmetTitle
        title="Curso Alta Especialidad"
        name="description"
        content="content"
      />
      <Navbar/>
      <Banner setShowRequirements={(e)=>setShowRequirements(e)} setShowDates={setShowDates}/>
      <PlanEstudios/>
      <Activities />
      <Academy />
      <HowToApply showRequirements={showRequirements} setShowRequirements={(e)=>setShowRequirements(e)}/>
      <Contact/>
      <ImportantDates showDates={showDates} setShowDates={setShowDates} />
      {/* <ApplyModal showApplyModal={showApplyModal} setShowApplyModal={(e)=>setShowApplyModal(e)}/> */}
    </>
  )
};