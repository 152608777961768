import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import {
  UilDownloadAlt,
  UilCheck,
  UilInvoice,
  UilInfoCircle,
} from "@iconscout/react-unicons";
import Dra from "../../assets/checkout/dra.png";
import { useSelector, useDispatch } from "react-redux";
import { resetCart } from "../../actions/shoppingCart";
import { useEffect } from "react";
import printJS from "print-js";
import axios from "axios";
import { Loader } from "../general/spinner";
import { resetPatientInfo } from "../../actions/patientInfo";

const StyledForDesktop = styled.div`
  max-width: 548px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h1`
  text-transform: capitalize;
  margin-top: 32px;
  text-transform: capitalize;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  text-align: center;
`;

const RegularP = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  > span {
    font-weight: 800;
  }
`;

const RegularO = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  > span {
    font-weight: 500;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  > button {
    font-size: 18px;
    font-weight: 500;
    background: none;
    border: none;
    margin-top: 20px;
    margin-bottom: 32px;
    color: #4D99E5;
    text-decoration: underline;
    > svg {
      color: #00ad90;
    }
  }
`;

const StyledDiv = styled.div`
  margin-bottom: 24px;
  background: #dbf1ff;
  padding: 16px 20px;
  border-radius: 12px;
  > p {
    text-align: left;
    margin-bottom: 0px;
    > a {
      color: #4D99E5;
      text-decoration: underline;
    }
  }
`;

const PlaceP = styled.p`
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: #3b3b3b;
`;

const AddressP = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #717171;
`;

const DateP = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
`;

const InvertedRadius = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  max-width: 100%;
  padding: 16px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 24px;
  box-shadow: 0px 10px 20px rgba(164, 164, 164, 0.5);
  > div.bottom {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #fff;
  }
  > .top {
    top: 12px;
  }
  > .bottom {
    bottom: 64px;
  }
  > .left {
    left: -16px;
  }
  > .right {
    right: -16px;
  }
  > hr.pointed {
    position: absolute;
    bottom: 65px;
    margin-top: 0px;
    width: 100%;
    height: 0;
    border: medium none;
    border-top: 2px dashed #e0ecec;
  }
  > div div.col-3 svg {
    width: 30px !important;
    height: 30px !important;
  }
  > hr {
    margin-left: auto;
    margin-right: auto;
    width: 470px;
  }
  > .row#info div {
    display: flex;
    justify-content: left;
    padding: 0px;
    > svg {
      color: #3b3b3b;
    }
  }

  > .row#info div#patient {
    padding-left: 10px;
  }
`;

const Done = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 86px;
  height: 86px;
  background: linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  > svg {
    color: #fff;
    width: 36px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 40px;
  }
`;

const InfoIcon = styled(UilInfoCircle)`
  color: #2a97dd;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
`;

const Require = styled.div`
  background-image: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdraBg.png?alt=media&token=02562a38-0d64-482a-b6f2-b0083081bca6");
  background-repeat: no-repeat;
  background-position: bottom right;
  min-height: 210px;
  padding-bottom: 31px;
  padding-right: 35%;
  position: relative;
  > ul {
    margin-bottom: 0px;
  }
  > img {
    z-index: 1;
    width: 175px;
    position: absolute;
    bottom: 0;
    right: 5px;
  }
`;

const Reward = styled.div`
  background: linear-gradient(180deg, #1ecbcb 0%, #4a6cc1 100%);
  padding: 32px 16px;
  border-radius: 12px;
  > div {
    background: #fff;
    border-radius: 12px;
    padding: 40px 24px;
    text-align: center;
    > h4 {
      font-weight: 900;
      margin-bottom: 16px;
    }
    > button {
      border: none;
      color: #fff;
      margin-top: 10px;
      font-weight: 800;
      font-size: 16px;
      width: 214px;
      height: 48px;
      background: #00ad90;
      border-radius: 102px;
    }
  }
`;

const DocButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  > #whiteButton {
    text-align: center;
    border: none;
    width: 241px;
    background: #fff;
    color: #4D99E5;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
`;

const GreenButton = styled.button`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #4D99E5;
  border: none;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #0a7a71;
  }
  :hover {
    border: 2px solid #4D99E5;
    color: #4D99E5;
    background: #fff;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

export const ThanksScreen = (props) => {
  const isDoctor = props.isDoctor;
  const gender = "M"; // TODO: Add field to state
  const cardPayment = props.pay === "web";
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.data?.data);
  const isLoggedIn = useSelector((state) => state.auth?.uid || false);
  const [loading, setLoading] = useState(false);
  const cartId = localStorage.getItem("cartId");
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const openLink = () => {
    window.open(
      "https://lpqsystem.com/comprobante-ecommerce?id=" +
        props.confirm.data.id
    );
  };

  const openLinkB = async () => {
    setLoading(true);
    // window.open('https://lpqsystem.com/getReceta?id=' + props.confirm.data.id);

    const orderBase64 = await axios.get(
      "https://lpqsystem.com/obtenerReceta?id=" + props.confirm.data.id
    );
    console.log(
      "🚀 ~ file: thanksScreen.jsx:336 ~ openLinkB ~ orderBase64",
      orderBase64
    );
    /*if (orderBase64?.data?.ok && !mobile) {
      printJS({ printable: orderBase64?.data?.pdf, type: "pdf", base64: true });
      setLoading(false);
    } else {*/
      window.open(
        "https://lpqsystem.com/getReceta?id=" + props.confirm.data.id
      );
      setLoading(false);
    //}
  };

  useEffect(() => {
    dispatch(resetCart());
    localStorage.removeItem("cartId");
    localStorage.setItem("cartId", "");
    dispatch(resetPatientInfo());
    localStorage.removeItem("patientInfo");
    console.log(localStorage);
    console.log("thanks");
    console.log(props);
  }, []);

  var mobile = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
      return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
    }
  };

  return (
    <StyledForDesktop>
      <Done>
        <UilCheck size="36" />
      </Done>
      <Title>
        Gracias, {isDoctor ? (user.gender === "Masculino" ? "Dr." : "Dra.") : null}{" "}
        {user.name}
      </Title>
      {props.pay === "orderToPatient" ? (
        props.firstTimeDoctor ? (
          <RegularP>
            ¡La orden de estudios para {props.personalData.name} se completó
            exitosamente!
          </RegularP>
        ) : (
          <RegularP>
            ¡La orden de estudios para {props.personalData.name} se completó
            exitosamente!
          </RegularP>
        )
      ) : (
        <RegularP>¡Tu compra se completó exitosamente!</RegularP>
      )}

      <StyledDiv>
        {props.firstTimeDoctor && props.pay === "orderToPatient" ? (
          <RegularP>
            Puedes consultar el histórico de órdenes creadas desde{" "}
            <a href="/historial-medico">tu perfil</a>
          </RegularP>
        ) : (
          <>
            {/* TODO: Add order number from shopping cart */}
            {props.pay !== "orderToPatient" ? (
              <>
                <RegularP>
                  No. de la orden: <span>{props.confirm.data?.folio}</span>
                </RegularP>
                <ButtonDiv>
                  <button type="button" onClick={openLink}>
                    <UilDownloadAlt /> Descargar comprobante.
                  </button>
                </ButtonDiv>
              </>
            ) : (
              <>
                {/* TODO: Add order number from shopping cart */}
                <RegularP>
                  No. de la orden: <span>{props.confirm.data.folio}</span>
                </RegularP>
                <ButtonDiv>
                  <button type="button" onClick={openLinkB}>
                    <UilDownloadAlt color={"#4D99E5"}/> {mobile ? 'Descargar' : 'Imprimir'} orden
                  </button>
                </ButtonDiv>
              </>
            )}
            <RegularP>
              Envíamos a tu correo la orden de estudios junto con las
              indicaciones el día de la cita.{" "}
              {isLoggedIn && (
                <>
                  Puedes consultar tus órdenes creadas desde tu{" "}
                  <a href="/perfil">expediente digital</a>
                </>
              )}
            </RegularP>
          </>
        )}
      </StyledDiv>

      {(isDoctor && props.pay === "orderToPatient") || !cardPayment ? (
        <InvertedRadius>
          <p style={{ fontSize: "16px", lineHeight: "24px", color: "#3B3B3B" }}>
            Resumen de solicitud
          </p>
          <StyledDiv>
            <RegularO style={{ textAlign:"center" }}>
              No. de la orden: <span>{props.confirm.data.folio}</span>
            </RegularO>
          </StyledDiv>

          {props.items.map((item) => (
            <>
              <p style={{ fontWeight: "500", marginBottom: "8px" }}>
                {item.title}
              </p>
              <p>
                {currency(
                  props.pay === "web" || props.pay === "orderToPatient"
                    ? item.price
                    : item.oldPrice
                )}
              </p>
              <hr />
            </>
          ))}
          {props.discount !== 0 && (
            <div>
              <p>
                Usaste el cupón "
                <span style={{ fontWeight: "800" }}>{props.coupon}</span>"
              </p>
              <p>
                Obtuviste un descuento de{" "}
                <span style={{ fontWeight: "800" }}>
                  {currency(props.discount)} MXN
                </span>
              </p>
              <hr />
            </div>
          )}
          {/* <PlaceP>{props.place.name}</PlaceP>
          <AddressP>{props.place.address}</AddressP> */}
          {/* <DateP>
            {days[props.date.getDay()]}{' '}
            {String(props.date.getDate()).padStart(2, '0')} de{' '}
            {months[props.date.getMonth()]} del {props.date.getFullYear()}
            &nbsp; de {props.time}
          </DateP> */}
          {isDoctor && props.pay === "orderToPatient" ? (
            <Row id="info">
              <Col xs={1}>
                <InfoIcon />
              </Col>
              <Col id="patient" xs={11}>
                <p style={{ marginBottom: "0px" }}>
                  Los pacientes deben portar cubrebocas y sólo se lo podrán
                  retirar cuando se les indique.
                </p>
              </Col>
            </Row>
          ) : null}

          <hr className="pointed" />
          <div class="bottom left"></div>
          <div class="bottom right"></div>
          <Row
            style={{
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "48px",
            }}
          >
            <Col xs={8} style={{ textAlign: "center" }}>
              <p
                style={{
                  fontWeight: "800",
                  marginBottom: "0px",
                  fontSize: "16px",
                }}
              >
                Total a pagar:
              </p>
              <p style={{ fontSize: "16px", marginBottom: "0px" }}>
                {currency(props.total)} MXN
              </p>
            </Col>
            <Col
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UilInvoice />
            </Col>
          </Row>
        </InvertedRadius>
      ) : null}

      {!isDoctor ? (
        <>
          <RegularP style={{ textAlign: "left" }}>
            El día de la muestra no olvides llevar:
          </RegularP>
          <Require>
            <ul>
              <li>
                Identificación oficial; INE, Pasaporte y/o Credencial Escolar
                (en menores de edad).
              </li>
              <hr />
              <li style={{ paddingRight: "40px" }}>
                Los pacientes deben portar cubrebocas y sólo se lo podrán
                retirar cuando se les indique.
              </li>
            </ul>
          </Require>
          <br />
        </>
      ) : (
        <DocButtons className="justify-content-center">
          <GreenButton
            onClick={(e) => (window.location.href = "/mis-paquetes?forPatient=1")}
          >
            Crear nueva orden
          </GreenButton>
          <a style={{ marginBottom: "20px" }} id="whiteButton" href="/">
            Volver a la página principal
          </a>
        </DocButtons>
      )}
      {loading && <Loader show={loading} />}
    </StyledForDesktop>
  );
};
