import React, { useState, useEffect } from 'react';
import { Banner } from '../../components/categories/Banner';
import { useParams } from 'react-router';
import { Highlights } from './Highlights';
import { Studies } from './Studies';
import axios from 'axios';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import { Doubts } from '../../components/doubts/doubts';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/general/spinner';

export const Main = (props) => {
  const { service } = useParams();
  const [loading, setLoading] = useState(false);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const [pageInfo, setPageInfo] = useState({
    description: '',
    descripcion_larga: '',
    icono: '',
    id: 0,
    imagen_desktop: '',
    imagen_mobile: '',
    nombre: '',
  });

  const getInfo = async () => {
    setLoading(true);
    try {
      let request = axios.get(
        `https://lpqsystem.com/get-category-test/${service}/${
          idClienteLabopat ? `${idClienteLabopat}` : '0'
        }`
      );
      let response = await request;
      setPageInfo(response.data);
      setLoading(false);
    } catch (error) {
      alert(error);
      window.location.href = "/not-found";
    }
  };
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div style={{ paddingRight: 0, paddingLeft: 0 }}>
      <HelmetTitle title={pageInfo?.nombre ? pageInfo?.nombre +' | '+pageInfo?.descripcion :'Estudios..'} name='description' content='content' />
      <Banner
        data={{
          icono: pageInfo?.icono,
          nombre: pageInfo?.nombre,
          descripcion: pageInfo?.descripcion,
        }}
      />
      <Highlights data={{ id: pageInfo.id, service: service }} setLoading={(e)=>setLoading(e)}/>
      <Studies className='mt-4' data={{ id: pageInfo.id, service: service }} setLoading={(e)=>setLoading(e)}/>
      <Doubts />
      {loading && <Loader show={loading} />}
    </div>
  );
};
