import React, { useEffect, useState } from "react";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import logoB from "../../assets/logos/logo1.png";
import axiona from "../../assets/logos/axiona.png";
import heilab from "../../assets/logos/heilab.png";
import vitalgene from "../../assets/logos/vitalgene.png";
import {
  UilInstagram,
  UilTwitter,
  UilLinkedin,
  UilYoutube,
  UilFacebook,
} from "@iconscout/react-unicons";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { addMember, getLists } from "../../mailchimp/maichimp";
import { PrivacyModal } from "./privacyModal";

const FooterMain = styled.div`
  width: 100%;
  background: ${(props) =>
    props.isDoctor
      ? "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)"
      : "linear-gradient(90deg, #5097E3 -165.31%, #0CAC8C -1.22%, #30DF99 93.13%, #CEF719 138.7%, #F5EC1D 193.93%)"};
  padding-top: 3px;
  @media only screen and (max-width: 992px) {
    background: ${(props) =>
      props.isDoctor
        ? "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)"
        : "linear-gradient(90deg, #5097E3 -165.31%, #0CAC8C -1.22%, #30DF99 93.13%, #CEF719 138.7%, #F5EC1D 193.93%)"};
  }
`;

const FooterinMain = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    #5097e3 -165.31%,
    #0cac8c -1.22%,
    #30df99 93.13%,
    #cef719 138.7%,
    #f5ec1d 193.93%
  );
  padding-top: 3px;
  @media only screen and (max-width: 992px) {
    background: linear-gradient(
      90deg,
      #5097e3 -165.31%,
      #0cac8c -1.22%,
      #30df99 93.13%,
      #cef719 138.7%,
      #f5ec1d 193.93%
    );
  }
`;

const WhiteSection = styled.div`
  background: #ffffff;
  padding: 62px 0 53px;
  font-family: "IBM Plex Sans";
  // ---------------- FIRST COL ----------------
  #lb-logo {
    width: 80%;
    max-width: 276px;
    margin: 0 0 23px 15px;
  }
  #iso {
    width: 75%;
    max-width: 264px;
    margin: 0 0 0 -15px;
  }
  #nosotros {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #474a4b;
    max-width: 280px;
    a {
      color: #474a4b;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  // ---------------- SECOND COL ----------------
  #linea-lb {
    width: 90%;
    max-width: 277px;
    margin-bottom: 32px;
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.01em;
      margin-bottom: 10px;
    }
    a {
      color: #474a4b;
    }
  }
  // ---------------- THIRD COL ----------------
  #social-logos {
    width: 100%;
    max-width: 246px;
    display: flex;
    justify-content: space-between;
  }
  hr {
    opacity: 1;
  }
  #sub {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.01em;
    width: 100%;
    max-width: 351px;
    span {
      color: ${(props) => (props.isDoctor ? "#4D99E5" : "#00AD90")};
      font-weight: 700;
    }
  }
`;

const BottomSection = styled(Container)`
  padding: 31px 16px 28px;
  #logos {
    div {
      width: 100%;
      max-width: 372px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
  }
  #axiona {
    width: 112px;
  }
  #vitalgene {
    width: 116px;
  }
  #heilab {
    width: 80px;
  }
  p {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #ffffff;
  }
  @media only screen and (max-width: 992px) {
    #logos div {
      margin: 0 auto 16px;
    }
    padding: 31px 26px 41px;
    p {
      text-align: center;
    }
  }
  @media only screen and (max-width: 600px) {
    padding-bottom: 11px;
    p {
      max-width: 246px;
      margin: 0 auto 30px;
    }
  }
`;

const SubscribeForm = styled.form`
  margin-top: 30px;
  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 5px;
    font-family: "IBM Plex Sans";
    height: 35px;
    padding: 2px 10px;
  }
  .form-floating > .form-control:focus {
    padding-top: 0.5em;
  }
  .form-floating > label {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #6d7274;
    transform-origin: 50px -70px;
    background: #f6f6f6;
    padding: 2px 10px;
    opacity: 1;
  }
  .form-floating > .form-control {
    padding-left: 10px;
    padding-top: 0.5em;
  }
`;

const BlButton = styled.button`
  width: 100%;
  max-width: 145px;
  height: 35px;
  background: #4d99e5;
  border-radius: 10px;
  border: none;
  color: #fff;
`;

const GreenButton = styled.button`
  width: 100%;
  max-width: 145px;
  height: 35px;
  background: #00ad90;
  border-radius: 10px;
  border: none;
  color: #fff;
`;

export const Footer = (props) => {
  const number = "522212311313";
  const location = useLocation();
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;

  const [newsletter, setNewsletter] = useState(true);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    if (newsletter) {
      console.log(data.email);
      await addMember(data.email).then([window.location.replace("/")]);
    }
  };

  useEffect(() => {
    //getLists();
  }, []);

  return (
    <>
      {props.onDashboard ? (
        <FooterMain isDoctor={isDoctor}>
          <WhiteSection>
            <Container>
              {/* Labopat logo */}
              <img src={logoB} alt="Labopat" id="lb-logo" />
              <Row>
                <Col xs={12} lg={7}>
                  <Row>
                    {/* ISO and abstract */}
                    <Col xs={12} md={6} lg={7}>
                      <img
                        alt="ISO"
                        id="iso"
                        src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ffooter%2FISO-id.png?alt=media&token=27387622-345e-4b86-9ccb-3621d59d6991"
                      />
                      <p id="nosotros">
                        <span style={{ fontSize: "12px" }}>
                          Vigencia de 2023-03-01 al 2026-03-01
                        </span>{" "}
                        <br />
                        Alcance: Servicios genómicos mediante secuenciación de
                        siguiente generación (NGS).
                      </p>
                      <br />
                      <p id="nosotros">
                        Somos una empresa mexicana con más de 30 años de
                        trayectoria que tiene como misión brindar la mejor
                        atención y calidad en los servicios médicos de
                        diagnóstico... <a href="nosotros">Saber más</a>
                      </p>
                    </Col>
                    {/* Phone and links */}
                    {isDoctor ? (
                      <Col xs={12} md={6} lg={5}>
                        <a
                          href={`https://wa.me/${number}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            alt="Linea Labopat"
                            id="linea-lb"
                            src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ffooter%2Flinea-doc.png?alt=media&token=60581ece-040b-49c3-a17c-19d5b00fed3b"
                          />
                        </a>
                        <ul>
                          <li>
                            <a href="/nosotros" rel="noreferrer">
                              Nosotros
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FTerminos%20y%20condiciones%20.pdf?alt=media&token=f94e2aa8-9d69-45fd-ab0b-1073683e4111"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Términos y condiciones
                            </a>
                          </li>
                          <li>
                            <a href="/formulario-calidad">
                              Quejas y Sugerencias
                            </a>
                          </li>
                          <li
                            onClick={() => setShowPrivacyModal(true)}
                            style={{ cursor: "pointer" }}
                          >
                            Aviso de privacidad
                          </li>
                          <li>
                            <a
                              href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprivacy%2F2024%2FLP_solicitud_ARCO%20(1).pdf?alt=media&token=594ffdc7-7eaf-4dc2-8c41-f45c5fab3e82"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Solicitud Derechos ARCO
                            </a>
                          </li>
                        </ul>
                      </Col>
                    ) : (
                      <Col xs={12} md={6} lg={5}>
                        <a
                          href={`https://wa.me/${number}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            alt="Linea Labopat"
                            id="linea-lb"
                            src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ffooter%2Flinea-lb-verde.png?alt=media&token=dcbd5449-b583-44bc-9a54-0fdc511ba5d7"
                          />
                        </a>
                        <ul>
                          <li>
                            <a href="/nosotros" rel="noreferrer">
                              Nosotros
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FTerminos%20y%20condiciones%20.pdf?alt=media&token=f94e2aa8-9d69-45fd-ab0b-1073683e4111"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Términos y condiciones
                            </a>
                          </li>
                          <li>
                            <a href="/formulario-calidad">
                              Quejas y Sugerencias
                            </a>
                          </li>
                          <li
                            onClick={() => setShowPrivacyModal(true)}
                            style={{ cursor: "pointer" }}
                          >
                            Aviso de privacidad
                          </li>
                          <li>
                            <a
                              href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprivacy%2F2024%2FLP_solicitud_ARCO%20(1).pdf?alt=media&token=594ffdc7-7eaf-4dc2-8c41-f45c5fab3e82"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Solicitud Derechos ARCO
                            </a>
                          </li>
                        </ul>
                      </Col>
                    )}
                  </Row>
                </Col>
                {/* Social networks and subscribe */}
                {isDoctor ? (
                  <Col xs={12} lg={5}>
                    <div id="social-logos">
                      <a
                        href="https://www.facebook.com/labopatdiagnosticos"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilFacebook size="26" color="#4D99E5" />
                      </a>
                      <a
                        href="https://twitter.com/LabopatD"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilTwitter size="26" color="#4D99E5" />
                      </a>
                      <a
                        href="https://www.instagram.com/labopat_diagnosticos/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilInstagram size="26" color="#4D99E5" />
                      </a>
                      <a
                        href="https://mx.linkedin.com/company/labopat/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilLinkedin size="26" color="#4D99E5" />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UC1r3xLFsIwb4OM04ImMAyAA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilYoutube size="26" color="#4D99E5" />
                      </a>
                    </div>
                    <hr />
                    <p id="sub">
                      <span style={{ color: "#4D99E5" }}>Suscríbete</span> a
                      nuestra Newsletter.
                      <br />
                      Tenemos información de valor, interesante y lo mejor de
                      todo, ¡es gratis!
                    </p>
                    {/* Subscribe form */}
                    <SubscribeForm onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col xs={7} style={{ paddingLeft: "0" }}>
                          <FloatingLabel
                            label="Correo electrónico"
                            className="mb-3"
                          >
                            <Form.Control
                              {...register("email", {
                                required: true,
                                pattern: {
                                  value:
                                    /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                                  message: "Correo inválido",
                                },
                              })}
                              key="newsletter"
                              id="email"
                              name="email"
                              type="email"
                              placeholder="name@example.com"
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={5}>
                          <BlButton onClick={onSubmit} type="submit">
                            Suscribirme
                          </BlButton>
                        </Col>
                      </Row>
                    </SubscribeForm>
                  </Col>
                ) : (
                  <Col xs={12} lg={5}>
                    <div id="social-logos">
                      <a
                        href="https://www.facebook.com/labopatdiagnosticos"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilFacebook size="26" color="#00AD90" />
                      </a>
                      <a
                        href="https://twitter.com/LabopatD"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilTwitter size="26" color="#00AD90" />
                      </a>
                      <a
                        href="https://www.instagram.com/labopat_diagnosticos/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilInstagram size="26" color="#00AD90" />
                      </a>
                      <a
                        href="https://mx.linkedin.com/company/labopat/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilLinkedin size="26" color="#00AD90" />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UC1r3xLFsIwb4OM04ImMAyAA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilYoutube size="26" color="#00AD90" />
                      </a>
                    </div>
                    <hr />
                    <p id="sub">
                      <span style={{ color: "#00AD90" }}>Suscríbete</span> a
                      nuestra Newsletter.
                      <br />
                      Tenemos información de valor, interesante y lo mejor de
                      todo, ¡es gratis!
                    </p>
                    {/* Subscribe form */}
                    <SubscribeForm onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col xs={7} style={{ paddingLeft: "0" }}>
                          <FloatingLabel
                            label="Correo electrónico"
                            className="mb-3"
                          >
                            <Form.Control
                              {...register("email", {
                                required: true,
                                pattern: {
                                  value:
                                    /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                                  message: "Correo inválido",
                                },
                              })}
                              key="newsletter"
                              id="email"
                              name="email"
                              type="email"
                              placeholder="name@example.com"
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={5}>
                          <GreenButton onClick={onSubmit} type="submit">
                            Suscribirme
                          </GreenButton>
                        </Col>
                      </Row>
                    </SubscribeForm>
                  </Col>
                )}
              </Row>
            </Container>
          </WhiteSection>
          <BottomSection>
            <Row>
              {/* Logos */}
              <Col
                xs={{ span: 12, order: 1 }}
                lg={{ span: 5, offset: 7, order: 1 }}
                id="logos"
              >
                <div>
                  <a
                    href="https://www.axionagenetics.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={axiona} alt="Axiona" id="axiona" />
                  </a>
                  <a
                    href="https://www.vitalgene.com.mx/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={vitalgene} alt="Vitalgene" id="vitalgene" />
                  </a>
                  <a
                    href="https://www.heilab.com.mx/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={heilab} alt="heilab" id="heilab" />
                  </a>
                </div>
              </Col>
              {/* License and rights */}
              <Col xs={{ span: 12, order: 2 }} lg={{ span: 5, order: 3 }}>
                <p>
                  Sitio web creado por: Heilab!{" "}
                  <a
                    href="https://www.heilab.com.mx/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    www.heilab.com.mx
                  </a>
                </p>
              </Col>
              <Col xs={{ span: 12, order: 3 }} lg={{ span: 7, order: 2 }}>
                <p>
                  © 2024 Labopat diagnósticos - Todos los derechos reservados
                </p>
              </Col>
            </Row>
          </BottomSection>
        </FooterMain>
      ) : (
        <div
          style={
            location.pathname === "/alta-especialidad" // Navbar gris para landing page del curso
              ? { background: "#474A4B" }
              : props.isDoctor && props.onDashboard
              ? {
                  background:
                    "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)",
                }
              : {
                  background:
                    "linear-gradient(90deg, #5097E3 -165.31%, #0CAC8C -1.22%, #30DF99 93.13%, #CEF719 138.7%, #F5EC1D 193.93%)",
                }
          }
          id="footer"
        >
          <FooterinMain>
            <WhiteSection>
              <Container>
                {/* Labopat logo */}
                <img src={logoB} alt="Labopat" id="lb-logo" />
                <Row>
                  <Col xs={12} lg={7}>
                    <Row>
                      {/* ISO and abstract */}
                      <Col xs={12} md={6} lg={7}>
                        <img
                          alt="ISO"
                          id="iso"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ffooter%2FISO-id.png?alt=media&token=27387622-345e-4b86-9ccb-3621d59d6991"
                        />
                        <p id="nosotros">
                          <span style={{ fontSize: "12px" }}>
                            Vigencia de 2023-03-01 al 2026-03-01
                          </span>{" "}
                          <br />
                          Alcance: Servicios genómicos mediante secuenciación de
                          siguiente generación (NGS).
                        </p>
                        <br />
                        <p id="nosotros">
                          Somos una empresa mexicana con más de 30 años de
                          trayectoria que tiene como misión brindar la mejor
                          atención y calidad en los servicios médicos de
                          diagnóstico... <a href="nosotros">Saber más</a>
                        </p>
                      </Col>
                      {/* Phone and links */}
                      <Col xs={12} md={6} lg={5}>
                        <a
                          href={`https://wa.me/${number}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            alt="Linea Labopat"
                            id="linea-lb"
                            src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ffooter%2Flinea-lb-verde.png?alt=media&token=dcbd5449-b583-44bc-9a54-0fdc511ba5d7"
                          />
                        </a>
                        <ul>
                          <li>
                            <a href="/nosotros" rel="noreferrer">
                              Nosotros
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FTerminos%20y%20condiciones%20.pdf?alt=media&token=f94e2aa8-9d69-45fd-ab0b-1073683e4111"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Términos y condiciones
                            </a>
                          </li>
                          <li>
                            <a href="/formulario-calidad">
                              Quejas y Sugerencias
                            </a>
                          </li>
                          <li
                            onClick={() => setShowPrivacyModal(true)}
                            style={{ cursor: "pointer" }}
                          >
                            Aviso de privacidad
                          </li>
                          <li>
                            <a
                              href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprivacy%2F2024%2FLP_solicitud_ARCO%20(1).pdf?alt=media&token=594ffdc7-7eaf-4dc2-8c41-f45c5fab3e82"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Solicitud Derechos ARCO
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  {/* Social networks and subscribe */}
                  <Col xs={12} lg={5}>
                    <div id="social-logos">
                      <a
                        href="https://www.facebook.com/labopatdiagnosticos"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilFacebook size="26" color="#00AD90" />
                      </a>
                      <a
                        href="https://twitter.com/LabopatD"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilTwitter size="26" color="#00AD90" />
                      </a>
                      <a
                        href="https://www.instagram.com/labopat_diagnosticos/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilInstagram size="26" color="#00AD90" />
                      </a>
                      <a
                        href="https://mx.linkedin.com/company/labopat/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilLinkedin size="26" color="#00AD90" />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UC1r3xLFsIwb4OM04ImMAyAA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UilYoutube size="26" color="#00AD90" />
                      </a>
                    </div>
                    <hr />
                    <p id="sub">
                      <span style={{ color: "#00AD90" }}>Suscríbete</span> a
                      nuestra Newsletter.
                      <br />
                      Tenemos información de valor, interesante y lo mejor de
                      todo, ¡es gratis!
                    </p>
                    {/* Subscribe form */}
                    <SubscribeForm onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col xs={7} style={{ paddingLeft: "0" }}>
                          <FloatingLabel
                            label="Correo electrónico"
                            className="mb-3"
                          >
                            <Form.Control
                              {...register("email", {
                                required: true,
                                pattern: {
                                  value:
                                    /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                                  message: "Correo inválido",
                                },
                              })}
                              key="newsletter"
                              id="email"
                              name="email"
                              type="email"
                              placeholder="name@example.com"
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={5}>
                          <GreenButton onClick={onSubmit} type="submit">
                            Suscribirme
                          </GreenButton>
                        </Col>
                      </Row>
                    </SubscribeForm>
                  </Col>
                </Row>
              </Container>
            </WhiteSection>
            <BottomSection>
              <Row>
                {/* Logos */}
                <Col
                  xs={{ span: 12, order: 1 }}
                  lg={{ span: 5, offset: 7, order: 1 }}
                  id="logos"
                >
                  <div>
                    <a
                      href="https://www.axionagenetics.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={axiona} alt="Axiona" id="axiona" />
                    </a>
                    <a
                      href="https://www.vitalgene.com.mx/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={vitalgene} alt="Vitalgene" id="vitalgene" />
                    </a>
                    <a
                      href="https://www.heilab.com.mx/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={heilab} alt="heilab" id="heilab" />
                    </a>
                  </div>
                </Col>
                {/* License and rights */}
                <Col xs={{ span: 12, order: 2 }} lg={{ span: 5, order: 3 }}>
                  <p>
                    Sitio web creado por: Heilab!{" "}
                    <a
                      href="https://www.heilab.com.mx/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      www.heilab.com.mx
                    </a>
                  </p>
                </Col>
                <Col xs={{ span: 12, order: 3 }} lg={{ span: 7, order: 2 }}>
                  <p>
                    © 2024 Labopat diagnósticos - Todos los derechos reservados
                  </p>
                </Col>
              </Row>
            </BottomSection>
          </FooterinMain>
          <PrivacyModal
            show={showPrivacyModal}
            hide={() => setShowPrivacyModal(false)}
          />
        </div>
      )}
    </>
  );
};
