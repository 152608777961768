import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { TEXT_BLACK } from "../../helpers/colors";
import { UilLocationPinAlt, UilClock, UilEnvelopeMinus, UilPhone } from '@iconscout/react-unicons';
import MapaAgs from '../../assets/aguascalientes/ssAgsMap.png';

const StyledDiv = styled.div`
  padding-bottom: 10px;
  #info-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #info-div {
    width: 100%;
    max-width: 431px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--Gray-OUTLINES-AND-LINES, #E0ECEC);
    background: #FFF;
    > div {
      display: flex;
      gap: 10px; /* Adjust the gap as needed */
      p {
        color: ${TEXT_BLACK};
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.18px;
        span {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
  img {
    width: 90%;
    max-width: 406px;
    max-height: 406px;
    margin: 0 auto;
    filter: drop-shadow(-6px 9px 23px rgba(0, 0, 0, 0.10));
  }
  button {
    color: #474A4B;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 20px;
    border: 4px solid #30DF99;
    transition: all ease 0.3s;
    margin: 36px auto;
    background: transparent;
    width: 200px;
    :hover {
      background: #30DF99;
      color: #FFF;
    }
  }
  @media only screen and (width <= 768px) {s
    #info-div div p {
      font-size: 16px;
      span {
        font-size: 18px;
      }
    }
  }
`;

const IconContainer = styled.div`
  width: 40px; /* Icon width */
  svg {
    color: #30DF99;
    margin-right: 10px;
    margin-top: -3px;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
`;

export const ContactAgs = () => {

  const openMap = () => {
    window.open('https://maps.app.goo.gl/7gzm9ucRoHrKL1VS6', '_blank');
  };

  const handleEmailClick = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <StyledDiv style={{backgroundColor: "#FFF"}}>
      <Container>
        <Row>
          <Col xs={12} lg={12} className="d-flex">
            <img src={MapaAgs} alt="Mapa aguascalientes" />
          </Col>
          <button onClick={openMap}>Ver en Google</button>
          <Col xs={12} lg={12} id="info-col"> 
            <div id="info-div">
              <div>
                <IconContainer>
                  <UilLocationPinAlt size={40} />
                </IconContainer>
                <TextContainer>
                  <p>
                    <span>Edificio Médico Okabe.</span>
                    <br />
                    Blvd. Luis Donaldo Colosio Murrieta 208, Lomas del Campestre II, 20119, Aguascalientes, Ags.
                  </p>
                </TextContainer>
              </div>
              <div>
                <IconContainer>
                  <UilClock size={40} />
                </IconContainer>
                <TextContainer>
                  <p><span>Horario de atención</span><br/>Lunes a viernes de 8:00 a 20:00 h.<br/>Sábados de 8:00 a 14:00 h.</p>
                </TextContainer>
              </div>
              <div>
                <IconContainer>
                  <UilPhone size={40} />
                </IconContainer>
                <TextContainer>
                <a href="tel:+524497102251"><p>449 710 22 51</p></a>
                </TextContainer>
              </div>
              <div>
                <IconContainer>
                  <UilEnvelopeMinus size={40} />
                </IconContainer>
                <TextContainer  onClick={() => handleEmailClick('recepcion09.labopat@gmail.com')}>
                  <p>recepcion09.labopat@gmail.com</p>
                </TextContainer>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledDiv>
  )
};