import React from 'react';
import Family from '../../assets/blog/Family.png';
import { Col, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { FOSFO_GREEN, TEXT_BLACK } from './colors';

const StyledContainer = styled(Container)`
  height: 310px;
  background: linear-gradient(90deg, #5097E3 -29.62%, #0CAC8C 29.38%, #30DF99 79.81%, #CEF719 99.83%, #F5EC1D 121.92%);
  background-size: cover;
  background-position: center;
  margin: 20px auto;
  overflow: hidden;
  padding: 25px 45px;
  position: relative;

  @media only screen and (max-width: 768px) {
    height: 528px;
  }

  @media only screen and (min-width: 768px) {
    .col-12 {
      position: relative;
      z-index: 1;
    }
    #family {
      width: 80%;
      max-width: 850px;
      position: absolute;
      right: -10%;
      bottom: -150px;
      z-index: 0;
    }
  }

  h1 {
    color: #F2F7F9;
    font-family: IBM Plex Sans Condensed;
    font-size: 45px;
    font-weight: 500;
    line-height: 55px;
    max-width: 360px;
    margin-bottom: 10px;
  }
  p {
    color: #F2F7F9;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    max-width: 360px;
  }
  span {

  }
  #sub-btn {

  }
  input[type="text"] {
    border-radius: 5px;
    background: #FFF;
    border: none;
    padding: 8px 10px;
    width: auto;
    width: 100%;
    box-sizing: border-box;
  }

  form {
    display: block;
  }
  
  @media only screen and (min-width: 48rem) {
    form {
      display: flex;
    }
  }

  @media only screen and (min-width: 32rem) {
    button {
      width: auto;
    }
  }
  
  @media only screen and (min-width: 48rem) {
    button {
      margin-left: 15px;
      margin-top: 0 !important;
    }
  }

  button {
    border-radius: 10px;
    background: ${FOSFO_GREEN};
    color: ${TEXT_BLACK};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border: none;
    padding: 7px 20px;
    margin-top: 1rem;
    transition: all ease 0.3s;
    :hover {
      filter: brightness(1.15);
    }
  }
`;

export const BannerNews = () => {
  return (
    <StyledContainer>
      <Col xs={12} md={7} lg={5}>
        <h1>¡Sigue cuidando de ti y tu familia!</h1>
        <p><span>Suscríbete</span> a nuestra Newsletter. Tenemos información de valor, interesante y lo mejor de todo, ¡es gratis!</p>
        <div>
          <form action="">
            <input type="text" />
            <button id='sub-btn'>Suscribirme</button>
          </form>
        </div>
      </Col>
      <img src={Family} alt="" id='family'/>
    </StyledContainer>
  );
};
