import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'react-bootstrap';
import { UilInfoCircle, UilHunting, UilEye } from '@iconscout/react-unicons';
import AgsDoctor from '../../assets/aguascalientes/agsDoctor.png';
import LogoAgs from '../../assets/aguascalientes/lbptAgs.png';

const StyledDiv = styled.div`
  background: #FFFFFF;
  paddingBottom: 40px;
  height: 243px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 70%;
    max-width: 370px;
  }
  @media only screen and (max-width: 992px) {
    height: 150px;
  }
`;

const StyledRow = styled(Row)`
  img {
    height: auto !important;
    max-width: 95%;
    margin-top: -20%;
  }
  #img-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    button {
      color: #474A4B;
      font-size: 20px;
      font-weight: 600;
      padding: 15px 50px;
      border-radius: 20px;
      border: 4px solid #30DF99;
      transition: all ease 0.3s;
      margin-top: 36px;
      background: transparent;
      :hover {
        background: #30DF99;
        color: #FFF;
      }
    }
  }
  .item {
    padding: 19px 0 19px 53px;
    position: relative;
    width: 90%;
    max-width: 450px;
  }
  #second {
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
  }
  h1 {
    color: #474A4B;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: #474A4B;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
  }
  span {
    font-weight: 600;
  }
  .icon {
    position: absolute;
    left: 0;
    top: 19px;
  }
  .col-12 {
    display: flex;
    justify-content: center;
    align-items: start;
  }
  #text-col {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 992px) {
    p {
      font-size: 16px;
    }
    img {
      max-width: 85%;
      margin-top: 0;
    }
    #img-col {
      button {
        padding: 10px 40px;
      }
    }
  }
`;

export const MisionVision = () => {
	return (
    <>
    {/* Labopat logo */}
    <StyledDiv>
      <img src={LogoAgs} alt="Labopat" />
    </StyledDiv>

    {/* Doctor Mision Vision */}
		<div style={{ background: "#F9F9F9", paddingBottom: "40px" }}>
			<Container style={{ padding: '40px 0 0 0' }}>
        <StyledRow>
          <Col xs={{span: 12, order: 2}} lg={{span: 6, order: 1}} xl={6} id='img-col'>
            <img src={AgsDoctor} alt="Doctor sonriendo" />
            <button onClick={() => window.location.href = '/nosotros'}>Conoce Labopat</button>
          </Col>
          <Col xs={{span: 12, order: 1}} lg={{span: 6, order: 2}} xl={6} id='text-col'>
            <div className="item">
              <UilInfoCircle className="icon" size={40}/>
              <h1>¿Quiénes somos?</h1>
              <p>Una empresa de servicios de <span>diagnóstico de alta especialidad con más de 30 años de experiencia</span> en el sector. Ofrecemos un amplio catálogo de estudios y tecnología de vanguardia para brindar un diagnóstico confiable.</p>
            </div>
            <div className="item" id="second">
              <UilHunting className="icon" size={40}/>
              <h1>Nuestra misión</h1>
              <p>Dar el mejor servicio de diagnóstico a pacientes, médicos e instituciones, con la mejor atención al cliente, tecnología y siempre de la mano de la ética.</p>
            </div>
            <div className="item">
              <UilEye className="icon" size={40}/>
              <h1>Nuestra visión</h1>
              <p>Ser la empresa de salud líder en diagnóstico especializado en México, que marque <span>el nuevo estándar de calidad y atención al paciente, médico y cliente,</span> así como los procesos prácticos de vanguardia en nuestra rama de la medicina.</p>
            </div>
          </Col>
        </StyledRow>
			</Container>
		</div>
    </>
	);
};
