import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import ImageBannerMB from '../../assets/aguascalientes/banner ags 2 MB.png';
import ImageBanner from '../../assets/aguascalientes/banner ags 2.png';

const Bg = styled.div`
  width: 100%;
  background: url('${ImageBanner}') center;
  background-size: cover;
  @media only screen and (max-width: 768px) {
    background: url('${ImageBannerMB}') center;
  }
`;

const Slide = styled(Container)`
  height: 460px;
  padding-top: 76px;
  display: flex;
  align-items: start;
  justify-content: right;
  flex-direction: column;
  #green-div {
    width: 100%;
    padding: 10px 40px 20px;
    background: var(--CA-BOTONES, linear-gradient(79deg, #5097E3 -41.6%, #0CAC8C 26.14%, #30DF99 84.04%, #CEF719 107.01%, #F5EC1D 132.38%));
    color: var(--Blanco, #F3F3F3);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "IBM Plex Sans";
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  #white-div {
    display: inline-flex;
    padding: 0px 32px;
    height: 70px;
    justify-content: center;
    align-items: center;
    background: var(--Blanco, #F3F3F3);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-left: auto;
    margin-top: 56px;
    p {
      font-family: "IBM Plex Sans";
      font-size: 55px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background: var(--OUTLINE-CARDS-LABOPAT, linear-gradient(90deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0;
      margin-top: -10px;
    }
  }
  @media only screen and (max-width: 1400px) {
    #green-div {
      font-size: 60px;
    }
  }
  @media only screen and (max-width: 768px) {
    height: 700px;
    padding: 80px 0 0;
    #green-div {
      font-size: 44px;
    }
    #white-div {
      height: 27px;
      margin: auto auto 70px;
      p {
        font-size: 40px;
        margin-top: -7px;
      }
    }
  }
`;

export const AgsBanner = () => {
  return (
    <Bg>
      <Slide>
        <div id='green-div'>Labopat llega a Aguascalientes</div>
        <div id='white-div'><p>¡Descúbrelo!</p></div>
      </Slide>
    </Bg>
  );
};
