import React, { useState } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  background-color: #F2F7F9;
  width: 100%;
  h1 {
    color: #474A4B;
    font-size: 70px;
    font-weight: 300;
    line-height: 105.5%;
    text-align: center;
    span {
      font-weight: 600;
      background: linear-gradient(90deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .values-cont {
    height: 174px;
    width: 100%;
    display: flex;
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    .values-cont {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 90%;
      height: auto;
      margin: 0 auto;
      border-radius: 20px;
      overflow: hidden;
    }
    h1 {
      font-size: 32px;
      margin-bottom: 50px;
    }
  }
`;

const ValueDiv = styled.div`
  position: absolute;
  left: ${({id}) => id * 20}%;
  width: 20%;
  overflow: hidden;
  height: 100%;
  background: ${({bgColor}) => bgColor};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 2;
  opacity: ${({hovered}) => hovered ? 0 : 1};
  // ----- FONT -----
  h2 {
    color: #F3F3F3;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 50px;
    font-weight: 300;
    line-height: 105.5%;
    margin-bottom: -10px;
  }
  @media only screen and (max-width: 768px) {
    width: ${({isHovered}) => isHovered ? 100 : 200}%;
    position: inherit;
    height: ${({isHovered}) => isHovered ? 116 : 65}px;
    align-items: ${({isHovered}) => isHovered ? 'center' : 'flex-end'};
    left: auto;
    opacity: 1;
    padding: 0 12px;
    h2 {
      font-size: ${({isHovered}) => isHovered ? 35 : 50}px;
      margin-bottom: ${({isHovered}) => isHovered ? 0 : -13}px;
      transition: all 0.3s ease;
    }
  }
`;

const FullDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({bgColor}) => bgColor};
  opacity: ${({hovered}) => hovered ? 1 : 0};
  transition: all 0.3s ease;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F3F3F3;
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 100px;
  font-style: normal;
  font-weight: 300;
  line-height: 105.5%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const OurValues = () => {
  const [hovered, setHovered] = useState();

  return (
    <StyledDiv>
      <h1>Nuestros <span>valores</span></h1>
      <div className="values-cont">
        <ValueDiv 
          bgColor="#3BD1E6"
          id={0}
          hovered={hovered}
          isHovered={hovered?.name === 'Confianza'}
          onMouseEnter={() => setHovered({name: 'Confianza', color: '#3BD1E6'})}
          onMouseLeave={() => setHovered(undefined)}
        >
          <h2>Confianza</h2>
        </ValueDiv>
        <ValueDiv 
          bgColor="#30DF99"
          id={1}
          hovered={hovered}
          isHovered={hovered?.name === 'Calidad'}
          onMouseEnter={() => setHovered({name: 'Calidad', color: '#30DF99'})}
          onMouseLeave={() => setHovered(undefined)}
        >
          <h2>Calidad</h2>
        </ValueDiv>
        <ValueDiv 
          bgColor="#3BD1E6"
          id={2}
          hovered={hovered}
          isHovered={hovered?.name === 'Vanguardia'}
          onMouseEnter={() => setHovered({name: 'Vanguardia', color: '#3BD1E6'})}
          onMouseLeave={() => setHovered(undefined)}
        >
          <h2>Vanguardia</h2>
        </ValueDiv>
        <ValueDiv 
          bgColor="#4D99E5"
          id={3}
          hovered={hovered}
          isHovered={hovered?.name === 'Espíritu de servicio'}
          onMouseEnter={() => setHovered({name: 'Espíritu de servicio', color: '#4D99E5'})}
          onMouseLeave={() => setHovered(undefined)}
        >
          <h2>Espíritu de servicio</h2>
        </ValueDiv>
        <ValueDiv 
          bgColor="#0CAC8C"
          id={4}
          hovered={hovered}
          isHovered={hovered?.name === 'Empatía y Humanidad'}
          onMouseEnter={() => setHovered({name: 'Empatía y Humanidad', color: '#0CAC8C'})}
          onMouseLeave={() => setHovered(undefined)}
        >
          <h2>Empatía y Humanidad</h2>
        </ValueDiv>
        {hovered !== '' ? 
          <FullDiv 
            bgColor={hovered?.color}
            hovered={hovered}
          >
            {hovered?.name}
          </FullDiv>
        : null}
      </div>
    </StyledDiv>
  )
};

export default OurValues;