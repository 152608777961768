import React from "react";
import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { UilBars, UilPlus } from '@iconscout/react-unicons';
import LOGOCURSO from '../../assets/curso-alta-especialidad/logo-curso-alta-especialidad.png';

const Background = styled.div`
  width: 100%;
  height: 80px;
  background: #F2F7F9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  z-index: 100;
  position: fixed;
  top: 0;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Logo = styled.img`
  width: 225px;
  @media only screen and (min-width: 768px) {
    width: 311px;
  }
`;

const Links = styled.div`
  a {
    padding: 12px 20px;
    font-family: 'Faustina';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #474A4B;
    @media only screen and (min-width: 1200px) {
      margin-left: 20px;
    }
  }
  #apply {
    font-family: 'IBM Plex Sans';
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
    background: #00AD90;
    margin-left: 25px;
  }
`;

const StyledDropdown = styled(DropdownButton)`
  button {
    border: none !important;
    background: none !important;
  }
  button::after {
    display: none;
  }
  .show {
    transition:height 0.3s ease-in-out;
    transition-delay: 0.1s;
  }
  .dropdown-menu {
    background: #474A4B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0;
    border: none;
    padding: 0;
    padding-bottom: 10px;
    a {
      width: 176px;
      font-family: 'Faustina';
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #F2F7F9;
      padding: 7px 0 8px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 11px;
      margin-right: -12px;
    }
  }
  #orange {
    position: relative;
    background: #F9AD62;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
  }
  #plus {
    position: absolute;
    left: 15px;
    top: 52%;
    transform: translate(0, -50%);
  }
`;

export const Navbar = () => {
  const {width} = useWindowDimensions();

  const scrollTo = (id) => {
    var elem = document.getElementById(id);
    window.scroll({
      top: getOffset(elem)-80,
      left: 0,
      behavior: 'smooth'
    });
  }

  const getOffset = ( el ) => {
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return _y;
  }

  return (
    <>
    <Background>
      <Container>
        <Logo alt='Curso alta especialidad 2024' src={LOGOCURSO}/>
        {width > 992 ? (
          <Links>
            <a onClick={()=>scrollTo('')}>Home</a>
            {/* <a onClick={()=>scrollTo('')}>¿Por qué nosotros?</a> */}
            <a onClick={()=>scrollTo('contact')}>Contacto</a>
            <a onClick={()=>scrollTo('howToApply')} id='apply'>¿CÓMO APLICAR?</a>
          </Links>
        ) : (
          <StyledDropdown id="dropdown-basic-button" title={<UilBars color='#00AD90' size={42}/>}>
            <Dropdown.Item onClick={()=>scrollTo('howToApply')} id='orange'><UilPlus size={18} color='#F2F7F9' id='plus'/>&nbsp;&nbsp;APLICAR AHORA</Dropdown.Item>
            {/* <Dropdown.Item onClick={()=>scrollTo('')}>¿Por qué nosotros?</Dropdown.Item> */}
            <Dropdown.Item onClick={()=>scrollTo('planDeEstudios')}>Plan de estudios</Dropdown.Item>
            <Dropdown.Item onClick={()=>scrollTo('activities')}>Actividades</Dropdown.Item>
            <Dropdown.Item onClick={()=>scrollTo('academy')}>Academia</Dropdown.Item>
            {/* <Dropdown.Item onClick={()=>scrollTo()}>Testimonios</Dropdown.Item> */}
            <Dropdown.Item onClick={()=>scrollTo('contact')}>Contacto</Dropdown.Item>
          </StyledDropdown>
        )}
      </Container>
    </Background>
    <div style={{height: '80px '}}/>
    </>
  )
};