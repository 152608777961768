import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import logo1 from '../assets/logos/logo1.png';
import { UilTimes, UilCheck, UilExclamation } from '@iconscout/react-unicons';

export const Toast = ({ title, message, type }) => {
    const [visible, setVisible] = useState(true);
    const [opacity, setOpacity] = useState(1);
    const [remainingTime, setRemainingTime] = useState(4);

    useEffect(() => {

        setRemainingTime(remainingTime - 1);
        const timer = setInterval(() => {
            setRemainingTime((prevTime) => prevTime - 1);
        }, 1000);

        setTimeout(() => {
            setVisible(false);
            clearInterval(timer);
        }, 1500);

        return () => clearInterval(timer);
    }, []);

    useEffect(()=>{
        if(remainingTime < .5){
            setOpacity(0);
        }
    },[remainingTime])

    return (
        <div>
            {visible && (
                <StyledToast visible={visible} type={type} opacity={opacity}>
                    <Title>{title}</Title>
                    <hr />
                    {/* <img alt='Labopat' src={logo1}/> */}
                    <ToastContent>{message}</ToastContent>

                    <IconStatus type={type} onClick={() => setVisible(false)}>
                        {type === "success"
                            ? <UilCheck size={40} />
                            : type === "error"
                                ? <UilTimes size={40} />
                                : <UilExclamation size={40} />
                        } 
                        
                    </IconStatus>
                    {/* <BottomBar remainingTime={remainingTime} type={type} /> */}
                    <CloseButton type={type} onClick={() => setVisible(false)}><UilTimes size={40} /></CloseButton>
                </StyledToast>
            )}
        </div>
    );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const StyledToast = styled.div`
    box-shadow: 0px 10px 20px rgba(164, 164, 164, 1);
    position: fixed;
    width: 100%;
    max-width: 500px;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    opacity: ${(props) => (props.opacity)};
    background-color: #FFF;
    color: #333;
    padding: 15px 25px 5px;
    border-radius: 10px;
    border: 1px solid #E0ECEC;
    background: #FFF;
    z-index: 9999;
    animation: ${(props) =>
        props.opacity > 0 ? fadeIn : fadeOut} 0.4s ease-in-out;
    img {
        height: 20px;
        margin-bottom: 8px;
    }
`;

const Title = styled.h1`
    color: #474A4B;
    font-family: IBM Plex Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ToastContent = styled.div`
    font-size: 16px;
    margin: 6px auto 12px;
    color: #474A4B;
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
`;

const BottomBar = styled.div`
    position: relative;
    height: 5px;
    width: ${(props) => (props.remainingTime / 4) * 100}%;
    background-color: ${(props) => getAlertColor(props.type)};
    transition: width 1s;
`;

const CloseButton = styled.button`
    background: ${(props) => getAlertColor(props.type)};
    display: flex;
    align-items: center;
    padding: 3px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    color: #FFF;
    position: absolute;
    top: -20px;
    right: 20px;
    cursor: pointer;
`;

const IconStatus = styled.button`
    background: ${(props) => getAlertColor(props.type)};
    display: flex;
    align-items: center;
    padding: 3px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    color: #FFF;
    cursor: pointer;
    margin: 20px auto 15px;
`;

const getAlertColor = (type) => {
    switch (type) {
        case 'warning':
        return '#FFC900';
        case 'error':
        return '#E90000';
        case 'success':
        return '#00AC90';
        default:
        return '#B1B1B1';
    }
};