import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Highlights } from '../hightlights/highlights';

const DiscountsContainer = styled(Container)`
  padding: 60px 0 120px;
  #text-col {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    p {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 44px;
      line-height: 125%;
      color: #474A4B;
      max-width: 518px;
      margin-bottom: 68px;
      span {
        font-weight: 600;
      }
    }
    @media only screen and (max-width: 992px) {
      align-items: center;
      p {
        font-size: 32px;
        text-align: center;
        margin-bottom: 38px;
      }
    }
  }
  #img-col {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 992px) {
      margin-bottom: 32px;
    }
    img {
      max-width: 100%;
    }
  }
`;

const TransparentButton = styled.button`
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-size: 29px;
  line-height: 55px;
  text-align: center;
  color: #474A4B;
  background: none;
  width: 280px;
  height: 68px;
  border: 4px solid #30DF99;
  border-radius: 20px;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    color: #fff;
    background: #30DF99;
  }
`;

export const App = () => {
  return (
    <>
      {/* Highlights slider */}
      <Highlights data-aos='fade-up'/>
      {/* Content container */}
      <DiscountsContainer>
        <Row>
          <Col xs={12} lg={6} id='img-col'>
            <img data-aos='fade-up' alt="" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FhomeSlider%2FphoneGreen.png?alt=media&token=c072776f-5d99-422f-ac09-e86f1b6a2016"/>
          </Col>
          <Col xs={12} lg={6} id='text-col'>
            <p data-aos='fade-up'><span>Obtén descuentos y promociones</span> a través de nuestra plataforma de solicitud de estudios.</p>
            <TransparentButton onClick={()=>{window.location.href = '/registro'}}>Crea tu cuenta</TransparentButton>
          </Col>
        </Row>
      </DiscountsContainer>
    </>
  );
};
