import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { UilAngleDown, UilAngleUp, UilMultiply } from '@iconscout/react-unicons';
import logoB from "../../../assets/logos/logo1.png";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { TEXT_BLACK } from '../../../helpers/colors';
import { Searcher } from './searcher';
import { CATEOGORIES } from '../colors';
import burguer from '../../../assets/menu/burguer.png';

const StyledNavbar = styled.div`
  z-index: 7;
  position: sticky; 
  top: 0;
  width: 100%;
  opacity: 1;
  background: #FFFFFF !important;
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.15);
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
  }
  #options-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    * {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; 
    }
    p {
      color: ${TEXT_BLACK};
      font-size: 15px;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      margin-bottom: 0;
      :hover {
        text-decoration: underline;
      }
    }
  }
  #logo-lb {
    width: 164px;
    cursor: pointer;
    margin-left: 20px;
  }
  #cat-btn {
    position: relative;
  }
  #cat-dropdown {
    position: absolute;
    top: 50px;
    width: 250px;
    background: #FFF;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.15);
    padding: 10px 18px 10px;
    p {
      color: var(--Negro, #474A4B);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-bottom: 1px solid #CCC;
      margin-bottom: 12px;
      padding-bottom: 3px;
    }
    #close-btn {
      margin-bottom: 3px;
      margin-left: 92%;
      cursor: pointer;
    }
  }
  h6 {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    background: var(--OUTLINE-CARDS-LABOPAT, linear-gradient(90deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1px solid #CCC;
    margin-bottom: 13px;
    padding-bottom: 5px;
  }
  @media screen and ( width > 768px) {
    #cat-dropdown {
      left: 50%;
      transform: translate(-50%, 0);
    }
    h6 {
      display: none;
    }
  }
  @media screen and ( width >= 768px) and ( width <= 992px) {
    #options-div {
      width: 460px;
    }
  }
  @media screen and ( width <= 768px) {
    #options-div {
      justify-content: end;
    }
    .hide-mobile {
      display: none;
    }
    #cat-dropdown {
      right: 0;
      p {
        margin-left: 25px;
      }
    }
  }
`;

const StyledBurguer = styled.div`
  border-radius: 5px;
  background: var(--Blanco, #F3F3F3);
  display: inline-flex;
  padding: 7px 5px;
  justify-content: center;
  align-items: center;
  @media screen and ( width > 768px) {
    display: none;
  }
`;

export const NavbarBlog = () => {
  const [opened, setOpened] = useState(false);
  const history = useHistory();

  const handleClick = (e) => {
    if (e.target.id !== 'cat-dropdown' && e.target.id !== 'cat-btn') {
      // Set Opened to false here
      setOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <>
      {/* Main nacbar div */}
      <StyledNavbar>
        {/* Container */}
        <Container>

          {/* Logo */}
          <div id='logo-div'>
            <img id='logo-lb' src={logoB} alt="Labopat" onClick={()=>history.push('/')}/>
          </div>

          {/* Buttons and search */}
          <div id='options-div'>
            <p className='hide-mobile' onClick={()=>history.push(`/blog`)}>Feed</p>
            <div style={{position: 'relative'}} >
              <p id='cat-btn' className='hide-mobile' onClick={()=>setOpened(!opened)}>
                Categorias {opened ? <UilAngleUp color={'#30DF99'} size={35} id='cat-btn'/> : <UilAngleDown color={'#30DF99'} size={35} id='cat-btn'/>}
              </p>
              <StyledBurguer id='cat-btn' onClick={()=>setOpened(!opened)}>
                <img src={burguer} alt="Menu" id='cat-btn'/>
              </StyledBurguer>
              { opened &&
                <div id='cat-dropdown'>
                  <UilMultiply id='close-btn' color='#30DF99' onClick={()=>setOpened(false)}/>
                  <h6 onClick={()=>history.push(`/blog`)}>Feed</h6>
                  <h6 onClick={()=>history.push(`/blog/categorias`)}>Categorías</h6>
                  {CATEOGORIES?.map((cat, index)=>
                    <p key={index} onClick={()=>history.push(`/blog/${cat?.link}`)}>{cat?.name}</p>
                  )}
                </div>
              }
            </div>
            <Searcher/>
          </div>

        </Container>
      </StyledNavbar>
    </>
  );
};
