import React from "react";
import styled from "styled-components";
import { OUTLINE_CARDS, TEXT_BLACK } from "../../helpers/colors";
import { Col, Container, Row } from "react-bootstrap";
import drLuisMunoz from '../../assets/aguascalientes/drLuisMunoz.png';
import { UilBookOpen } from '@iconscout/react-unicons';
import { ContactAgs } from "./contactAgs";

const col1 = [ 'Médico cirujano por la Universidad Autónoma de Aguascalientes.', 'Especialista en Anatomía Patológica por el Instituto Nacional de Ciencias Médicas y Nutrición Salvador Zubirán/UNAM.', 'Máster en Oncología Molecular por el Centro Nacional de Investigaciones Oncológicas de Madrid, España.', 'Máster en Bioética y Derecho por la Universidad de Barcelona.', 'Miembro del Colegio de Bioética, A.C.', 'Miembro del Consejo Consultivo de la Comisión Nacional de Bioética.', 'Miembro de la Academia Mexicana de Médicos Escritores.', 'Columnista semanal de El Heraldo de Aguascalientes desde 2004.', 'Colaborador semanal de Ciencia y conciencia en UAA TV y Radio Universidad desde 2018.', 'Director de laboratorio de Labopat® Aguascalientes.', 'Presidente de la Corresponsalía Aguascalientes del Seminario de Cultura Mexicana.' ];

const StyledDiv = styled.div`
  background: #FFF;
  padding: 50px 0;
  h1 {
    color: ${TEXT_BLACK};
    text-align: center;
    font-weight: 300;
    line-height: 105.5%;
    margin-bottom: 60px;
    font-size: 35px;
    span {
      background: ${OUTLINE_CARDS};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 500;
    }
  }
  #doctor-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0 30px;
    h2 {
      font-size: 25px;
      font-weight: 600;
      line-height: normal;
      transition: all 0.3s ease;
      margin-top: 40px;
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
    }
    :hover {
      h2 {
        background: ${OUTLINE_CARDS};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      #gradient-dr-div {
        padding: 0;
      }
      #gradient-dr-div div{
        background: transparent;
      }
      img {
        width: 168px;
        filter: grayscale(0);
      }
    }
  }
  #gray-div {
    width: 100%;
    background: #F3F3F3;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6A6A6A;
    margin-bottom: 26px;
    svg {
      margin-right: 10px;
      font-size: 40px;
    }
  }
  li {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
  @media only screen and (width <= 768px) {
    h1 {
      font-size: 32px;
      margin-bottom: 70px;
    }
    li {
      font-size: 16px;
      line-height: 25px;
    }
    #doctor-div {
      flex-direction: column;
      h2 {
        font-size: 22px;
      }
      h3 {
        font-size: 16px;
      }
    }
    .container {
      padding: 0;
    }
  }
`;

const GradientBorderDiv = styled.div`
  background: ${OUTLINE_CARDS};
  width: 168px;
  height: 168px;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  transition: all 0.3s ease;
  margin-right: 35px;
  #overflow-div {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: absolute;
    bottom: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    img {
      filter: grayscale(1);
      transition: all 0.3s ease;
      width: 158px;
      margin-bottom: -80px;
    }
  }
  #content-div {
    background: #FFF;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    img {
      filter: grayscale(1);
      transition: all 0.3s ease;
      width: 158px;
      position: absolute;
      bottom: 0;
    }
  }
  @media only screen and (width <= 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const MedicTeam = () => {
  return (
    <StyledDiv>
      <h1>Equipo <span>médico y ubicación</span></h1>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <div id="doctor-div">
              <GradientBorderDiv id="gradient-dr-div">
                <div id="overflow-div">
                  <img src={drLuisMunoz} alt="Dr. Luis M." />
                </div>
                <div id="content-div">
                  <img src={drLuisMunoz} alt="Dr. Luis M." />
                </div>
              </GradientBorderDiv>
              <div>
                <h2>Dr. Luis Muñoz Fernández.</h2>
                <h3>Sabadell, Barcelona, España, 1961.</h3>
              </div>
            </div>
            <div id="gray-div">
              <UilBookOpen/> Semblanza curricular
            </div>
            <ul>
              {col1.map((line, index)=>
                <li key={index}>{line}</li>
              )}
            </ul>
          </Col>
          <Col xs={12} lg={6}>
            <ContactAgs/>
          </Col>
        </Row>
      </Container>
    </StyledDiv>
  )
};