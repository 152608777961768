import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { CA_GRADIENT_BUTTON } from '../../../helpers/colors';
import ImageBanner from '../../../assets/banner-home/mockUpBannerCreateAccount.png';

const Slide = styled(Container)`
  height: 460px;
  position: relative;
  background: none;
  overflow: hidden;
  h1 {
    color: var(--Negro, #474A4B);
    text-align: center;
    font-family: "IBM Plex Sans";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 130.9%; /* 65.45px */
    span {
      font-weight: 600;
    }
  }
  p {
    color: var(--Negro, #474A4B);
    font-family: "IBM Plex Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 26px */
    margin-top: 45px;
  }
  #text-col {
    z-index: 2;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    .row {
      width: 100%;
    }
  }
  #img-col {
    z-index: 1;
    height: 460px;
    display: flex;
    align-items: end;
    justify-content: center;
    @media only screen and (min-width: 768px) {
      position: relative;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0;
    @media only screen and (min-width: 768px) {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  @media only screen and (min-width: 1920px) {
    #img-col {
      padding: 0;
    }
    img {
      max-width: 120%;
      max-height: 105%;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {
    padding: 0 0 0 2%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1300px) {
    padding: 0 0 0 2%;
    h1 {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    height: 520px;
    #img-col {
      height: 520px;
    }
    h1 {
      font-size: 33px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 520px;
    padding: 30px 0 0;
    h1 {
      font-size: 30px;
    }
    img {
      max-width: 125%;
      max-height: 300px;
      right: 0;
    }
  }
`;

const Button = styled.button`
  color: #F1F1F1;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  border: none;
  background: ${CA_GRADIENT_BUTTON};
  margin: 0 auto;
  transition: all 0.3s ease;
  padding: 13px 40px;
  :hover {
    filter: brightness(1.05);
  }
  @media only screen and (min-width: 768px) and (max-width: 1400px) {
    font-size: 24px;
    padding: 10px 38px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    padding: 10px 38px;
  }
`;

export const CreateAccountBanner = () => {
  return (
    <Slide>
      <Row>
        <Col xs={12} md={6} xxl={7} id='text-col'>
          <h1><span>¡Agenda en línea</span> tus estudios de laboratorio y <span>olvídate de las filas!</span></h1>
          <Button onClick={() => window.location.href = '/registro'}>Crea tu cuenta</Button>
        </Col>
        <Col xs={12} md={6} xxl={5} id='img-col'>
          <img src={ImageBanner} alt="phone" />
        </Col>
      </Row>
    </Slide>
  );
};
