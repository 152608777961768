import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Slide = styled(Container)`
  height: 460px;
  position: relative;
  overflow: hidden;
  h1 {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    font-size: 46px;
    line-height: 130%;
    color: #4D99E5;
    margin-left: auto;
    margin-bottom: 18px;
  }
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-size: 40px;
    line-height: 130%;
    color: #4D99E5;
    text-align: right;
    margin-bottom: 43px;
    margin-left: auto;
    max-width: 515px;
  }
  #text-col {
    z-index: 2;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    .row {
      width: 100%;
    }
  }
  #img-col {
    z-index: 1;
    height: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    max-width: 1000px;
    max-height: 100%;
    margin-bottom: -20px;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1920px) {
    padding: 0 8% 0 8%;
  }
  @media only screen and (max-width: 992px) {
    h1 {
      font-size: 42px;
      margin-bottom: 5px;
    }
    p {
      font-size: 30px;
    }
    img {
      max-width: 150%;
      margin-bottom: 0;
    }
    #img-col {
      height: 520px;
    }
  }
  @media only screen and (max-width: 992px) {
    height: 520px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px 0 0;
    #img-col {
      height: auto;
    }
    h1 {
      width: 100%;
      text-align: center;
    }
    p {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 20px;
      max-width: 340px;
      padding: 0 10px;
    }
    img {
      width: 500px;
      margin-bottom: -10px;
    }
  }
`;

const TransparentButton = styled.button`
  padding: 13px 48px;
  border: none;
  background: linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%);
  border-radius: 12px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  font-size: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFF;
  margin-bottom: 25px;
  transition: all 0.4s ease;
  margin-left: auto;
  :hover {
    background: #CEF719;
    color: #00AC90;
  }
  @media only screen and (max-width: 1150px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ELabAlliesBanner = () => {
  return (
    <Slide>
      <Row>
        <Col xs={12} md={6} id='img-col'>
          <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdoctorsSlider%2FLaptop%20Mockup.png?alt=media&token=743f8b49-c982-4056-9056-21dfab6eea04" alt="doctora" />
        </Col>
        <Col xs={12} md={6} id='text-col'>
          <h1>labopat.mx</h1>
          <p>Somos el puente entre tú y tus pacientes.</p>
          <TransparentButton onClick={()=>window.location.href = '/registro'}>Crear cuenta</TransparentButton>
        </Col>
      </Row>
    </Slide>
  );
};
