import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import BG_DK from '../../../assets/banner-home/holidayDkBanner.png';
import BG_MB from '../../../assets/banner-home/holidayMbBanner.png';

const Background = styled.div`
  background: url('${BG_DK}') right center no-repeat #15111A;
  overflow: hidden;
  position: relative;
  @media only screen and (width <= 992px) {
    background: url('${BG_MB}') center bottom / cover no-repeat #15111A;
  }
`;

const Slide = styled(Container)`
  height: 460px;
  display: flex;
  justify-content: start;
  align-items: center;
  div {
    width: 80%;
  }
  p {
    color: var(--Aqua, #30DF99);
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  h1 {
    color: var(--axionacolors-axiona-offwhite, #F2F7F7);
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 92px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
  }
  @media only screen and (min-width: 992px) {
    div {
      max-width: 730px;
    }
  }
  @media only screen and (max-width: 992px) {
    height: 520px;
    div {
      width: 100%;
    }
    h1 {
      font-size: 72px;
    }
  }
`;

export const HolidaysBanner = () => {

  return (
    <Background>
      <Slide>
        <div>
          <p>LABOPAT TE DESEA</p>
          <h1>¡Felices fiestas!</h1>
        </div>
      </Slide>
    </Background>
  );
};
