import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Button } from "react-bootstrap";
import { Desktop } from "./desktop";
import { MobileMenu } from "./mobile";
import axios from "axios";
import { DesktopMenu } from "../../components/menu/desktop";
import {
  UilUserCircle,
  UilLocationPinAlt,
  UilClipboardAlt,
  UilExclamationTriangle,
  UilPhone,
  UilNewspaper,
} from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { ModalCart } from "../shopping-cart/ModalCart";
import { setOfferBanner } from "../../actions/auxiliar";
import {
  UilMultiply,
  UilAngleDown,
  UilAngleUp,
} from "@iconscout/react-unicons";
import { Cart } from "../shopping-cart/cart";
import { Searcher } from "./searcher";
import { getUserInfo } from "../../../src/actions/user";
import { branchesOptions, navbarMenu } from "../../helpers/navbar";
import { CA_GRADIENT_BUTTON, TEXT_BLACK } from "../../helpers/colors";
import { startLogout } from "../../actions/auth";
import { removeItemFromCart, setTotalPrice } from "../../actions/shoppingCart";
import { updateCartDB } from "../../helpers/api";
import { Link } from 'react-router-dom'

const Menu = styled(Row)`
  @media only screen and (width >= 768px) {
    #green-top-bar {
      height: 45px;
      display: flex;
      align-items: center;
    }
  }
`;

const DetailMenu = styled(Row)`
  padding: 1px;
  padding-right: 0px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  @media only screen and (max-width: 378px) {
    padding-left: 0px;
  }
  > div {
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 0;
    padding-right: 0px;
    @media only screen and (max-width: 378px) {
      padding-left: 0px;
      padding-right: 0px;
      justify-content: space-between;
    }
  }
`;

const ShowListStudies = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    padding: 0;
  }
  > div {
    margin-left: 5px;
  }
`;

const OfferMenu = styled(Row)`
  background: #0a7a71;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  margin-left: 0px;
  margin-right: 0px;
`;
const TextOffer = styled.p`
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-top: 3px;
`;

const RightItemsDiv = styled.div`
  display: flex;
`;

const DetailItemB = styled.div`
  width: auto !important;
  padding: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 0 10px;
  cursor: pointer;
  border-left: 1px solid #ddd;
  @media only screen and (max-width: 768px) {
    padding: 0px 3px;
    margin: 5px 0;
    border-left: none;
  }
`;

const CloseButton = {
  color: "red",
  width: "100%",
  fontWeight: "bold",
  fontSize: "16px",
  padding: "0",
};

const MoreInfo = styled.a`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #ffffff;
  ::-webkit-input-placeholder {
    font-size: 14px;
    color: #a6a6a6;
  }
`;

const CartCount = styled.span`
  background-color: #6c757d;
  position: absolute;
  top: -2px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 500px) {
    top: -2px;
    right: -6px;
    width: 19px;
    height: 19px;
    font-size: 13px;
  }
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 13px;
`;

const PhoneDiv = styled.div`
  margin-left: 0px;
  margin-right: auto;
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
  }
  a {
    font-weight: 800;
    font-size: 15px;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
    text-decoration-line: underline;
    color: #333333;
  }
  #phone-icon {
    margin-right: 9px;
    width: 26px !important;
    height: 26px;
    @media only screen and (max-width: 768px) {
      width: 24px !important;
      height: 24px;
      margin-left: 3px;
    }
    border-radius: 50px;
    border: 2px solid #00ac90;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #phone-number {
    width: 142px;
  }
`;

const StyledDropdown = styled.div`
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 200px;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
  h4 {
    color: ${TEXT_BLACK};
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
  }
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }
  div {
    cursor: pointer;
  }
  div:hover {
    h4,
    p {
      background: ${CA_GRADIENT_BUTTON};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const Detail = styled.div`
  position: absolute;
  height: auto;
  /* overflow-y: auto; 
  margin-left: -20px;*/
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  > h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-bottom: 6px;
  }
  > div p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #6a6a6a !important;
  }
`;

export const NavBar = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth?.uid || false);
  const theUID = useSelector((state) => state.auth?.uid || "");
  const [showOffer, setShowOffer] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showSearcher, setShowSearcher] = useState(props.showSearcher);
  const [search, setSearching] = useState("");
  const { items } = useSelector((state) => state.shoppingCart);
  const [userName, setUserName] = useState(""); //useSelector((state) => `${state.auth?.name}` || undefined);
  const [packages, setPackages] = useState([]);
  const [showBranchesDropdown, setShowBranchesDropdown] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;

  // const [visible, setVisible] = useState(false);
  const [showNav, setShowNav] = useState(true);
  let thePhone = "+522212311313";

  const showSearch = () => {
    props.setShowSearcher(true);
    setShowNav(false);
  };
  const hideSearch = () => {
    props.setShowSearcher(false);
    setShowNav(true);
  };

  const searching = (search) => {
    console.log("busqueda: ", search);
    setSearching(search);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const setUser = async () => {
    //console.log("the uid", theUID)
    let user = await getUserInfo(theUID);
    var drGender = "Dr. ";
    if (user.data.gender === "Femenino") {
      drGender = "Dra. ";
    }
    var name = "";
    if (user.data?.profile?.type === 1) {
      name = `${drGender}${user.data.name} ${user.data.fathersLastname} ${user.data.mothersLastname}`;
    } else {
      name = `${user.data.name} ${user.data.fathersLastname} ${user.data.mothersLastname}`;
    }
    setUserName(name);
  };

  useEffect(() => {
    getPackages();
    setUser();
  }, []);

  let isMobile = width <= 900;

  const getPackages = async () => {
    try {
      if (theUID && theUID !== "") {
        let requestPackages = await axios.get(
          `https://lpqsystem.com/getPaquetesMedico?uid=${theUID}`
        );
        // console.log(requestPackages);
        if (requestPackages.data.ok) {
          setPackages(requestPackages.data.paquetes_medico);
        } else {
          console.log("Error al obtener paquetes");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const vaciarCarrito = () => {
    // Eliminar todos los elementos del carrito
    dispatch(removeItemFromCart([])); // Pasar un array vacío elimina todos los elementos
    // Actualizar el precio total a cero
    dispatch(setTotalPrice(0));
    // Actualizar el carrito en la base de datos
    dispatch(updateCartDB([], 0)); // Pasar un array vacío y cero como precio
  };

  const handleMenu = () => {
    const searcher = document.getElementById("searcher-div");
    if (
      searcher !== null &&
      searcher?.style.display !== "none" &&
      !props.showMenu
    ) {
      searcher.style.display = "none";
    }
    props.setShowMenu(!props.showMenu);
    props.setShowSearcher(false);
    setShowNav(true);
  };

  useEffect(() => {
    setShowSearcher(props.showSearcher);
  }, [props.showSearcher]);

  const handleOpenModalCart = () => {
    props.setShowCart(true);
  };

  useEffect(() => {
    document.addEventListener("click", function (event) {
      if (
        event.target.id !== "branches-dropdown" &&
        event.target.id !== "branches-button"
      ) {
        setShowBranchesDropdown(false);
      }
    });

    return () => {
      // Clean up event listener when component unmounts
      document.removeEventListener("click", function (event) {
        if (event.target.id !== "branches-dropdown") {
          setShowBranchesDropdown(false);
        }
      });
    };
  }, []);

  return (
    <div style={{ zIndex: 7, position: "sticky", top: 0 }} id="topNavBar">
      <ModalCart />
      <Menu className="gx-0">
        {showOffer ? (
          <OfferMenu id="offerBanner">
            <Col sm={1} xs={1} />
            <Col
              sm={10}
              xs={10}
              className="text-left my-auto mt-1 mb-1"
              style={{ display: "inline-block" }}
            >
              <UilExclamationTriangle size="28" color="#F9AD62" />{" "}
              <TextOffer
                className="mt-2"
                style={{ display: "inline", paddingLeft: 8 }}
              >
                En LABOPAT tenemos múltiples opciones para detectar COVID{" "}
                <MoreInfo href="covid-19">Más información</MoreInfo>
              </TextOffer>
            </Col>
            <Col
              sm={1}
              xs={1}
              className="text-center"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="link"
                style={CloseButton}
                className="btn btn-sm"
                onClick={() => {
                  setShowOffer(false);
                  dispatch(setOfferBanner(false));
                }}
              >
                <UilMultiply color="#fff" />
              </Button>
            </Col>
          </OfferMenu>
        ) : null}

        {/* Green bar */}
        <div
          style={{ background: "rgb(216 248 235)", width: "100%" }}
          id="green-top-bar"
        >
          {showNav && (
            <div
              style={{ padding: "0px" }}
              className={width > 992 ? "container" : ""}
            >
              <DetailMenu>
                <Col xs={12} md={12}>
                  <PhoneDiv>
                    <div id="phone-icon">
                      <p>
                        <UilPhone size={16} color="#00AC90" />
                      </p>
                    </div>
                    <p>
                      {width > 768 && "Atención a clientes "}
                      <a href={"tel:" + thePhone}>
                        <img
                          alt="phone"
                          id="phone-number"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FphoneHeader.png?alt=media&token=01697c02-8758-4a89-bbbd-0f3a20124ea3"
                        />
                      </a>
                    </p>
                  </PhoneDiv>

                  <RightItemsDiv>
                     <DetailItemB  style={{borderLeft: '0px'}}>
                       <Link to="/blog" style={{ textDecoration: 'none', color: 'inherit' }}>
                         {width > 768 && 'Blog'}{" "}
                         <UilNewspaper size="32" color="#00AD90" />
                       </Link>
                    </DetailItemB>
                    <DetailItemB
                      onMouseEnter={() => setShowBranchesDropdown(true)}
                      onMouseLeave={() => setShowBranchesDropdown(false)}
                      id="branches-button"
                    >
                      {width > 768 && "Sucursales"}{" "}
                      <UilLocationPinAlt size="32" color="#00AD90" />
                      <div
                        style={{
                          position: "relative",
                          height: "0px",
                          width: "100%",
                        }}
                      >
                        {showBranchesDropdown && (
                          <StyledDropdown id="branches-dropdown">
                            {branchesOptions?.map((opt) => (
                              <div
                                onClick={() =>
                                  (window.location.href = opt?.link)
                                }
                                key={opt?.id}
                              >
                                <h4>{opt?.name}</h4>
                              </div>
                            ))}
                          </StyledDropdown>
                        )}
                      </div>
                    </DetailItemB>
                    <DetailItemB onClick={() => setViewDetail(!viewDetail)}>
                      {width > 768 &&
                        (isLoggedIn
                          ? userName?.length >= 17
                            ? `${userName?.substring(0, 15)}...`
                            : userName !== ""
                          : "Entrar")}{" "}
                      <UilUserCircle size="32" color="#00AD90" />
                      {viewDetail ? (
                        <UilAngleUp size="28" />
                      ) : (
                        <UilAngleDown size="28" />
                      )}
                      {viewDetail && (
                        <Detail>
                          <>
                            {isLoggedIn ? (
                              <>
                                
                                <h4
                                  onClick={() => {
                                    window.location.href = "/datos-personales";
                                  }}
                                >
                                 Mi perfil
                                </h4>
                                
                                <h4
                                  onClick={() =>
                                    dispatch(
                                      startLogout(
                                        window.location.reload(vaciarCarrito)
                                      )
                                    )
                                  }
                                >
                                  Cerrar sesión
                                </h4>
                              </>
                            ) : (
                              <>
                              <h4
                                onClick={() => {
                                  window.location.href = "/registro";
                                }}
                              >
                                Crear cuenta
                              </h4>
                              <h4
                                onClick={() => {
                                  window.location.href = "/iniciar-sesion";
                                }}
                              >
                                Iniciar sesión
                              </h4>
                              </>
                            )}
                          </>
                        </Detail>
                      )}
                    </DetailItemB>
                    <DetailItemB onClick={handleOpenModalCart}>
                      <ShowListStudies id="openCartButton">
                        {width > 768 && "Estudios"}
                        <div style={{ position: "relative" }}>
                          <UilClipboardAlt
                            size={isMobile ? "28" : "32"}
                            color="#00AD90"
                          />
                          {items.length > 0 && (
                            <CartCount>{items.length}</CartCount>
                          )}
                        </div>
                      </ShowListStudies>
                    </DetailItemB>
                  </RightItemsDiv>
                </Col>
                <Col xs={1} />
              </DetailMenu>
            </div>
          )}
        </div>

        {/* Desktop / mobile MENU */}
        {isMobile ? (
          <MobileMenu
            menu={navbarMenu}
            showSearch={showSearch}
            hideSearch={hideSearch}
            setSearching={searching}
            showSearcher={showSearcher}
            search={search}
            showMenu={props.showMenuMb}
            setShowMenu={(e) => props.setShowMenuMb(e)}
            opened={props.opened}
            setOpened={(e) => props.setOpened(e)}
          />
        ) : (
          <Desktop
            menu={navbarMenu}
            showTheMenu={handleMenu}
            showMenu={props.showMenu}
            showSearch={showSearch}
            hideSearch={hideSearch}
            setSearching={setSearching}
            showSearcher={showSearcher}
            search={search}
          />
        )}
      </Menu>

      {/* Searcher */}
      {showSearcher && (
        <div style={{ position: "relative" }}>
          <Searcher hideSearch={hideSearch} packages={packages} />
        </div>
      )}

      {/* Desktop Menu */}
      <div style={{ position: "relative" }}>
        {props.showMenu ? (
          <DesktopMenu
            menu={navbarMenu[0]}
            onHide={() => props.setShowMenu(false)}
          />
        ) : null}
      </div>

      {/* Cart */}
      <Cart
        openSearcher={() => props.setShowSearcher(true)}
        onClose={() => {
          props.setShowCart(false);
        }}
        visible={props.showCart}
      />
    </div>
  );
};