import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Button, Container, Modal } from 'react-bootstrap';
import { Sections } from './sections';
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Link, NavLink } from 'react-router-dom';
import logoB from '../../assets/logos/logo1.png';
import HelpIcon from '../../assets/sidebar/HelpIcon.png';
import HelpIconUser from '../../assets/sidebar/HelpIconUser.png';
import PhoneIconUser from '../../assets/sidebar/PhoneIconUser.png';
import LogOutIcon from '../../assets/sidebar/LogoutIcon.png';
import { startLogout } from '../../actions/auth';
import { UilBars, UilMultiply, UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { Cart } from '../shopping-cart/cart';
import { useLocation } from 'react-router-dom';
import { PrivRoutes } from '../../routes/routes';
import { Searcher } from '../navbar/searcher';

const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #FFFFFF;
  z-index: 62;
  box-shadow: ${props => props.scrolled ? 'rgb(10 10 10 / 5%) 0px 4px 8px' : 'none'};
  transition: 300ms;
`;

const LogoDocContainer = styled.div`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  max-width: 60px;
  max-height: 60px;
  position: relative;
  overflow: hidden;
  background-image: ${props => `url('${props.bg}')`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  @media only screen and (max-width: 1200px) {
    width: 48px;
    height: 48px;
  }
  @media only screen and (max-width: 768px) {
    width: 52px;
    height: 52px;
  }
`;

const NormalText = styled.p`
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #697077;
  margin: 0;
`;

const LinkText = styled.p`
  font-family: 'IBM Plex Sans';
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: ${props => props.isDoctor ? '#4F90BA' : '#00AC90'};
`;

const StyledNav = styled.nav`
margin-bottom: ${props => props.isDoctor ? '20%' : '40%'};
  a {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    color: #878D96;
    :hover {
      color: ${props => props.mainColor};
      .icon {
        filter: none;
      }
    }
  }
  .cart {
    color: ${props => props.showCart ? props.mainColor : '#878D96'};
    .icon {
      filter: ${props => props.showCart && 'none'};
    }
    :hover {
      color: ${props => props.mainColor};
      .icon {
        filter: none;
      }
    }
  }
  .icon {
    max-width: 25px;
    max-height: 25px;
    height: auto;
    filter: grayscale();
  }
  .bigIcon {
    max-width: 22px;
    max-height: 22px;
    height: auto;
    filter: grayscale();
  }
  .active {
    color: ${props => props.mainColor} !important;
    .icon, .bigIcon {
      filter: none;
    }
  }
`;

const StyledHr = styled.hr`
  background-color: #4f90ba4d;
  padding: .5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: ${props => props.ondrawer ? '0' : '-9%'};
  left: 0;
  right: 0;
  padding: .6rem 1rem .6rem 1rem;
  background-color: ${props => props.isDoctor ? '#85b4d233' : '#00ad901a'};
  border-radius: 12px;
`;

const HelpButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  .helpIcon {
    max-width: 38px;
    margin-top: 3%;
    margin-right: 5%;
  }
  a {
    color: ${props => props.isDoctor ? '#4F90BA' : '#00AC90'};
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    margin: 0;
  }
`;

const PhoneButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  .helpIcon {
    max-width: 38px;
    margin-top: 3%;
    margin-right: 5%;
  }
  a {
    color: ${props => props.isDoctor ? '#4F90BA' : '#00AC90'};
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    margin: 0;
  }
`;

const LogOutBtn = styled.a`
  font-family: 'IBM Plex Sans';
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: #697077;
  :hover {
    color: #000000;
    img {
      filter: none;
    }
  }
`;

const MenuBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  padding: 0;
  background: ${props => props.isdoctor === 1 ? '#E7F0F6' : '#F2F7F9'};
`;

const CloseDrawerBtn = styled.div`
  display: flex;
  width: auto;
  background-color: #E0ECEC;
  border-radius: 50rem;
  padding: 10px;
`;

const HomeBtn = styled.div`
  width: 100%;
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    color: ${props => props.selected ? props.mainColor : '#878D96'};
  }
`;

const StudiesBtn = styled.div`
  width: 100%;
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    color: ${props => props.selected ? props.mainColor : '#878D96'};
  }
`;

const StyledHomeIcon = styled.img`
  max-width: 25px;
  height: auto;
  filter: ${props => props.selected ? 'none' : 'grayscale()'};
  @media only screen and (max-width: 992px) {
    max-width: 18px;
  }
`;

const CartCount = styled.div`
  background-color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'};
  padding: 9px;
  position: absolute;
  top: -5px;
  right: -4px;
  color: white;
  width: 16px;
  height: 16px;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    font-size: 12px;
  }
  @media only screen and (max-width: 768px) {
    right: 10%;
    padding: 6px;
  }
  @media only screen and (max-width: 576px) {
    right: 45%;
  }
  @media only screen and (max-width: 425px) {
    right: 15px;
  }
`;

const DesktopLogo = styled.img`
  margin: auto;
  display: block;
  height: 40px;
  position: absolute;
  left: 20px;
  @media only screen and (max-width: 768px) {
    width: 50px;
  }
`;

export const SideDrawer = (props) => {
  const user = useSelector((state) => state?.user?.data.data);
  const { items } = useSelector((state) => state.shoppingCart);
  const { width } = useWindowDimensions();
  const [showMenu, setShowMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const isDoctor = user?.profile?.type === 1;
  const [selectedHome, setSelectedHome] = useState(false);
  const [selectedStudies, setSelectedStudies] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [ showSearcher, setShowSearcher ] = useState(false);
  const location = useLocation();
  const mainColor = (PrivRoutes.includes(location.pathname) || location?.pathname?.includes('paciente/') || location?.pathname?.includes('editar-paquete/')) && isDoctor ? "#4D99E5" : "#00AC90";

  const handleScroll = () => {
    setScrollY(window.scrollY);
  }

  const handleCart = () => {
    setVisible(!visible);
  }

  useEffect(() => {
    const onScroll = () => {
      window.addEventListener('scroll', handleScroll);
    }
    onScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return(
    <>
      <NavContainer scrolled={scrollY > 15 ? true : false}>
        <div className='d-flex flex-row justify-content-between align-items-center w-100' style={{ padding: '15px 20px'}}>
          <Link to='/' 
            onClick={() => setShowMenu(false)}
            className='d-flex flex-row justify-content-center'
            style={{maxWidth: '156px', maxHeight: '29px'}} 
            >
            <img src={logoB} alt='Logo Labopat' 
              className='img-fluid' />
          </Link>
          <MenuBtn
            isdoctor={isDoctor ? 1 : 0}
            variant='link'
            size='sm'
            onClick={()=>{setShowMenu(true); setSelectedHome(false); setSelectedStudies(false)}}
            style={{
              borderRadius: '50%',
              width: '40px',
              height: '40px'
            }}
          >
            <UilBars
              size='25'
              color={isDoctor ? '#4F90BA' : '#0A7A71'}
            />
          </MenuBtn>
          <Drawer
            placement='right'
            closable={false}
            onClose={()=>setShowMenu(false)}
            open={showMenu}
            key='right'
            contentWrapperStyle={{width: '100%', transition: 'transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1)'}}
            drawerStyle={isDoctor ? {background: '#E7F0F6'} : {background: '#F2F7F9'}}
          >
            <div className='d-flex flex-row justify-content-between align-items-center position-fixed w-100' 
              style={{backgroundColor: '#F2F7F9', top: '0', left: '0', padding: '15px 20px', zIndex: 63}}>
              <Link onClick={() => setShowMenu(false)}
                to='/' className='d-flex flex-row justify-content-center' 
                style={{maxWidth: '156px', maxHeight: '29px'}}>
                <img src={logoB} alt='Logo Labopat' className='img-fluid' />
              </Link>
              <CloseDrawerBtn onClick={() => setShowMenu(false)}>
                <UilMultiply size='24' color={'#00AC90'} />
              </CloseDrawerBtn>
            </div>
            <Row className='align-items-center mt-5 pt-4'>
              {isDoctor && (
                <>
                  {user.profile?.logo && (
                    <Col xs={3} sm={2} style={{paddingRight: '0'}}>
                      <LogoDocContainer bg={user.profile?.logo} />
                    </Col>
                  )}
                </>
              )}
              <Col xs={9} sm={10} style={{paddingLeft: '0'}}>
                <NormalText className='mb-2'>{user?.name} {user?.fathersLastname}</NormalText>
              </Col>
            </Row>
            <StyledHr className='mt-4 mb-4' />
            <div className='d-flex flex-column justify-content-between position-relative'>
              <StyledNav 
                className='pt-1' 
                isMobile={width <= 768 ? true : false} 
                isDoctor={isDoctor} 
                showCart={visible}
                mainColor={mainColor}
                >
                <HomeBtn className='mt-4 mb-4' 
                  onClick={() => {setSelectedHome(!selectedHome); setSelectedStudies(false)}} selected={selectedHome} mainColor={mainColor}>
                  <Row className='align-items-center'>
                    <Col xs={2} sm={1}>
                      <UilBars selected={selectedHome} color={selectedHome ? mainColor : "#878D86"} />
                    </Col>
                    <Col xs={10} sm={11} style={{paddingRight: '0'}}>
                      <Row className='justify-content-between'>
                        <Col xs={11} className='p-0'>
                          <p className='m-0 p-0 w-auto'>Secciones</p>
                        </Col>
                        <Col xs={1} className='p-0'>
                          {selectedHome ? (
                            <UilAngleUp size={24} color={mainColor} className='w-auto' />
                            ) : (
                            <UilAngleDown size={24} color={'#878D96'} className='w-auto' />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </HomeBtn>
                {selectedHome && (
                  <>
                    <StudiesBtn onClick={() => setSelectedStudies(!selectedStudies)} selected={selectedStudies} mainColor={mainColor}>
                      <Row className='mb-4 mt-4 align-items-center'>
                        <Col xs={12} style={{paddingRight: '0'}}>
                          <Row>
                            <Col xs={11} className='p-0'>
                              <NormalText style={{color: selectedStudies ? mainColor : "#878D96"}}>Estudios y servicios</NormalText>
                            </Col>
                            <Col xs={1} className='p-0 d-flex flex-column align-items-end'>
                              {selectedStudies ? (
                                <UilAngleUp size={24} color={mainColor} className='w-auto' />
                                ) : (
                                <UilAngleDown size={24} color={'#878D96'} className='w-auto' />
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </StudiesBtn>
                    {selectedStudies && (
                      <>
                      {Sections.studiesAndServices.map((section, index) => (
                        <Row className='mb-4 justify-content-end align-items-center' key={index}>
                          <Col xs={12}>
                            <Link to={section.route}
                              style={{fontSize: '15px', color: '#878D96'}} 
                              className='d-block'
                              onClick={() => setShowMenu(false)}>
                              {section.name}
                            </Link>
                          </Col>
                        </Row>
                      ))}
                      <StyledHr className='mt-4 mb-4' />
                      </>
                    )}
                    {Sections.homeSections.map((section, index) => (
                      <Row className='mb-4 align-items-center' key={index}>
                        <Col xs={12}>
                          <Link to={section.route}
                            style={{fontSize: '15px', color: '#878D96'}} 
                            className='d-block'
                            onClick={() => setShowMenu(false)}>
                              {section.name}
                          </Link>
                        </Col>
                      </Row>
                    ))}
                    <StyledHr className='mt-4 mb-4' />
                  </>
                )}
                {isDoctor ? (
                  <>
                    {Sections.docSections?.map((section, index) => (
                      <>
                        {section.name === 'Estudios' ? (
                          <div className='mt-4 mb-4' key={index}>
                            <a className='cart' onClick={handleCart}>
                              <Row className='align-items-center'>
                                <Col xs={2} sm={1} style={{paddingRight: '0'}} className='position-relative'>
                                  {section.icon}
                                  {items.length > 0 ? (
                                    <CartCount isdoctor={isDoctor ? 1 : 0}>
                                        <p>
                                          {items.length}
                                        </p>
                                      </CartCount>
                                  ) : null}
                                </Col>
                                <Col xs={10} sm={11}>
                                  {section.name}
                                </Col>
                              </Row>
                            </a>
                          </div>
                        ) : (
                          <div className='mt-4 mb-4' key={index}>
                            {section.route === "search" ? 
                              <Row 
                                onClick={()=>{
                                  props.setShowSearcher(true);
                                  setShowMenu(false);
                                }} 
                                style={{color: "#878D96", cursor: "pointer"}}
                              >
                                <Col xs={2} sm={1} style={{paddingRight: '0'}}>
                                  {section.icon}
                                </Col>
                                <Col xs={10} sm={11}>
                                  {section.name}
                                </Col>
                              </Row>
                            : 
                              <NavLink to={section.route} onClick={() => setShowMenu(false)}>
                                <Row>
                                  <Col xs={2} sm={1} style={{paddingRight: '0'}}>
                                    {section.icon}
                                  </Col>
                                  <Col xs={10} sm={11}>
                                    {section.name}
                                  </Col>
                                </Row>
                              </NavLink>
                            }
                          </div>
                        )}
                      </>
                    ))}
                    
                    {Sections.docPacientes?.map((section, index) => (
                      <div className='mt-4 mb-4' key={index}>
                        <NavLink to={section.route} onClick={() => setShowMenu(false)}>
                          <Row>
                            <Col xs={2} sm={1} style={{paddingRight: '0'}}>
                              {section.icon}
                            </Col>
                            <Col xs={10} sm={11}>
                              {section.name}
                            </Col>
                          </Row>
                        </NavLink>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {Sections.userSections.map((section, index) => (
                      <>
                        {section.name === 'Estudios' ? (
                          <div className='mt-4 mb-4'>
                            <a className='cart' onClick={handleCart}>
                              <Row className='align-items-center'>
                                <Col xs={2} style={{paddingRight: '0'}} className='position-relative'>
                                  {section.icon}
                                  {items.length > 0 ? (
                                    <CartCount isdoctor={isDoctor ? 1 : 0}>
                                        <p>
                                          {items.length}
                                        </p>
                                      </CartCount>
                                  ) : null}
                                </Col>
                                <Col xs={10}>
                                  {section.name}
                                </Col>
                              </Row>
                            </a>
                          </div>
                        ) : (
                          <div className='mt-4 mb-4' key={index}>
                            <NavLink to={section.route} onClick={() => setShowMenu(false)}>
                              <Row>
                                <Col xs={2} style={{paddingRight: '0'}}>
                                  {section.icon} 
                                </Col>
                                <Col xs={10}>
                                  {section.name}
                                </Col>
                              </Row>
                            </NavLink>
                          </div>
                        )}
                      </>
                    ))}
                  </>
                )}
                <div className='mt-4 mb-4'>
                  <LogOutBtn style={{color: '#878D96'}} onClick={() => {setShowMenu(false); dispatch(startLogout())}}>
                    <Row>
                      <Col xs={2} sm={1} style={{paddingRight: '0'}}>
                        <img src={LogOutIcon} alt='Icono cerrar sesión' className='icon' />
                      </Col>
                      <Col xs={10} sm={11}>
                        Cerrar sesión
                      </Col>
                    </Row>
                  </LogOutBtn>
                </div>
              </StyledNav>
              <ButtonsContainer isDoctor={isDoctor} ondrawer={showMenu}>
                {isDoctor ? (
                  <></>
                ) : (
                  <>
                    <PhoneButton isDoctor={isDoctor} className='mb-3'>
                      <a href="" target='_blank' rel='noreferrer' className='d-flex w-100 align-items-center'
                        onClick={() => setShowMenu(false)}>
                        <img src={PhoneIconUser} alt='Phone Icon' className='helpIcon' />
                        TELÉFONOS
                      </a>
                    </PhoneButton>
                  </>
                )}
                <HelpButton isDoctor={isDoctor}>
                  <a href='https://wa.me/522212311313' 
                    target='_blank' 
                    rel='noreferrer'
                    className='d-flex w-100 align-items-center'
                    onClick={() => setShowMenu(false)}>
                      <img src={isDoctor ? HelpIcon : HelpIconUser} alt='Help Icon' className='helpIcon' />
                      AYUDA
                  </a>
                </HelpButton>
              </ButtonsContainer>
            </div>
          </Drawer>
        </div>
      </NavContainer>
      <Modal show={showSearcher} fullscreen={true} onHide={() => setShowSearcher(false)}>
        <Modal.Body style={{padding: "0px"}}>
          <Container>
            <div style={{width: "100%", paddingTop: "30px", minHeight: "100vh", position: "relative"}}>
              <Link to='/' replace>
                <DesktopLogo
                  alt='Logo Labopat'
                  src={logoB}
                  style={{ width: '100%', maxWidth: '164px', height: 'auto' }}
                  />{' '}
              </Link>
              <Searcher hideSearch={()=>setShowSearcher(false)} spaceTop="100px" mainColor={mainColor}/>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <Cart
        onClose={() => {setVisible(false)}}
        visible={visible}
        openSearcher={() => setShowSearcher(true)}
      />
    </>
  );
};