import React, { useEffect } from "react";
import styled from "styled-components";
import { Row, Button } from "react-bootstrap";
import { Menu } from "../menu/mobile";
import { UilTimes } from "@iconscout/react-unicons";
import logoB from "../../assets/logos/logo1.png";
import searchLogo from "../../assets/ecommerce/UilSearchAlt.png";
import menu from "../../assets/menu/menu.png";
import { SearchModal } from "../login/modals/SearchModal";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";

const Mobile = styled(Row)`
  display: none;
  background: #ffffff;
  padding-top: 1rem !important;
  @media only screen and (max-width: 768px) {
    display: block;
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const TopBar = styled.div`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  background: #00AC90;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

export const MobileMenu = (props) => {
  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker("Navbar");

  useEffect(() => {
    const modalSearch = document.getElementById("modalSearch");
    const handleOpenModalSearch = () => {
      modalSearch.style.display = "block";
    };
    if (props.search?.length > 0) {
      handleOpenModalSearch();
    }
  }, [props.search]);

  const handleOpenMenu = () => {
    props.setShowMenu(true);
    gaEventTracker("abrir-buscador");
  }

  return (
    <Mobile style={{ display: 'block' }}>

      {/* Top bar with burguer, logo and searcher button */}
      <TopBar>
        {/* Open MENU button */}
        <Button variant="link" size="sm" onClick={handleOpenMenu}>
          <img src={menu} width={"40px"} height={"40px"} alt="menu" />
        </Button>

        {/* Labopat logo */}
        <a href="/">
          <img src={logoB} alt="labopat logo" style={{ width: "135px" }} />
        </a>

        {/* Open/close SEARCHER button */}
        {props.showSearcher ? (
          <StyledButton variant="link" size="sm" onClick={props.hideSearch}>
            <UilTimes size="25" color="#fff" style={{ marginLeft: "-2px", marginTop: "-1px" }}/>
          </StyledButton>
        ) : (
          <Button variant="link" size="sm" onClick={props.showSearch}>
            <img src={searchLogo} width={"40px"} height={"40px"} alt="searchLogo" />
          </Button>
        )}

      </TopBar>

      {/* Mobile MENU */}
      <Menu
        show={props.showMenu}
        hide={() => props.setShowMenu(false)}
        menu={props.menu}
        opened={props.opened}
        setOpened={(e) => props.setOpened(e)}
      />

      {/* Search modal */}
      <SearchModal search={props.search} />
    </Mobile>
  );
};
