import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { PrivRoutes } from '../../routes/routes';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

const Title = styled.h1`
    margin-top: 50px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    color: #474A4B;
    margin-bottom: 25px;
    padding: 0 10%;
    text-align: center;
    @media only screen and (min-width: 768px) {
        font-size: 30px;
        padding: 0 5%;
    }
    span {
        font-weight: 800;
    }
`;

const RegularP = styled.p`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #474A4B;
`;

const ChatButton = styled.a`
    width: 260px;
    height: 48px;
    border: 2px solid #4F90BA;
    box-sizing: border-box;
    border-radius: 10px;
    background: none;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: #4F90BA;
    margin-top: 10px;
    margin-bottom: 24px;
    transition: all 0.3s ease;
    padding-top: 11px;
    :hover {
        color: #fff;
        background: #4F90BA;
    }
    @media only screen and (min-width: 768px) {
        width: 300px;
    }
`;

const Chat2Button = styled.a`
    width: 260px;
    height: 48px;
    border: 2px solid #30DF99;
    box-sizing: border-box;
    border-radius: 10px;
    background: none;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: #30DF99;
    margin-top: 10px;
    margin-bottom: 24px;
    transition: all 0.3s ease;
    padding-top: 11px;
    :hover {
        color: #fff;
        background: #30DF99;
    }
    @media only screen and (min-width: 768px) {
        width: 300px;
    }
`;

export const Doubts = () => {
    const location = useLocation();
    const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
    const mainColor = PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";
    const number = '+522212311313';
    return (
        <Container style={{paddingBottom: '42px', color: 'rgb(237 255 248)'}}>
            <Title data-aos="fade-up">¿Tienes dudas?</Title>
            <RegularP data-aos="fade-up">Nuestro equipo está capacitado para responder cualquier duda y darte la asistencia necesaria.</RegularP>
            {isDoctor && PrivRoutes.includes(location.pathname) ? (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <ChatButton  href={`https://wa.me/${number}`} target="_blank" rel="noreferrer noopener">Chatea con nosotros</ChatButton>
            </div>
            ) : (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <Chat2Button  href={`https://wa.me/${number}`} target="_blank" rel="noreferrer noopener">Chatea con nosotros</Chat2Button>
            </div>
            )}
            <RegularP>Al continuar se abrirá una ventana para iniciar una conversación de Whatsapp</RegularP>
        </Container>
    )
};