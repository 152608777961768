import React, { useEffect, useState } from "react";
import { OUTLINE_CARDS, TEXT_BLACK } from "../../helpers/colors";
import styled from "styled-components";
import { Col, Container, Modal, Row } from "react-bootstrap";
import patQuiCardImg from '../../assets/aguascalientes/patQuiCardImg.png';
import citoloCardImg from '../../assets/aguascalientes/citoloCardImg.png';
import inmHisCardImg from '../../assets/aguascalientes/inmHisCardImg.jpg';
import inmFluCardImg from '../../assets/aguascalientes/inmFluCardImg.png';
import bioMolCardImg from '../../assets/aguascalientes/bioMolCardImg.png';
import serGenCardImg from '../../assets/aguascalientes/serGenCardImg.png';
import patoloIconAgs from '../../assets/aguascalientes/servicesIcons/patoloIconAgs.png';
import citoloIconAgs from '../../assets/aguascalientes/servicesIcons/citoloIconAgs.png';
import inmunoIconAgs from '../../assets/aguascalientes/servicesIcons/inmunoIconAgs.png';
import inmfluIconAgs from '../../assets/aguascalientes/servicesIcons/inmfluIconAgs.png';
import molecuIconAgs from '../../assets/aguascalientes/servicesIcons/molecuIconAgs.png';
import sergenIconAgs from '../../assets/aguascalientes/servicesIcons/sergenIconAgs.png';
import { UilMultiply } from '@iconscout/react-unicons';

const Services = [
	{
		name: 'Patología quirúrgica',
		desc: 'Brindamos un servicio integral a médicos y pacientes a través de nuestro equipo de médicos anatomopatólogos con gran experiencia y especialización.',
		link: '/patologia-quirurgica',
		icon: patoloIconAgs,
		image: patQuiCardImg,
	},
	{
		name: 'Citología',
		desc: 'Estudio de las células medidante la obtención de líquidos corporales como método o de prueba de diagnóstico, tal es el caso de la citología cervicovaginal o Papanicolaou.',
		link: '',
		icon: citoloIconAgs,
		image: citoloCardImg,
	},
	{
		name: 'Inmunohistoquímica',
		desc: 'Ofrecemos un catálogo con más de 270 marcadores de inmunnohistoquímica e inmunofluorescencia que cuentan con procesos automatizados y controles de calidad internacional.',
		link: '',
		icon: inmunoIconAgs,
		image: inmHisCardImg,
	},
	{
		name: 'Inmunofluorescencia',
		desc: 'Utilizamos la inmunofluorescencia directa en el estudio de enfermedades inmunitarias como el lupus eritematoso, enfermedades ampollosas como el pénfigo, entre otras, en biopsias cutáneas y la clasificación de glomerulopatías en biopsias renales. Esta técnica se puede realizar en muestras en fresco,  y también fijadas en formol y embebidas en parafina.',
		link: '',
		icon: inmfluIconAgs,
		image: inmFluCardImg,
	},
	{
		name: 'Biología molecular',
		desc: 'El departamento de biología molecular realiza pruebas para la identificación de alteraciones genéticas causantes de diferentes patologías, incluido el cáncer y la identificación de genomas de microorganismos infecciosos.',
		link: '/molecular',
		icon: molecuIconAgs,
		image: bioMolCardImg,
	},
	{
		name: 'Servicios genéticos',
		desc: 'Pruebas genéticas para la detección de alteraciones causantes de trastornos o enfermedades.',
		link: '/servicios-geneticos',
		icon: sergenIconAgs,
		image: serGenCardImg,
	},
]

const StyledDiv = styled.div`
  background: #FFF;
  padding: 50px 0;
  h1 {
    color: ${TEXT_BLACK};
    text-align: center;
    font-size: 70px;
    font-weight: 300;
    line-height: 105.5%;
    span {
      background: ${OUTLINE_CARDS};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 500;
    }
  }
	@media only screen and (width <= 768px) {
    h1 {
      font-size: 32px;
      max-width: 132px;
			margin: 0 auto 20px;
    }
	}
`

const Card = styled.div`
	width: 90%;
	max-width: 285px;
	margin: 10px auto;
	border-radius: 40px;
	border: 1px solid #E0ECEC;
	background: #FFF;
	overflow: hidden;
	transition: all ease 0.3s !important;
	.img-div {
		filter: grayscale(1);
		width: 100%;
		height: 100px;
		background: url('${props => props.img ?? ''}');
		background-position: center;
		background-size: cover;
	}
	.info-div {
		height: 185px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		transition: all ease 0.3s !important;
	}
	.link {
		cursor: pointer;
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		line-height: normal;
		text-decoration-line: underline;
		display: none;
		transition: all ease 0.3s !important;
	}
	h2 {
		color: ${TEXT_BLACK};
		text-align: center;
		font-size: 20px;
		font-weight: 600;
		line-height: normal;
	}
	.green-icon {
		background: #474A4B;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		transition: all ease 0.3s !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	:hover {
		.img-div {
			transition: all ease 0.3s !important;
			filter: grayscale(0);
		}
		.link {
			display: inherit;
		}
		.green-icon {
			background: ${OUTLINE_CARDS};
		}
	}
`;

const StyledModal = styled(Modal)`
	.modal-dialog {
		width: 100%;
		max-width: 715px !important;
	}
	.modal-content {
		width: 96%;
		max-width: 715px !important;
		border-radius: 40px;
		overflow: hidden;
	}
	.modal-body {
		height: 450px;
		background: ${({bg}) => `url('${bg}') no-repeat center`};
		background-size: cover;
		padding: 0;
		transition: all 0.4s ease;
		filter: grayscale(1);
		position: relative;
		:hover {
		filter: grayscale(0);
		transition: all 0.4s ease;
		#text-div {
			transition: all 0.4s ease;
			opacity: 0;
		}
		#close-button {
			opacity: 1;
			transition: all 0.4s ease;
		}
		}
	}
	#close-button {
		position: absolute;
		top: 15px;
		right: 15px;
		opacity: 0;
		transition: all 0.4s ease;
		cursor: pointer;
	}
	#text-div {
		width: 100%;
		height: 270px;
		background: #F9F9F9;
		margin-top: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		transition: all 0.4s ease;
		padding: 25px;
	}
	h1 {
		color: #474A4B;
		text-align: center;
		font-size: 44px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 10px;
	}
	p {
		width: 85%;
		color: #474A4B;
		text-align: center;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	@media only screen and (max-width: 768px) {
		h1 {
		font-size: 30px;
		}
		p {
		font-size: 16px;
		}
		.modal-content {
		border-radius: 20px;
		}
		#text-div {}
	}
`;

export const OurServices = () => {
	const [show, setShow] = useState(false);
	const [modalInfo, setModalInfo] = useState();

	// We get window width
	const [width, setWidth] = useState(window.innerWidth);
	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);
	let isMobile = width <= 768;

	const handleOpen = () => setShow(true);
	const handleClose = () => setShow(false);

	const handleClick = (service) => {
		setModalInfo(service);
		handleOpen();
	}
	
	return (
		<StyledDiv>
				<h1>Nuestros <span>servicios</span></h1>
				<Container>
					<Row>
						{Services?.map((service, index) => 
							<Col xs={12} md={6} lg={4} onClick={()=> handleClick(service)} key={index}>
								<Card img={service?.image}>
									<div className="img-div"/>
									<div className="info-div">
										<div className="green-icon">
											<img src={service?.icon} alt="Icon"/>
										</div>
										<h2>{service?.name}</h2>
										{service?.link !== '' ? 
											<p className="link" onClick={() => window.location.href = service?.link}>Más información</p>
										: null}
									</div>
								</Card>
							</Col>
						)}
					</Row>
				</Container>
				<StyledModal
					show={show}
					onHide={handleClose}
					dialogClassName="modal-dialog-slide"
					bg={modalInfo?.image}
					centered
				>
					<Modal.Body closeButton>
					<div id="close-button" onClick={handleClose}>
						<UilMultiply color="#F3F3F3" size={52}/>
					</div>
					<div id="text-div">
						<h1>{modalInfo?.name}</h1>
						<p>{modalInfo?.desc}</p>
					</div>
					</Modal.Body>
				</StyledModal>
		</StyledDiv>
	)
};