import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StudiesSlider } from "./studiesSlider";
import { Search } from "../navbar/search";

const MainContainer = styled(Container)`
  padding-top: 76px;
  @media only screen and (max-width: 768px) {
    padding-top: 45px;
  }
`;

const Title = styled.h5`
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-size: 44px;
  line-height: 125%;
  text-align: center;
  color: #474A4B;
  margin-top: 28px;
  @media only screen and (max-width: 768px) {
    font-size: 25px;
    margin-top: 15px;
  }
`;

export const Highlights = () => {
  // Studies state
  const [studies, setStudies] = useState([]);
  const [filteredStudies, setFilteredStudies] = useState([]);

  // Searching state
  const [searching, setSearching] = useState("");

  // Get highlight studies
  const getStudies = async () => {
    try {
      let request = axios.get(
        'https://lpqsystem.com/get-featured-studiesALL'
      );
      let data = await request;
      setStudies(data.data);
      setFilteredStudies(data.data)
    } catch (error) {
      console.log(error);
    }
  };

  // Get studies first time
  useEffect(() => {
    getStudies();
  }, []);

  // Filter studies every time searching changes
  useEffect(() => {
    const finalStudies = studies?.filter((study)=>
      study.title?.toLowerCase()?.includes(searching.toLowerCase())
    )
    setFilteredStudies(finalStudies?.length <= 0 || searching === "" ? studies : finalStudies);
  },[searching]);

  return (
    <MainContainer>
      <Row>
        <Col md={12} className='text-center'>
          <Title>¿Qué estudios buscas?</Title>
          {/* Searcher input div*/}
          <div style={{ maxWidth: '548px', marginLeft: 'auto', marginRight: 'auto' }}>
            <Search
              setSearching={setSearching}
              hideSearch={() => null}
              showSearch={() => null}
              mainColor={"#00AD90"}
              notFocus={'nope'}
            />
          </div>
        </Col>
        {/* Slider column*/}
        <Col md={12}>
          <StudiesSlider studies={filteredStudies} />
        </Col>
      </Row>
    </MainContainer>
  )
};