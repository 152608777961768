import React from "react";
import { VideoOctober } from "./videoOctober";
import OurStudies from "./ourStudies";
import { HelmetTitle } from "../../helpers/HelmetTitle";

const October = () => {
  return (
    <div>
      <HelmetTitle title="Octubre Rosa." name="description" content="content" />
      <VideoOctober/>
      <OurStudies/>
    </div>
  )
};

export default October;