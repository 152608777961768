import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Modal, Row } from "react-bootstrap";
import logoB from "../../assets/logos/logo1.png";
import { Link, NavLink } from "react-router-dom";
import { getUserInfo } from "../../actions/user";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  UilBookMedical,
  UilDocumentLayoutLeft,
} from "@iconscout/react-unicons";
import { SideDrawer } from "./drawer";
import { Sections } from "./sections";
import { Cart } from "../shopping-cart/cart";
import { Searcher } from "../navbar/searcher";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { allowedUsersToPost } from "../blog/colors";

const SidebarSection = styled.div`
  display: ${(props) => (props.isMobile ? "none" : "")};
  background-color: ${(props) => (props.isDoctor ? "#4f90be" : "#878D96")};
  width: 100%;
  height: 100%;
`;

const StickyContent = styled.div`
  padding: 0% 17% 0% 17%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  h1 {
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

const StyledNav = styled.nav`
  a {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin: 20px;
    color: #fff;
    :hover {
      color: ${(props) => props.mainColor};
    }
  }
  .cart {
    color: ${(props) => (props.showCart ? props.mainColor : "#878D96")};
    :hover {
      color: ${(props) => props.mainColor};
    }
  }
  .icon {
    max-width: 25px;
    max-height: 25px;
    height: auto;
    color: #fff;
    @media only screen and (max-width: 992px) {
      max-width: 20px;
      max-height: 20px;
    }
  }
  .bigIcon {
    max-width: 22px;
    max-height: 22px;
    height: auto;
    @media only screen and (max-width: 992px) {
      max-width: 18px;
      max-height: 18px;
    }
  }
  svg {
  }
  .active {
    color: ${(props) => props.mainColor} !important;
  }
`;

const CartCount = styled.div`
  background-color: ${(props) =>
    props.isdoctor === 1 ? "#4D99E5" : "#00AC90"};
  padding: 9px;
  position: absolute;
  top: -5px;
  right: -4px;
  color: white;
  width: 16px;
  height: 16px;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    font-size: 12px;
  }
  @media only screen and (max-width: 1200px) {
    right: -25%;
  }
  @media only screen and (max-width: 992px) {
    right: -14%;
    padding: 6px;
  }
`;

const CardContainer = styled.div`
  position: relative;
  display: inline-flex;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 10px;
  width: 90%;
  cursor: pointer;
  margin: 15px;
  max-width: 210px;
  height: 114px;
  color: #fff;
  padding: 10px 4px 13px;
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  margin-bottom: 20px;
  transition: all ease 0.3s !important;
  :hover {
    background-color: #fff;
    color: #4f90be;
    p {
      transition: all ease 0.3s !important;
      color: #4f90be;
    }
    #imgicon {
      background-color: #000;
    }
  }
  .row .col-3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .icon {
    max-width: 25px;
    max-height: 25px;
    height: auto;
    background: #fff;
    @media only screen and (max-width: 992px) {
      max-width: 20px;
      max-height: 20px;
    }
  }
`;
export const SideNav = (props) => {
  const [showSearcher, setShowSearcher] = useState(props.showSearcher);
  const [packages, setPackages] = useState([]);
  const isLoggedIn = useSelector((state) => state.auth?.uid || false);
  const theUID = useSelector((state) => state.auth?.uid || "");
  const [loading, setLoading] = useState(false);
  const uid = useSelector((state) => state?.auth?.uid);
  const user = useSelector((state) => state?.user?.data.data);
  const { items } = useSelector((state) => state.shoppingCart);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const isDoctor = user?.profile?.type === 1;
  const [userName, setUserName] = useState(""); //useSelector((state) => `${state.auth?.name}` || undefined);
  const location = useLocation();
  const mainColor =
    (PrivRoutes.includes(location.pathname) ||
      location?.pathname?.includes("editar-post/") ||
      location?.pathname?.includes("paciente/") ||
      location?.pathname?.includes("editar-paquete/")) &&
    isDoctor
      ? "#4D99E5"
      : "#00AC90";

  const handleCart = () => {
    props.setShowCartSideBar(!props.showCartSideBar);
  };

  useEffect(() => {
    setShowSearcher(props.showSearcher);
  }, [props.showSearcher]);
  const setUser = async () => {
    //console.log("the uid", theUID)
    let user = await getUserInfo(theUID);
    var drGender = "Dr. ";
    if (user.data.gender === "Femenino") {
      drGender = "Dra. ";
    }
    var name = "";
    if (user.data?.profile?.type === 1) {
      name = `${drGender}${user.data.name} ${user.data.fathersLastname} ${user.data.mothersLastname}`;
    } else {
      name = `${user.data.name} ${user.data.fathersLastname} ${user.data.mothersLastname}`;
    }
    setUserName(name);
  };
  useEffect(() => {
    setUser();
  }, []);

  const dd = async () => {
    try {
      setLoading(true);
      let requestPackages = await axios.get(
        `https://lpqsystem.com/getPaquetesMedico?uid=${uid}`
      );
      // console.log(requestPackages);
      if (requestPackages.data.ok) {
        setPackages(requestPackages.data.paquetes_medico);
        setLoading(false);
      } else {
        console.log("Error al obtener paquetes");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    dd();
  }, []);

  const handleDelete = async (id) => {
    console.log(id);
    try {
      console.log("deleting package...?");
      setLoading(true);
      let response = await axios.get(
        `https://lpqsystem.com/eliminarPaquete?id=${id}`
      );
      console.log(response);
      dd();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {width <= 768 ? (
        <SideDrawer setShowSearcher={(e) => props.setShowSearcher(e)} />
      ) : (
        <SidebarSection
          isDoctor={isDoctor}
          isMobile={width <= 768 ? true : false}
        >
          <StickyContent>
            <br />
            <h1>
              {width > 768 &&
                (isLoggedIn
                  ? userName?.length >= 17
                    ? `${userName?.substring(0, 25)}`
                    : userName !== ""
                  : "")}
            </h1>
            <StyledNav
              className="pt-1 pb-4"
              style={
                isDoctor
                  ? { display: "flex" }
                  : { display: "flex", justifyContent: "center" }
              }
              showCart={props.showCartSideBar}
              isMobile={width <= 768 ? true : false}
              isDoctor={isDoctor}
              mainColor={mainColor}
            >
              {isDoctor ? (
                <>
                  {Sections.docSections.map((section, index) => (
                    <>
                      {section.name === "Estudios" ? (
                        <div key={index}>
                          <a className="cart" onClick={handleCart}>
                            <Row className="align-items-center">
                              <CardContainer>
                                <Col
                                  style={{ paddingRight: "0" }}
                                 
                                >
                                  {items.length > 0 ? (
                                    <CartCount isdoctor={isDoctor ? 1 : 0}>
                                      <p>{items.length}</p>
                                    </CartCount>
                                  ) : null}
                                </Col>
                                <Col xs={10}>
                                  {section.icon}
                                  {section.name} <br />
                                  <p style={{ fontSize: "14px" }}>
                                    {section.text}
                                  </p>
                                </Col>
                              </CardContainer>
                            </Row>
                          </a>
                        </div>
                      ) : (
                        // Cuando la opcion es buscar abre el buscador que es fullscreen
                        <div key={index}>
                          {section.route === "search" ? (
                            <a className="cart" onClick={handleCart}>
                              <Row
                                className="align-items-center"
                                onClick={() => props.setShowSearcher(true)}
                                style={{ color: "#878D96", cursor: "pointer" }}
                              >
                                <CardContainer>
                                  {section.icon}
                                  {section.name}
                                  
                                  <p style={{ fontSize: "11px" }}>
                                    {section.text}
                                  </p>
                                </CardContainer>
                              </Row>
                            </a>
                          ) : (
                            <NavLink to={section.route}>
                              <Row className="align-items-center">
                                <CardContainer>
                                  <Col xs={2}></Col>
                                  
                                  <Col xs={10}>
                                    {section.icon}
                                    {section.name} <br />
                                    <br />
                                    <p style={{ fontSize: "11px" }}>
                                      {section.text}
                                    </p>
                                  </Col>
                                </CardContainer>
                              </Row>
                            </NavLink>
                          )}
                        </div>
                      )}
                    </>
                  ))}

                  {/* Blog option nav if allowed */}
                  {allowedUsersToPost?.includes(uid) && (
                    <div key={"create-post"}>
                      <NavLink to="/crear-post">
                        <Row className="align-items-center">
                          <CardContainer>
                            <Col xs={1} style={{ paddingRight: "0" }}></Col>
                            <Col xs={10}>
                              <UilBookMedical />
                              Crear post <br />
                              <br />
                              <p style={{ fontSize: "11px" }}>
                                Crea y edite su blog
                              </p>
                            </Col>
                          </CardContainer>
                        </Row>
                      </NavLink>
                    </div>
                  )}
                  {/* Blog option nav if allowed */}
                  {allowedUsersToPost?.includes(uid) && (
                    <div>
                      <NavLink to="/admin-blog" key={"admin-blog"}>
                        <Row className="align-items-center">
                          <CardContainer>
                            <Col xs={1} style={{ paddingRight: "0" }}></Col>
                            <Col xs={10}>
                              <UilDocumentLayoutLeft />
                              Admin Blog <br />
                              <br />
                              
                                Administra tus blogs
                            
                            </Col>
                          </CardContainer>
                        </Row>
                      </NavLink>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {Sections.userSections.map((section, index) => (
                    <>
                      {section.name === "Estudios" ? (
                        <div className="mt-4 mb-4" key={index}>
                          <a className="cart" onClick={handleCart}>
                            <Row className="align-items-center">
                              <CardContainer>
                                <Col
                                  style={{ paddingRight: "0" }}
                                  className="position-relative"
                                >
                                  {items.length > 0 ? (
                                    <CartCount isdoctor={isDoctor ? 1 : 0}>
                                      <p>{items.length}</p>
                                    </CartCount>
                                  ) : null}
                                </Col>
                                <Col xs={10}>
                                  {" "}
                                  {section.icon}
                                  {section.name}
                                  <br />
                                  <br />
                                  <p style={{ fontSize: "12px" }}>
                                    {section.text}
                                  </p>
                                </Col>
                              </CardContainer>
                            </Row>
                          </a>
                        </div>
                      ) : (
                        <div className="mt-4 mb-4" key={index}>
                          <NavLink to={section.route}>
                            <Row className="align-items-center">
                              <CardContainer>
                                <Col xs={10}>
                                  {" "}
                                  {section.icon}
                                  {section.name}
                                  <br />
                                  <br />
                                  <p style={{ fontSize: "12px" }}>
                                    {section.text}
                                  </p>
                                </Col>
                              </CardContainer>
                            </Row>
                          </NavLink>
                        </div>
                      )}
                    </>
                  ))}
                </>
              )}
            </StyledNav>
          </StickyContent>
        </SidebarSection>
      )}

      <Cart
        onClose={() => {
          props.setShowCartSideBar(false);
        }}
        visible={props.showCartSideBar}
        openSearcher={() => props.setShowSearcher(true)}
      />
    </>
  );
};
