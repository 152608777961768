import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { PINK_OCTOBER } from "../../helpers/colors";
import StudyDiv from "./studyDiv";
import SeeStudies from "./seeStudies";
import axios from "axios";
import { UilPhoneVolume, UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { Loader } from '../general/spinner';

const allStudies = '14-56-90-113-116-123-125-126-127-135-136-141-146-150-154-160-163-164-194-261-265-267-268-271-277-293-344-350-381-388-409-410-411-598-619-669-671-702-769-773-943-945-949-964-1003-1004-1005-1006-1009-1011';

const StudiesIDs = [
  {
    id: 1,
    category: 'Ultrasonido',
    studiesIds: [ 146, 150, 154, 160, 1009, 1003 ],
  },
  {
    id: 2,
    category: 'Laboratorio clínico',
    studiesIds: [ 141, 136, 135, 113, 14, 943, 116 ],
  },
  {
    id: 3,
    category: 'Biología molecular',
    studiesIds: [ 773, 164, 163 ],
  },
  {
    id: 4,
    category: 'Patología',
    studiesIds: [ 964, 127, 409, 388, 381, 267, 277, 271, 265, 90, 268, 56, 350, 90, 293, 123, 261, 344 ],
  },
  {
    id: 5,
    category: 'Citología',
    studiesIds: [ 411, 126, 411, 1005, 1004, 1006, 671, 410, 125 ],
  },
  {
    id: 6,
    category: 'Inmunohistoquímica',
    studiesIds: [ 769, 669, 702 ],
  },
];

const regularPrice = [
  { id: 619, regular_price: 21000},
  { id: 773, regular_price: 3074},
  { id: 164, regular_price: 2332},
  { id: 163, regular_price: 7420},
  { id: 127, regular_price: 6000},
  { id: 964, regular_price: 5000},
  { id: 126, regular_price: 689},
  { id: 411, regular_price: 445},
  { id: 1005, regular_price: 450},
  { id: 1004, regular_price: 650},
  { id: 1006, regular_price: 850},
  { id: 671, regular_price: 212},
  { id: 410, regular_price: 445},
  { id: 125, regular_price: 445},
  { id: 945, regular_price: 1725},
  { id: 127, regular_price: 6000},
  { id: 409, regular_price: 2265},
  { id: 388, regular_price: 2265},
  { id: 381, regular_price: 2265},
  { id: 267, regular_price: 2265},
  { id: 277, regular_price: 2694},
  { id: 271, regular_price: 2694},
  { id: 265, regular_price: 2694},
  { id: 90, regular_price: 1725},
  { id: 268, regular_price: 1725},
  { id: 56, regular_price: 1725},
  { id: 350, regular_price: 2265},
  { id: 90, regular_price: 1725},
  { id: 293, regular_price: 2265},
  { id: 123, regular_price: 1725},
  { id: 261, regular_price: 1725},
  { id: 344, regular_price: 2265},
  { id: 769, regular_price: 5736},
  { id: 669, regular_price: 6453},
  { id: 702, regular_price: 7051},
  { id: 141, regular_price: 430},
  { id: 136, regular_price: 850},
  { id: 135, regular_price: 191},
  { id: 113, regular_price:  1500},
  { id: 14, regular_price: 210},
  { id: 943, regular_price: 760},
  { id: 116, regular_price: 1200},
  { id: 949, regular_price: 21000},
  { id: 146, regular_price: 850},
  { id: 150, regular_price: 1250},
  { id: 154, regular_price: 1250},
  { id: 160, regular_price: 1250},
  { id: 1009, regular_price: 660},
  { id: 1003, regular_price: 880},
  { id: 598, regular_price: 6500}
]

const MainContainer = styled(Container)`
  padding: 71px 14px 250px;
  position: relative;
  @media only screen and (max-width: 992px) {
    width: 100% !important;
    max-width: 100% !important;
    overflow-x: hidden;
    overflow-y: show;
    padding: 20px 20px 250px;
  }
  h1 {
    color: #090C29;
    text-align: center;
    font-size: 33px;
    font-weight: 700;
    line-height: 136.364%;
    @media only screen and (max-width: 992px) {
      font-size: 25px;
    }
  }
  > .row {
    margin-top: 40px;
    justify-content: center;
    @media only screen and (max-width: 992px) {
      margin-top: 0;
    }
  }
  .pink-card {
    border-radius: 40px;
    background: #F3F3F3;
    width: 95%;
    max-width: 510px;
    position: relative;
    margin-bottom: 70px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 100px;
      p {
        font-size: 20px;
      }
    }
    p {
      color: #474A4B;
      text-align: center;
      font-size: 17px;
      font-weight: 400;
      line-height: 140%;
    }
  }
  #sergen-img {
    position: absolute;
    width: 99px;
    right: -80px;
    top: 30px;
  }
  #ultbio-img {
    position: absolute;
    width: 90px;
    bottom: 60px;
    left: -20px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  #pat-img {
    position: absolute;
    width: 254px;
    right: -60px;
    top: -100px;
    @media only screen and (max-width: 992px) {
      width: 167px;
      right: -60px;
      top: -60px;
    }
  }
  .category {
    padding: 37px 63px 58px;
    @media only screen and (max-width: 992px) {
      padding: 18px 40px 58px;
    }
  }
  .company {
    padding: 132px 63px 58px;
    @media only screen and (max-width: 992px) {
      padding: 132px 40px 58px;
    }
    .img-company-div {
      border-radius: 40px 40px 0 0;
      position: absolute;
      width: 100%;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 116px;
      overflow: hidden;
      display: flex;
      justify-content: center;
    }
    img {
      height: 116px;
    }
    span {
      font-weight: 600;
    }
  }
  .col-12 {
    display: flex;
    justify-content: center;
    align-items: start;
    @media only screen and (max-width: 992px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  #first-image-col {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: -50px;
    @media only screen and (max-width: 992px) {
      height: 0px;
      overflow: show;
      margin-top: 250px;
      margin-bottom: -250px;
    }
  }
  #first-image {
    height: 923px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-right: -250px;
    margin-bottom: -200px;
    z-index: -1;
    @media only screen and (max-width: 992px) {
      height: 506px;
      margin-right: -500px;
    }
  }
  #second-image-col {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  #second-image {
    height: 892px;
    margin-left: -220px;
    margin-top: -100px;
    margin-bottom: -80px;
    @media only screen and (max-width: 992px) {
      margin-bottom: -140px;
      margin-top: -50px;
    }
  }
  .column-no-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.6);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mini {
    font-size: 15px !important;
    line-height: normal;
    margin-bottom: 0;
  }
  #flowers {
    position: absolute;
    bottom: 0;
    left: 0;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  h5 {
    color: #090C29;
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
    @media only screen and (max-width: 992px) {
      font-size: 33px;
    }
  }
  .absolute-button {
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 85%;
    max-width: 323px;
    height: 52px;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  button {
    transition: all 0.4s ease;
    :hover {
      filter: brightness(115%);
    }
  }
`;

const Title = styled.h1`
  color: #090C29;
  font-size: 60px !important;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
  span {
    color: ${PINK_OCTOBER};
  }
  @media only screen and (max-width: 992px) {
    font-size: 33px !important;
  }
`;

const TitleTwo = styled.h1`
  color: #090C29;
  text-align: center;
  font-size: 50px !important;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
  margin-bottom: 15px;
  @media only screen and (max-width: 992px) {
    font-size: 33px !important;
  }
`;

const Desc = styled.p`
  color: #051036;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  line-height: 130%;
  max-width: 707px;
  margin: 30px auto 20px;
  @media only screen and (max-width: 992px) {
    font-size: 20px !important;
  }
`;

const DescRios = styled.p`
  overflow: hidden;
  color: #051036;
  text-align: center;
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  height: ${({more}) => more ? 'auto' : '78px'};
  max-width: 378px;
  margin: 30px auto 20px;
`;

const Subtitle = styled.p`
  color: ${PINK_OCTOBER};
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
  span {
    font-weight: 600;
  }
  @media only screen and (max-width: 992px) {
    font-size: 23px;
  }
`;

const More = styled.p`
  color: ${PINK_OCTOBER};
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

const MainButton = styled.button`
  border: none;
  background: ${PINK_OCTOBER};
  color: #FFF;
  font-size: 22px;
`;

const SecondaryButton = styled.button`
  border: 2px solid ${PINK_OCTOBER};
  color: ${PINK_OCTOBER};
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; 
  width: 85%;
  max-width: 323px;
  height: 52px;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const OurStudies = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(false);

  useEffect(()=>{
    axios.get(`https://lpqsystem.com/get-studies-detail?estudios=${allStudies}`)
      .then(res => {
        setData({
          ultrasonido: res?.data?.filter(e=>StudiesIDs[0]?.studiesIds?.includes(e.id))?.map(item => {return {...item, regular_price: regularPrice?.find(e=>e.id === item?.id)?.regular_price ?? 0}}),
          laboratorio: res?.data?.filter(e=>StudiesIDs[1]?.studiesIds?.includes(e.id))?.map(item => {return {...item, regular_price: regularPrice?.find(e=>e.id === item?.id)?.regular_price ?? 0}}),
          molecular: res?.data?.filter(e=>StudiesIDs[2]?.studiesIds?.includes(e.id))?.map(item => {return {...item, regular_price: regularPrice?.find(e=>e.id === item?.id)?.regular_price ?? 0}}),
          patologia: res?.data?.filter(e=>StudiesIDs[3]?.studiesIds?.includes(e.id))?.map(item => {return {...item, regular_price: regularPrice?.find(e=>e.id === item?.id)?.regular_price ?? 0}}),
          citologia: res?.data?.filter(e=>StudiesIDs[4]?.studiesIds?.includes(e.id))?.map(item => {return {...item, regular_price: regularPrice?.find(e=>e.id === item?.id)?.regular_price ?? 0}}),
          inmuno: res?.data?.filter(e=>StudiesIDs[5]?.studiesIds?.includes(e.id))?.map(item => {return {...item, regular_price: regularPrice?.find(e=>e.id === item?.id)?.regular_price ?? 0}}),
        });
        setAllData(res?.data?.map(item => {return {...item, regular_price: regularPrice?.find(e=>e.id === item?.id)?.regular_price ?? 0}}));
      })
      .catch((error) => {
        console.log(error);
      });
  },[]);

  useEffect(()=>{
    if(data?.ultrasonido?.length >= 1){
      setLoading(false);
    }
  },[data]);

  const callToSchedule = (studyName) => {
    window.open(`https://wa.me/522212311313?text=Quiero agendar una cita para ${studyName}`)
  };

  return (
    <>
    {loading ? <Loader show={loading}/> : 
      <div style={{width: '100%', overflowX: 'hidden'}}>
      <MainContainer>
        {/* Heading */}
        <Title>Conoce nuestros <span>estudios rosas</span></Title>
        <Subtitle>¡Y toma decisiones informadas sobre tu salud!</Subtitle>

        {/* Column cards row */}
        <Row>

          {/* Imagen */}
          <Col xs={12} lg={6} id='first-image-col'>
            <img id='first-image' src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20f655748b71247c7f886583bf51af1289.png?alt=media&token=44c92d70-6377-4a46-b1b0-1465cfda055b" alt="Montes y montañas" />
          </Col>
          
          {/* Montes y montañas */}
          <Col xs={12} lg={6} className="column-no-card">
            <h5>Montes y Montañas</h5>
            <Subtitle>Muy bell@s por fuera, pero ¡echemos un vistazo por dentro!</Subtitle>
            <h1>Mastografías</h1>
            <StudyDiv isGreen={true} study={allData?.find(e => e.id === 1009)}/>
            <StudyDiv isGreen={true} study={allData?.find(e => e.id === 1003)}/>
            <SecondaryButton onClick={()=>callToSchedule('Mastografías')}>Agenda tu cita</SecondaryButton>
          </Col>

          {/* Servicios genéticos */}
          <Col xs={12} lg={6}>
            <div className="pink-card category">
              <h1>Servicios genéticos</h1>
              <p>Pruebas genéticas para la detección de alteraciones causantes de trastornos o enfermedades.</p>
              <StudyDiv isGreen={true} study={{...allData?.find(e => e.id === 949), categoria: 10}}/>
              <p className="mini">Válido solo en sucursal Palmas Plaza.</p>
              <MainButton className="absolute-button" onClick={()=>callToSchedule('Panel para cáncer de mama y ginecológico')}><UilPhoneVolume size={30}/> Llamar para agendar</MainButton>
              <img id="sergen-img" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FRecurso%2033.png?alt=media&token=eb08ddc8-cadb-42bf-9e9b-6043c858209b" alt="img" />
            </div>
          </Col>

          {/* Sala de ultrasonidos y biopsias */}
          <Col xs={12} lg={6}>
            <div className="pink-card category">
              <h1>Sala de ultrasonidos y biopsias</h1>
              <p>Estudios de imagen para la detección de alteraciones causantes de cáncer.</p>
              {/* <StudyDiv isGreen={true} study={allData?.find(e => e.id === 1011)} extraInfo={true}/> */}
              <p className="mini">Válido solo en sucursal Palmas Plaza.</p>
              <SeeStudies bottom={true} studies={data?.ultrasonido}/>
              <img id="ultbio-img" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FRecurso%2030.png?alt=media&token=e1e742f5-4a93-4574-bce8-c3344cbb2c3f" alt="img" />
            </div>
          </Col>

          {/* Laboratorio clínico */}
          <Col xs={12} lg={6}>
            <div className="pink-card category">
              <h1>Estudios de laboratorio clínico</h1>
              <p>Servicio de análisis eficiente, innovador y de confianza para la detección, tratamiento y prevención oportuna de patologías.</p>
              <StudyDiv isGreen={true} study={allData?.find(e => e.id === 113)}/>
              <StudyDiv isGreen={true} study={allData?.find(e => e.id === 116)}/>
              <p className="mini">Válido en todas las sucursales LabopatⓇ</p>
              <SeeStudies bottom={true} studies={data?.laboratorio}/>
            </div>
          </Col>

          {/* Imagen */}
          <Col xs={12} lg={6} id='second-image-col'>
            <img id='second-image' src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20Second%20image.png?alt=media&token=8cd8d88e-0562-4589-88bc-883b72e8cd3f" alt="Montes y montañas" />
          </Col>

          {/* Vitalgene */}
          <Col xs={12} lg={6}>
            <div className="pink-card company">
              <div className="img-company-div">
                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20Vitalgene%20encabezado.png?alt=media&token=30438a71-dffd-4ebf-aaa5-9dadd56e0354" alt="Vitalgene" />
              </div>
              <p><span>Prueba genética para nutrición personalizada y condición física.</span></p>
              <p>Analiza 70 variantes en 62 genes implicados en el metabolismo de nutrimentos, salud cardiometabólica, control de peso y composición corporal; intolerancia alimentaria, ejercicio y salud física.</p>
              <StudyDiv isGreen={true} study={allData?.find(e => e.id === 598)}/>
              <p className="mini">Envíos a toda la república mexicana.</p>
              <SecondaryButton className="absolute-button" onClick={()=>callToSchedule('Nutrición personalizada VitalGene 70')}>Más información</SecondaryButton>
            </div>
          </Col>

          {/* Axiona */}
          <Col xs={12} lg={6}>
            <div className="pink-card company">
              <div className="img-company-div">
                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20Axiona%20encabezado.png?alt=media&token=ad8d5950-a8c3-4780-a29e-f2722c376b39" alt="Axiona" />
              </div>
              <p>Prueba genética para <span>detectar alteraciones en 134 genes asociados con el riesgo de padecer alguno de los tipos de cáncer más comunes</span> como de mama, ovario, endometrio, entre otros, mediante la secuenciación del ADN.</p>
              <StudyDiv isGreen={true} study={{...allData?.find(e => e.id === 619), categoria: 10}}/>
              <p className="mini">Envíos a toda la república mexicana.</p>
              <SecondaryButton className="absolute-button" onClick={()=>callToSchedule('Panel cáncer hereditario Axiona GENE')}>Más información</SecondaryButton>
            </div>
          </Col>
        </Row>
        
        {/* Second heading */}
        <TitleTwo>La aventura continúa</TitleTwo>
        <Subtitle>Conoce nuestro <span>amplio catálogo de estudios de alta especialidad</span></Subtitle>
        <Desc>En Labopat® somos especialistas en servicios de diagnóstico de alta especialidad para la salud de la mujer, ¡tenemos más de 30 años de experiencia en el sector! </Desc>

        <Row>

          {/* Biología Molecular */}
          <Col xs={12} lg={6}>
            <div className="pink-card category">
              <h1>Biología Molecular</h1>
              <StudyDiv isGreen={true} study={allData?.find(e => e.id === 163)}/>
              <SeeStudies bottom={true} studies={data?.molecular}/>
            </div>
          </Col>

          {/* Patología */}
          <Col xs={12} lg={6}>
            <div className="pink-card category">
              <h1>Patología</h1>
              <StudyDiv isGreen={true} study={allData?.find(e => e.id === 945)}/>
              <StudyDiv isGreen={true} study={allData?.find(e => e.id === 127)}/>
              <SeeStudies bottom={true} studies={data?.patologia}/>
              <img id="pat-img" src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20Niplit-2023-%201.png?alt=media&token=3e8e4948-48d6-44fd-883c-ba9573a4c24a" alt="img" />
            </div>
          </Col>

          {/* Imagen */}
          <Col xs={12} lg={6} id='first-image-col'>
            <img id='first-image' src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20ffce352eb0bfa037a544aece3c9a2657.png?alt=media&token=9d662d03-b882-4de9-8dae-36abd6ef609a" alt="Ríos celulosos" />
          </Col>
          
          {/* Ríos celulosos */}
          <Col xs={12} lg={6} className="column-no-card">
            <h5>Ríos celulosos</h5>
            <Subtitle>¿Has escuchado de la <span>citología<br/>en base líquida</span>?</Subtitle>
            <DescRios more={more}>Es un método, más eficaz que el convencional, donde la muestra tomada del cérvix se disuelve en un medio líquido de conservación y se trasfiere el material a una lámina en monocapa, la muestra es más completa sin células dañadas o cubiertas por sangre, moco o células inflamatorias lo cual permite al patólogo una mejor visualización de las células, por lo cual se asocia con una menor frecuencia de errores de diagnóstico.</DescRios>
            <More onClick={()=>setMore(!more)}>{more ? 'Ver menos' : 'Ver más'} {more ? <UilAngleUp/> : <UilAngleDown/>}</More>
            <h1>Citología</h1>
            <StudyDiv isGreen={true} study={allData?.find(e => e.id === 126)}/>
            <SeeStudies studies={data?.citologia}/>
          </Col>

          {/* Inmunohistoquímica */}
          <Col xs={12} lg={6}>
            <div className="pink-card category">
              <h1>Inmunohistoquímica</h1>
              <StudyDiv isGreen={true} study={allData?.find(e => e.id === 669)}/>
              <p className="mini">Válido en todas las sucursales LabopatⓇ</p>
              <SeeStudies bottom={true} studies={data?.inmuno}/>
            </div>
          </Col>
        </Row>

        <img id='flowers' src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20Recurso-1%201.png?alt=media&token=c248937f-79ec-4ac8-8f64-fa37781ebfad" alt="Flores" />
      </MainContainer>
      </div>
    }
    </>
  )
};

export default OurStudies;