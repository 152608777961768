import { 
  UilEstate,
  UilSearchAlt,
  UilUser,
  UilUsersAlt,
  UilClipboardAlt,
  UilFilePlusAlt, 
  UilFileMedicalAlt,
  UilMonitorHeartRate,
  UilBagAlt,
  UilStore
} from '@iconscout/react-unicons';

export const Sections = {
  docSections: [
    {
      name: 'Mi perfil',
      icon: <UilUser/>,
      route: '/datos-personales',
      text: 'Accede a tu perfil medico para más información'
    },
    {
      name: 'Mis pacientes',
      icon: <UilUsersAlt/>,
      route: '/pacientes',
      text: 'Encuentra el expediente Medico de tus pacientes'
    },
    {
      name: 'Mis resultados',
      icon: <UilMonitorHeartRate/>,
      route: '/resultados',
      text: 'Localiza los resultados de tus pacientes'
    },
    {
      name: 'Sucursales',
      icon: <UilStore/>,
      route: '/sucursalesM',
      text: 'Localiza tu sucursal más cercana'
    },   
    {
      name: 'Paquetes',
      icon: <UilFilePlusAlt/>,
      text: 'Busca los estudios o paquetes creados',
      route: '/mis-paquetes'
    },
  ],
  //docPacientes: [
  //  {
  //    name: 'Paquetes',
  //    icon: <UilFilePlusAlt/>,
  //    route: '/mis-paquetes'
  //  },
  //  {
  //    name: 'Mis pacientes',
  //    icon: <UilUsersAlt/>,
  //    route: '/pacientes',
  //    text: 'Encuentra el expediente Medico de tus pacientes'
  //  },
  //],
  userSections: [
    {
      name: 'Inicio',
      icon: <UilEstate/>,
      route: '/dashboard',
      text: 'Expediente digital'
    },
    {
      name: 'Mi perfil',
      icon: <UilUser/>,
      route: '/datos-personales',
      text: 'Accede a tu perfil para más información'
      },
    // {
    //   name: 'Resultados',
    //   icon: <UilMonitorHeartRate/>,
    //   route: '/resultados'
    // },
    {
      name: 'Estudios',
      icon: <UilClipboardAlt/>,
      text: 'Busca los estudios necesarios'
    },
    {
      name: 'Mi historial médico',
      icon: <UilFileMedicalAlt/>,
      route: '/historial-medico',
      text: 'Localiza tu historial médico'
    }
  ],
  homeSections: [
    {
      name: 'COVID-19',
      route: '/covid-19'
    },
    {
      name: 'Sucursales',
      route: '/sucursales'
    },
    {
      name: 'Médicos',
      route: '/medicos'
    },
  ],
  studiesAndServices: [
    {
      name: 'COVID-19',
      route: '/covid-19'
    },
    {
      name: 'Toma de biopsia',
      route: '/toma-biopsia'
    },
    {
      name: 'Nutrición',
      route: '/nutricion'
    },
    {
      name: 'Laboratorio clínico',
      route: '/laboratorio-clinico'
    },
    {
      name: 'Servicios genéticos',
      route: '/servicios-geneticos'
    },
    {
      name: 'Patología quirúrgica',
      route: '/patologia-quirurgica'
    },
    {
      name: 'Ultrasonido',
      route: '/ultrasonido'
    },
    {
      name: 'Biología molecular',
      route: '/molecular'
    },
    {
      name: 'Rayos X',
      route: '/rayosx'
    },

  ]
};