import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ImgPalmasPlaza from "../../assets/branches/branch/bg_palmas.jpg";

const ImageMaps = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fbranches%2Fimg%2FMapa_google.png?alt=media&token=680e0fcd-bafb-4f08-816f-1abe214f59be'
const Card = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  padding: 25px 17px 0px 17px;

  @media screen and (min-width: 768px) {
    max-width: 999px;
    background: linear-gradient(
    79deg,
    #5097e3 -41.6%,
    #0cac8c 26.14%,
    #30df99 84.04%,
    #cef719 107.01%,
    #f5ec1d 132.38%
  );
  transform: translate(-50%, -50%);
  padding: 40px 0px 40px 0px;

  }
`;
const CardTitle = styled.div`
  text-align: center;
  padding: 200px 0px 20px 0px;
  @media screen and (min-width: 768px) {
  padding: 0px 0px 0px 0px;

  }
`;
const Title = styled.h2`
  color: #f3ffff;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 143.75% */
  letter-spacing: 0.16px;
  @media screen and (min-width: 768px) {
  font-size: 18px;

  }
`;
const Subtitle = styled.h1`
  color: #f3ffff;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (min-width: 768px) {
    font-size: 30px;
  }

`;

const TextBold = styled.p`
    color: #F3FFFF;
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media screen and (min-width: 768px) {
    font-size: 17px;

    }
    
`

const TextLight = styled.p`
    color: #F3FFFF;
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (min-width: 768px) {
    font-size: 17px;

    }
`

const NumberPhone = styled.span`
    color: #F3FFFF;
    font-family: "IBM Plex Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
`

const LinkGoogleMaps = styled.a`
    color: #F3FFFF;
    text-align: right;
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    transition: all .3s ease-in;
      &:hover{
        color: #F3FFFF;
        opacity: .9;
      }
    @media screen and (min-width: 768px) {
    font-size: 17px;
    }
`

const ContainerMain = styled.div`
  position: relative;
`;

const ImagePalmasPlaza = styled.div`
  width: 100%;
  height: 535px;
  height: 535px;
  background: linear-gradient(
    79deg,
    #5097e3 -41.6%,
    #0cac8c 26.14%,
    #30df99 84.04%,
    #cef719 107.01%,
    #f5ec1d 132.38%
  );

  @media screen and (min-width: 768px) {
  background: url(${ImgPalmasPlaza});
  background-position: center;
  background-size: cover;
  height: 426px;
  }
`;

const ImageGoogleMaps = styled.div`
  width: 100%;
  background: url(${ImageMaps});
  height: 200px;
  background-position: center;
  background-size: cover;

  @media screen and (min-width: 768px) {
  height: 200px;
  }
`;

const ImgBranch = styled.img`
    border: 5px solid #F3FFFF;
    border-radius: 13px;
    box-shadow: -1px 10px 5px 0px rgba(0,0,0,0.41);
    -webkit-box-shadow: -1px 10px 5px 0px rgba(0,0,0,0.41);
    -moz-box-shadow: -1px 10px 5px 0px rgba(0,0,0,0.41);
    @media screen and (max-width: 350px) {  
        width: 200px !important;
    }
`

export const Hero = ({dataBranch}) => {
  const { name, phone, imgBranch, location, urlLocation, schedules } = dataBranch;
  return (
    <ContainerMain>
      <Card>
        <CardTitle>
          <Title>Sucursales </Title>
          <Subtitle className="mb-4">{name}</Subtitle>
        </CardTitle>
        <Row className="flex-column-reverse flex-md-row justify-content-evenly align-items-center">
          <Col md={4} className="text-center mt-4 mt-md-0">
            <ImgBranch src={imgBranch} alt="plaza_palmas" loading='lazy' style={{ width: "283px" }} />
          </Col>
          <Col md={8} className=" text-left">
            <Row>
              <Col className=' m-0 p-0' xs={12} md={6}>
                {
                  schedules && schedules.length > 0 && (
                    <TextBold>Horario de atención <br />  <TextLight>Lunes a viernes de {schedules[0].startTime} a {schedules[0].endTime} h. <br /> Sábados de {schedules[1].startTime} a {schedules[1].endTime} h. </TextLight></TextBold>
                  )
                }
                {
                  name == 'Palmas plaza: Local 20' && (
                     <TextBold>Laboratorio clínico <br />  <TextLight>Lunes a viernes de 7:00 a 15:00 h. <br /> Sábados de 7:00 a 13:00 h. </TextLight></TextBold>
                  ) 
                }
                {
                  name == 'Torres médicas' && (
                     <TextBold>Rayos X <br />  <TextLight>Lunes a viernes de 10:00 a 18:00 h. <br /> Sábados de 7:00 a 13:00 h. </TextLight></TextBold>
                  ) 
                }
              </Col>
              <Col className="mb-4 m-0 p-0 text-left" xs={12} md={6}>
                <NumberPhone>{phone}</NumberPhone>
              </Col>
            </Row>
            <div>
              <TextBold>
                Ubicación <br />
                <TextLight>
                {location}
              </TextLight>
              </TextBold>
            </div>
            <div>
              <LinkGoogleMaps href={urlLocation} target="_blank">Abrir dirección en Google maps</LinkGoogleMaps>
            </div>
          </Col>
        </Row>
      </Card>
      <ImagePalmasPlaza></ImagePalmasPlaza>
      <ImageGoogleMaps></ImageGoogleMaps>
    </ContainerMain>
  );
};