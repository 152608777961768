import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Drawer } from "antd";
import { Row, Col, Modal, Button } from "react-bootstrap";
import {
  UilArrowRight,
  UilClipboardAlt,
  UilTimes,
  UilSearch,
  UilTrashAlt,
} from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { ListItemCart } from "./listItemCart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getTotalPrice,
  removeItemFromCart,
  setTotalPrice,
} from "../../actions/shoppingCart";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { updateCartDB } from "../../helpers/api";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { UIDsHome } from "../../helpers/cart";

const StyledDrawer = styled(Drawer)`
  .ant-drawer-title {
    font-weight: 800;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: ${(props) => props.mainColor};
  }
  .ant-drawer-close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${(props) => props.mainColor};
  }
`;

const Total = styled.h5`
  color: #6a6a6a;
  font-weight: 800;
  font-size: 15px;
  margin-top: 16px;
  position: relative;
  span {
    position: absolute;
    font-weight: 500;
    right: 0px;
    color: ${(props) => props.mainColor};
    text-align: right;
  }
  #black {
    color: #6a6a6a;
  }
`;

const WhiteButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: none;
  color: ${(props) => props.mainColor};
  border: 2px solid ${(props) => props.mainColor};
  border-radius: 16px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;

const WhiteButton1 = styled.button`
  width: 100%;
  //margin-left: 20%;
  height: 40px;
  background: none;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  margin-top: -5px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;

const BorderButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: none;
  color: ${(props) => props.mainColor};
  border: 2px solid ${(props) => props.mainColor};
  border-radius: 16px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;

const OrangeButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: ${(props) =>
    props.mainColor === "#4D99E5"
      ? "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)"};
  border-radius: 16px;
  border: 2px solid
    ${(props) => (props.mainColor === "#4D99E5" ? "#fff" : "#30DF99")};
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-top: 6px;
  margin-top: 14px;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const OrButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: none;
  color: ${(props) => props.mainColor};
  border: 2px solid ${(props) => props.mainColor};
  font-size: 16px;
  padding: 0;
  border-radius: 16px;
  font-weight: 800;
  margin-top: 6px;
  margin-top: 14px;
  margin-bottom: 24px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const BlueButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: ${(props) =>
    props.mainColor === "#4D99E5"
      ? "linear-gradient(84.3deg, #759ABD 15.24%, #97C6C0 97.95%)"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)"};
  border-radius: 16px;
  border: 2px solid
    ${(props) => (props.mainColor === "#4D99E5" ? "#fff" : "#30DF99")};
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const Bottom = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0px;
  width: 87%;
  padding-top: 0px;
  @media only screen and (max-width: 1900px) {
    //bottom: 100px;
  }
  p {
    text-align: center;
    //margin-botton: 24px;
    .disabled {
      pointer-events: none;
      color: #c4c4c4;
    }
  }
  #genOrder {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: ${(props) => props.mainColor};
    text-decoration: underline;
    cursor: pointer;
  }
  #noItems {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #a6a6a6;
    span {
      font-weight: 800;
    }
  }
`;

const ListDiv = styled.div`
  overflow-y: auto;
  padding-right: 7px;
  margin-right: -5px;
  max-height: 50vh;
  @media only screen and (max-width: 1600px) {
    max-height: 40vh;
  }
  @media only screen and (max-width: 1400px) {
    max-height: 40vh;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const CardDiv = styled.div`
  padding: 10px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
  border-radius: 10px;
  border: 2px solid transparent;
  overflow-y: auto;
  margin-bottom: 10px;
  margin-right: -5px;
  max-height: 40vh;
`;
const EmptyCart = styled.div`
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #474a4b;
    margin-bottom: 40px;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.mainColor};
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: ${(props) => props.mainColor};
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: ${(props) => props.mainColor};
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
    background: #fff;
  }
`;

export const Cart = (props) => {
  const { items, totalPrice } = useSelector((state) => state.shoppingCart);
  const uid = useSelector((state) => state.auth.uid);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const [totalSucursal, setTotalSucursal] = useState();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  const [showCallModal, setShowCallModal] = useState(false);
  var isOrder = urlParams.get("doctor");
  const location = useLocation();
  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker("Cart");
  let isMobile = width <= 768;
  const mainColor =
    PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";

  useEffect(() => {
    getTotal();
  }, [items]);

  const getTotal = () => {
    const pricesArray = items?.map((item) => item.oldPrice);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setTotalSucursal(final);
  };

  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    console.log(currentItem);
    if (currentItem.howMany > 1) {
      console.log("IF");
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      console.log("DATA: ", data);
    } else {
      console.log("else 3");
    }
    // console.log("get total 4: ", data)
    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  const vaciarCarrito = () => {
    // Eliminar todos los elementos del carrito
    dispatch(removeItemFromCart([])); // Pasar un array vacío elimina todos los elementos
    // Actualizar el precio total a cero
    dispatch(setTotalPrice(0));
    // Actualizar el carrito en la base de datos
    dispatch(updateCartDB([], 0)); // Pasar un array vacío y cero como precio
  };

  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para:` +
      items.map((study) => " " + study.name);
  };

  const someNotAvailableOnline = () => {
    return items.some(
      (study) =>
        study.categoria === 10 ||
        study.categoria === 8 ||
        study.ventaOnline === 0
    );
  };

  const handleOrder = () => {
    const patientInfo = JSON.parse(localStorage?.getItem("patientInfo"));

    gaEventTracker("carrito-generar-order");
    if (window.location.pathname === "/checkout" && !isOrder) {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
      window.location.reload();
    } else {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
    }
    props.onClose();
  };

  return (
    <StyledDrawer
      headerStyle={{
        fontFamily: "IBM Plex Sans",
        background: "#f5fffb",
      }}
      title="Estudios agregados"
      placement="right"
      closeIcon={
        <UilArrowRight
          style={{
            position: "initial",
            marginLeft: width < 500 ? "-10px" : "0px",
          }}
          color={"#fff"}
          size={30}
        />
      }
      onClose={props.onClose}
      open={props.visible}
      mainColor={mainColor}
    >
      {items?.length > 0 ? (
        <div style={{ position: "relative", paddingBottom: "150px" }}>
          {isMobile ? (
            <>
              <Row>
                <Col md={6}>
                  {isDoctor ? (
                    <>
                      <WhiteButton1
                        style={{
                          color: "#fff",
                          border: "1px solid #5097E3",
                          background: "#5097E3",
                        }}
                        onClick={() => {
                          props.onClose();
                          props.openSearcher();
                        }}
                      >
                        <UilSearch size={20} />
                        Buscar estudios
                      </WhiteButton1>
                    </>
                  ) : (
                    <>
                      <WhiteButton1
                        style={{
                          color: "#fff",
                          border: "1px solid #30B196",
                          background: "#30B196",
                        }}
                        onClick={() => {
                          props.onClose();
                          props.openSearcher();
                        }}
                      >
                        <UilSearch size={20} />
                        Buscar estudios
                      </WhiteButton1>
                    </>
                  )}
                  <br />
                  <br />
                  {isDoctor ? (
                    <WhiteButton1
                      style={{
                        color: "#5097E3",
                        border: "2px solid #5097E3",
                      }}
                      onClick={vaciarCarrito}
                    >
                      <UilTrashAlt size={20} />
                      Vaciar carrito
                    </WhiteButton1>
                  ) : (
                    <WhiteButton1
                      style={{
                        color: "rgb(0, 172, 144)",
                        border: "2px solid rgb(0, 172, 144)",
                      }}
                      onClick={vaciarCarrito}
                    >
                      <UilTrashAlt size={20} />
                      Vaciar carrito
                    </WhiteButton1>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <Col lg={6} style={{ padding: "2px" }}>
                  {isDoctor ? (
                    <WhiteButton1
                      style={{
                        color: "#fff",
                        border: "1px solid #5097E3",
                        background: "#5097E3",
                      }}
                      onClick={() => {
                        props.onClose();
                        props.openSearcher();
                      }}
                    >
                      <UilSearch size={20} />
                      Buscar estudios
                    </WhiteButton1>
                  ) : (
                    <WhiteButton1
                      style={{
                        color: "#fff",
                        border: "1px solid #30B196",
                        background: "#30B196",
                      }}
                      onClick={() => {
                        props.onClose();
                        props.openSearcher();
                      }}
                    >
                      <UilSearch size={20} />
                      Buscar estudios
                    </WhiteButton1>
                  )}
                </Col>
                <Col lg={6} style={{ padding: "2px" }}>
                  {isDoctor ? (
                    <WhiteButton1
                      style={{
                        color: "#5097E3",
                        border: "2px solid #5097E3",
                      }}
                      onClick={vaciarCarrito}
                    >
                      <UilTrashAlt size={20} />
                      Vaciar carrito
                    </WhiteButton1>
                  ) : (
                    <WhiteButton1
                      style={{
                        color: "rgb(0, 172, 144)",
                        border: "2px solid rgb(0, 172, 144)",
                      }}
                      onClick={vaciarCarrito}
                    >
                      <UilTrashAlt size={20} />
                      Vaciar carrito
                    </WhiteButton1>
                  )}
                </Col>
              </div>
            </>
          )}

          <br />
          <ListDiv>
            {items.map((item) => (
              <CardDiv>
                <ListItemCart
                  key={item.id}
                  item={item}
                  mainColor={mainColor}
                  onDelete={handleRemoveItemFromCart}
                  idClienteLabopat={idClienteLabopat}
                />
              </CardDiv>
            ))}
          </ListDiv>
          <br />
        </div>
      ) : (
        <EmptyCart>
          <div id="empty">
            <UilClipboardAlt color="#A6A6A6" size={100} />
          </div>
          <p>No hay estudios agregados</p>
          <WhiteButton
            mainColor={mainColor}
            onClick={() => {
              props.onClose();
              props.openSearcher();
            }}
          >
            Buscar estudios
          </WhiteButton>
        </EmptyCart>
      )}

      <Bottom minColor={mainColor}>
        {items?.length > 0 ? (
          <>
            {idClienteLabopat && idClienteLabopat !== 0 ? (
              <>
                <Total
                  mainColor={mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"}
                >
                  Total a pagar: <span>{currency(totalSucursal)} MXN</span>
                </Total>
              </>
            ) : (
              <>
                {isDoctor ? (
                  <>
                    <Total style={{ fontWeight: "500" }}>
                      Total a pagar en sucursal:{" "}
                      <span
                        id="black"
                        style={{ textDecoration: "line-through" }}
                      >
                        {currency(totalSucursal)} MXN
                      </span>
                    </Total>
                    <BorderButton
                      onClick={() => {
                        if (someNotAvailableOnline()) {
                          setShowCallModal(true);
                        } else {
                          gaEventTracker("carrito-pago-sucursal");
                          window.location.href = "/checkout?sucursal=1";
                          props.onClose();
                        }
                      }}
                      mainColor={
                        mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                      }
                    >
                      Agendar cita
                    </BorderButton>
                    <Total style={{ color: "#4D99E5" }}>
                      Total a pagar en línea:{" "}
                      <span>{currency(totalPrice)} MXN</span>
                    </Total>
                    <OrButton
                      onClick={() => {
                        if (someNotAvailableOnline()) {
                          setShowCallModal(true);
                        } else {
                          gaEventTracker("carrito-pago-online");
                          window.location.href = "/checkout";
                          props.onClose();
                        }
                      }}
                      mainColor={
                        mainColor === "#4F90BA" ? "#4D99E5" : "#30DF99"
                      }
                    >
                      Pagar ahora
                    </OrButton>
                  </>
                ) : (
                  <>
                    <Total style={{ fontWeight: "500" }}>
                      Total a pagar en sucursal:{" "}
                      <span id="black">{currency(totalSucursal)} MXN</span>
                    </Total>
                    <BorderButton
                      onClick={() => {
                        if (someNotAvailableOnline()) {
                          setShowCallModal(true);
                        } else {
                          gaEventTracker("carrito-pago-sucursal");
                          window.location.href = "/checkout?sucursal=1";
                          props.onClose();
                        }
                      }}
                      mainColor={
                        mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                      }
                    >
                      Agendar cita
                    </BorderButton>
                    <Total style={{ color: "#4D99E5" }}>
                      Total a pagar en línea:{" "}
                      <span>{currency(totalPrice)} MXN</span>
                    </Total>
                    <OrangeButton
                      onClick={() => {
                        if (someNotAvailableOnline()) {
                          setShowCallModal(true);
                        } else {
                          gaEventTracker("carrito-pago-online");
                          window.location.href = "/checkout";
                          props.onClose();
                        }
                      }}
                      mainColor={
                        mainColor === "#4F90BA" ? "#4D99E5" : "#30DF99"
                      }
                    >
                      Pagar ahora
                    </OrangeButton>
                  </>
                )}
              </>
            )}
            {isDoctor && (
              <>
                {UIDsHome.includes(uid) ? (
                  <OrangeButton
                    id="genOrder"
                    onClick={handleOrder}
                    mainColor={mainColor === "#4F90BA" ? "#4D99E5" : "#30DF99"}
                  >
                    Generar cotización
                  </OrangeButton>
                ) : (
                  <BlueButton
                    id="genOrder"
                    onClick={handleOrder}
                    mainColor={mainColor === "#4F90BA" ? "#4D99E5" : "#4D99E5"}
                  >
                    Genera una orden aquí
                  </BlueButton>
                )}
              </>
            )}
          </>
        ) : (
          isDoctor && (
            <p id="noItems">
              Para generar una orden{" "}
              <span>necesitas agregar al menos 1 estudio.</span>
            </p>
          )
        )}
      </Bottom>
      <StyledModal
        show={showCallModal}
        onHide={() => setShowCallModal(false)}
        mainColor={mainColor}
      >
        <div onClick={() => setShowCallModal(false)}>
          <UilTimes color="#fff" style={{ cursor: "pointer" }} />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>
            Estudios no disponibles para reserva en linea
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Los siguientes estudios solo se pueden agendar mediante llamada:
          </p>
          <ul>
            {items.map((study) => {
              if (
                study.categoria === 10 ||
                study.categoria === 8 ||
                study.ventaOnline === 0
              ) {
                return <li>{study.name}</li>;
              }
            })}
          </ul>
          <ContinueButton
            onClick={() => callToSchedule()}
            mainColor={mainColor}
          >
            Llamar para agendar
          </ContinueButton>
        </Modal.Body>
      </StyledModal>
    </StyledDrawer>
  );
};
