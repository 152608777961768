import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Background = styled.div`
    background: linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%);
`;

const ContainerDiv = styled.section`
    position: relative;
    width: 100%;
    min-height: 100vh;
`;

const SpaceHolder = styled.div`
    position: relative;
    width: 100%;
`;

const Sticky = styled.div`
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
`;

const Horizontal = styled.div`
    position: absolute;
    height: 100%;
    margin-top: -12vh;
    will-change: transform;
`;

const Cards = styled.section`
    position: relative;
    height: 100%;
    padding: 0 0 0 70px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: -500px;
`;

const SampleCard = styled.div`
    position: relative;
    height: 100vh;
    width: 90vw;
    background-color: none;
    // background-color: #111f301A;
    margin-right: 75px;
    padding-top: 300px;
    flex-shrink: 0;
    @media only screen and (max-width: 768px) {
        padding-top: 270px;
    }
    .text-col {
        display: flex;
        position: relative;
        padding-left: 80px;
        @media only screen and (max-width: 768px) {
            padding-left: 0;
        }
        h1 {
            font-family: 'IBM Plex Sans';
            font-weight: 700;
            font-size: 150px;
            line-height: 80%;
            color: #CEF719;
            margin-right: 25px;
            @media only screen and (max-width: 768px) {
                font-size: 60px;
                margin-top: 5px;
                margin-right: 18px;
            }
        }
        p {
            font-family: 'IBM Plex Sans';
            font-weight: 400;
            font-size: 35px;
            line-height: 50px;
            color: #FFFFFF;
            max-width: 381px;
            span {
                font-weight: 700;
            }
            @media only screen and (max-width: 768px) {
                font-size: 23px;
                line-height: 30px;
                max-width: 245px;
            }
        }
    }
    img {
        max-width: 60%;
        max-height: 85vh;
        height: auto;
        position: absolute;
        bottom: 0;
        right: 20px;
        @media only screen and (max-width: 768px) {
            max-width: 120%;
            max-height: 60vh;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
    a {
        color: #FFFFFF;
        text-decoration: underline;
        font-weight: 600;
    }
`;

const RedDiv = styled.div`
    position: sticky;
    top: 150px;
    left: 150px;
    background: none;
    // background: #FF00001A;
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 27px;
    line-height: 130%;
    color: #FFFFFF;
    @media only screen and (max-width: 768px) {
        top: 120px;
        left: 50px;
    }
    p {
        font-style: italic;
        margin-bottom: 10px;
        @media only screen and (max-width: 768px) {
            font-size: 23px;
            margin-bottom: 0;
        }
    }
    h3 {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 44px;
        line-height: 130%;
        @media only screen and (max-width: 768px) {
            font-size: 32px;
        }
    }
`;

export const HorizontalScrollDoctors = () => {
    // Ref for space holder
    const spaceHolderRef = useRef(null);

    // Ref for width
    const horizontalRef = useRef(null);

    useEffect(() => {

        // Get height dynamically
        const calcDynamicHeight = () => {
            const vw = window.innerWidth;
            const vh = window.innerHeight;
            const objectWidth = horizontalRef.current.scrollWidth;
            return objectWidth - vw + vh;
        };

        // When scroll (vertical) make horizontal scroll
        const handleScroll = () => {
            const sticky = document.querySelector('.sticky');
            horizontalRef.current.style.transform = `translateX(-${sticky.offsetTop}px)`;
        };

        // When resize change the height ofthe space holder
        const handleResize = () => {
            spaceHolderRef.current.style.height = `${calcDynamicHeight()}px`;
        };

        //  Listeners for scroll and resize
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Change space holder height
        spaceHolderRef.current.style.height = `${calcDynamicHeight()}px`;

        // Remove listeners
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Background>
            <ContainerDiv>
                <SpaceHolder ref={spaceHolderRef}>
                <Sticky className='sticky'>
                    {/* Fixed div with title */}
                    <RedDiv data-aos='fade-up'>
                        <p>¡Inscríbete al programa!</p>
                        <h3>¿Cómo funciona?</h3>
                    </RedDiv>
                    {/* Horizontal scroll div */}
                    <Horizontal ref={horizontalRef}>
                        {/* Step cards */}
                        <Cards>
                            <SampleCard data-aos='fade-up'>
                                <Row>
                                    <Col xs={12} md={10} lg={10} xl={6} className='text-col'>
                                        <h1>1</h1>
                                        <p><span><a href='/registro'>Crea tu cuenta</a></span><br/>fácil y rápido.</p>
                                    </Col>
                                </Row>
                                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdoctorsSlider%2Fstep1D.png?alt=media&token=e3feb0d4-24d0-4ef2-a7b2-4ee529b9dda9" alt="paso 1"/>
                            </SampleCard>
                            <SampleCard data-aos='fade-up'>
                                <Row>
                                    <Col xs={12} md={10} lg={10} xl={6} className='text-col'>
                                        <h1>2</h1>
                                        <p><span>Personaliza</span> tu formato de solicitud.</p>
                                    </Col>
                                </Row>
                                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdoctorsSlider%2Fstep2D.png?alt=media&token=230b0b18-39ea-453d-a9bb-f51c897aea23" alt="paso 2"/>
                            </SampleCard>
                            <SampleCard>
                                <Row>
                                    <Col xs={12} md={10} lg={10} xl={6} className='text-col'>
                                        <h1>3</h1>
                                        <p>Realiza la solicitud de <span>estudios de tus pacientes desde la plataforma.</span></p>
                                    </Col>
                                </Row>
                                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdoctorsSlider%2Fstep3D.png?alt=media&token=87295e2e-60a3-49da-ad97-6093ae47da55" alt="paso 3"/>
                            </SampleCard>
                            <SampleCard>
                                <Row>
                                    <Col xs={12} md={10} lg={10} xl={6} className='text-col'>
                                        <h1>4</h1>
                                        <p><span>Da grandes beneficios a tus pacientes </span>con los precios preferenciales que tendrás.</p>
                                    </Col>
                                </Row>
                                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdoctorsSlider%2Fstep4D.png?alt=media&token=1611438e-8ab0-440e-97f2-5526bc605114" alt="paso 4"/>
                            </SampleCard>
                        </Cards>
                    </Horizontal>
                </Sticky>
                </SpaceHolder>
            </ContainerDiv>
        </Background>
    );
};