import React, { useState } from "react";
import styled from "styled-components";
import { UilAngleRight, UilArrowLeft, UilLocationPinAlt, UilLocationPoint } from "@iconscout/react-unicons";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { handleAddItemToCart } from "../../actions/shoppingCart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import { Doubts } from "../doubts/doubts";
import { PrivRoutes } from '../../routes/routes';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { studyInCart } from "../../helpers/cart";
import handleAlert from "../../helpers/handleAlert";
import { PINK_OCTOBER } from "../../helpers/colors";

const branches = [
  {
    id: 4,
    baseId: 26,
    name: 'Palmas Plaza Drive Thru',
    address:
      "Estacionamiento de Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.",
    mapURL: "https://goo.gl/maps/8DMZYY7ERixC7xGR6",
    mapsId: "",
    coordinates: ["19.0267687", "-98.2311346"],
  },
  {
    id: 9,
    baseId: 5,
    name: 'Palmas Plaza Local 20',
    sunday: false,
    address:
      "Local 20, Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.",
    mapsId: "",
    mapURL: "https://goo.gl/maps/8DMZYY7ERixC7xGR6",
    coordinates: ["19.0267687", "-98.2311346"],
  },
  {
    id: 3,
    baseId: 13,
    name: "Torres Médicas",
    address:
      "Torres Médicas Angelópolis 2. Anillo Perif. Ecológico 3507, local 15 y 16 Tlaxcalancingo, Puebla 72821",
    mapURL: "https://goo.gl/maps/GKRKLQSnVXQPgt8o7",
    mapsId: "",
    coordinates: ["19.02051237624928", "-98.26456209191247"],
  },
  {
    id: 7,
    baseId: 21,
    name: "La Paz",
    address:
      "Plaza Pabellón R&M. Av. Rosendo Márquez 4122, local 5 Colonia La Paz, 72160 Puebla, Pue.",
    mapURL: "https://goo.gl/maps/nfcNL45qi89A5Lhw7",
    mapsId: "",
    coordinates: ["19.0522198", "-98.2362645"],
  },
  /*{
    id: 2,
    baseId: 11,
    name: 'Lomas de Angelópolis',
    address:
      'Torre Elementa. Blvd. América 308, local 3; Lomas de Angelópolis, 72228 San Andrés Cholula, Pue.',
    mapURL: 'https://goo.gl/maps/wPfV3oCtYEcmmgxVA',
    mapsId: '',
    coordinates: ['18.9976678', '-98.2818113'],
  },*/
  {
    id: 6,
    baseId: 20,
    name: "Zavaleta",
    address:
      "Plaza Bosques De Zavaleta, Calz Zavaleta 313-A, local 14 Santa Cruz Buenavista, 72150 Puebla, Pue.",
    mapURL: "https://goo.gl/maps/S7DapoFhy9F8zgNM9",
    mapsId: "",
    coordinates: ["19.066824", "-98.2503983"],
  },
];

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-right: 16px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 24px;
  border-radius: 12px;
  font-size: 11px;
`;
//color: #00ad90;
const Title = styled.h1`
  font-weight: 500;
  font-size: 38px;
  line-height: 50px;
  text-align: left;
  color: ${({pink,state}) => pink ? PINK_OCTOBER : useSelector((state) => state.user?.data?.data?.profile?.type) === 1 ? '#3BD1E6' : '#30DF99'};
  margin-top: 24px;
  margin-bottom: 24px;
  @media only screen and (max-width: 768px) {
    margin-top: 5px;
    font-weight: 500;
    line-height: 125%;
    font-size: 22px;
  }
`;

const Subtitle = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
`;

const RegularP = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #717171;
  margin-bottom: 10px;
`;

const BranchItem = styled(Col)`
  margin-top: 20px;
  > h1 {
    display: inline;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
  > p {
    font-weight: 500;
    font-size: 15px;
    color: #717171;
    margin-bottom: 2px;
  }
  > a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #c7c7c7;
    margin-top: 8px;
    margin-bottom: 24px;
    text-decoration: underline;
  }
`;

const WhiteButton = styled.button`
  width: 206px;
  height: 44px;
  background: none;
  color: ${({pink}) => pink ? PINK_OCTOBER : '#0CAC8C'};
  border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : '#0CAC8C'};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  @media only screen and (max-width: 600px) {
    width: 205px;
    margin-bottom: 10px;
  }
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${({pink}) => pink ? PINK_OCTOBER : '#0CAC8C'};
  }
`;

const BlButton = styled.button`
  width: 206px;
  height: 44px;
  background: none;
  color: ${({pink}) => pink ? PINK_OCTOBER : '#5097E3'};
  border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : '#5097E3'};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  @media only screen and (max-width: 600px) {
    width: 205px;
    margin-bottom: 10px;
  }
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${({pink}) => pink ? PINK_OCTOBER : '#5097E3'};
  }
`;

const OrangeButton = styled.button`
width: 206px;
height: 44px;
background: ${({pink}) => pink ? PINK_OCTOBER : 'linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)'};
border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
border-radius: 10px;
font-size: 16px;
color: #fff;
font-weight: 600;
transition: all 0.4s ease;
:hover {
  color: ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
  transition: all 0.4s ease;
  background: #fff;
  border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
  @media only screen and (max-width: 576px) {
    max-width: 146px;
  }
`;

const BlueButton = styled.button`
width: 206px;
height: 44px;
background: ${({pink}) => pink ? PINK_OCTOBER : 'linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)'};
border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
border-radius: 10px;
font-size: 16px;
color: #fff;
font-weight: 600;
transition: all 0.4s ease;
:hover {
  color: ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
  transition: all 0.4s ease;
  background: #fff;
  border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
  @media only screen and (max-width: 576px) {
    max-width: 146px;
  }
`;

const BluButton = styled.button`
width: 206px;
height: 44px;
background: ${({pink}) => pink ? PINK_OCTOBER : 'linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)'};
border: none;
border-radius: 10px;
font-size: 16px;
color: #fff;
font-weight: 600;
transition: all 0.4s ease;
:hover {
  color: ${({pink}) => pink ? PINK_OCTOBER : '#3BD1E6'};
  transition: all 0.4s ease;
  background: #fff;
  border: 1px solid ${({pink}) => pink ? PINK_OCTOBER : '#3BD1E6'};
  @media only screen and (max-width: 576px) {
    max-width: 146px;
  }
`;

const Prices = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: left;
  p {
    width: auto;
    font-family: 'IBM Plex Sans';
    padding-left: 0px;
    padding-right: 0px;
    @media only screen and (max-width: 768px) {
      font-size: 16px !important;
    }
  }
  #linea {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.03em;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  #descuento {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-inline: auto;
    padding-left: 150px;
    letter-spacing: -0.03em;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 8px;
      padding-left: 0px;
    }
  }
  #sucursal {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.03em;
    color: #6a6a6a;
  }
  #pipe {
    margin-top: -5px;
  }
`;

const BackBtn = styled(Button)`
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: ${(props) =>
    props.isDoctor ? "#4D99E5" : "#0CAC8C"};
  font-weight: 500;
  min-width: 100px;
  font-size: 15px;
  padding-right: 10px;
  padding-left: 0px;
  @media only screen and (min-width: 768px) {
    margin-right: 24px;
  }
`;

const Category = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 5px;
  img {
    width: 75px;
    @media only screen and (max-width: 600px) {
      width: 62px;
    }
  }
  p {
    color: #969696;
    display: inline;
    margin-bottom: 0px;
    text-align: center;
  }
  #cat {
    font-weight: 500;
    font-size: 13px;
    line-height: 115%;
  }
  #sign {
    font-size: 13px;
  }
  #name {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    text-align: left;
  }
`;

const Info = styled(Col)`
  background: #f2f7f9;
  border-radius: 16px;
  padding: 16px 4px 10px 4px;
  margin-top: 40px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 24px;
    margin-top: 0px;
  }
  div :nth-last-child(1) p {
    margin-bottom: 0px;
  }
`;

const FloatingTest = styled.div`
  position: ${(props) => (props.limit === 1 ? "absolute" : "sticky")};
  bottom: 20px;
  left: 0;
  width: 100%;
`;

const FloatingTestContainer = styled(Container)`
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
`;

const FloatingRow = styled(Row)`
  padding: 24px 40px;
  @media only screen and (max-width: 768px) {
    padding: 16px 24px;
  }
`;

const FloatingTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin: 0;
  @media only screen and (max-width: 768px) {
    margin-bottom: 24px;
    text-align: center;
  }
`;


const MainContainer = styled(Container)`
  position: relative;
  padding: 0 20px;
  padding-bottom: 110px;
  transition: all 300ms;
  @media only screen and (max-width: 1400px) {
    padding-bottom: 114px;
  }
  @media only screen and (max-width: 1200px) {
    padding-bottom: 166px;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 188px;
  }
  @media only screen and (max-width: 383px) {
    padding-bottom: 255px;
  }
`;

export const StudyDetail = (props) => {
  const location = useLocation();
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const mainColor = PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90"
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();
  const [limit, setLimit] = useState(false);
  const { studyId } = useParams();
  const [local, setLocal] = useState(false);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });
  const { width } = useWindowDimensions();
  let isMobile = width <= 768;

  const getInfo = async () => {
    try {
      let request = axios.get(
        `https://lpqsystem.com/obtener-estudio/${studyId}/${
          idClienteLabopat ? `${idClienteLabopat}` : "0"
        }`
      );
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.promo_price,
        oldPrice: response.data.normal_price,
      });
      if (response.data.categoria == 6) {
        setLocal(true);
      }
      if (response.data.categoria == 9) {
        setLocal(true);
      }
    } catch (error) {
      console.log(error);
      //window.location.href = "/not-found";
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const addStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(study));
    }
  };

  const payStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(study));
      history.push("/checkout");
    }
  };

  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para ` +
      props.data.title;
  };


  //console.log(branches)
  useEffect(() => {
    getInfo();
  }, [studyId]);

  const footerObserver = new IntersectionObserver((entries) => {
    if(entries[0].intersectionRatio <= 0) {
      setLimit(false);
    } else {
      setLimit(true);
    }
  });

  useEffect(() => {
    footerObserver.observe(document.getElementById('footer'));
  },[]);

  return (
    <>
      {study.detalleCategoria && (
        <div>
          <div className="container" style={{ paddingTop: "15px" }}>
            <Top style={{ marginTop: "0px" }}>
              {study.tag ? (
                <Tag
                  style={{
                    backgroundColor: "#FFE769",
                    color: "#000000",
                    marginTop: "6px",
                  }}
                >
                  Etiqueta
                  {study.tag.content}
                </Tag>
              ) : null}
            </Top>
            <Category>
              {isDoctor ? (
                <BackBtn variant="light" onClick={() => window.history.back()}>
                <UilArrowLeft
                  color="#3BD1E6"
                  size={isMobile ? "22" : "26"}
                  style={{ display: "inline" }}
                />
                Regresar
              </BackBtn>
              ) : (
                <BackBtn variant="light" onClick={() => window.history.back()}>
                <UilArrowLeft
                  color="#00AD90"
                  size={isMobile ? "22" : "26"}
                  style={{ display: "inline" }}
                />
                Regresar
              </BackBtn>
              )}
              
              <p
                id="cat"
                onClick={() => history.push(`/${study.detalleCategoria.link}`)}
                style={{ cursor: "pointer" }}
              >
                {study.detalleCategoria.nombre}
              </p>
              <p id="sign">
                <UilAngleRight size={20} color="#000" />
              </p>
              <p id="name">{study.name}</p>
            </Category>
          </div>
        </div>
      )}
      {isDoctor ? (
        <div>
        <div
          className="container"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <MainContainer>
          <HelmetTitle
            title={
              study?.detalleCategoria?.nombre
                ? study?.name + " | " + study?.detalleCategoria?.nombre
                : "Estudio"
            }
            name="description"
            content="content"
          />
          <br />
          {!study.detalleCategoria && (
            <Top>
              <BackBtn variant="light" onClick={() => window.history.back()}>
                <UilArrowLeft color="#00AD90" size="26" />
                Regresar
              </BackBtn>
              {study.tag ? (
                <Tag
                  style={{
                    backgroundColor: "#FFE769",
                    color: "#000000",
                    marginTop: "6px",
                  }}
                >
                  Etiqueta
                  {study.tag.content}
                </Tag>
              ) : null}
            </Top>
          )}
          <Row>
            <Col
              xs={12}
              lg={7}
              style={{ paddingRight: width <= 992 ? "12px" : "60px" }}
            >
              <Title
                pink={study.ventaEspecial === 1}
              >
                {study.name}
              </Title>
              <Prices>
                {idClienteLabopat && idClienteLabopat !== "0" ? (
                  <p id="sucursal">
                    Precio preferencial {currency(study.normal_price)} MXN
                  </p>
                ) : (
                  <>
                  <p id="sucursal">
                        Pago en sucursal {currency(study.normal_price)} MXN
                      </p>
                      {!isMobile && <p id="pipe">&nbsp;&nbsp;|&nbsp;&nbsp;</p>}
                    {study.categoria !== 10 &&
                    study.categoria !== 8 &&
                    study.ventaOnline !== 0 ? (
                      <>
                      <p
                      id="linea"
                      style={{
                        color: study.ventaEspecial === 1 ? PINK_OCTOBER : "#4D99E5",
                      }}
                    >
                      Pago en línea {currency(study.promo_price)} MXN
                    </p>

                    <p
                      id="descuento"
                      style={{
                        color: study.ahorroEspecial === 1 ? "#000" : "#C4C4C4",
                      }}
                    >
                      Ahorras { currency(study.normal_price - study.promo_price)} MXN
                    </p>
                    </>
                    ) : null}
                  
                  </>
                )}
              </Prices>
              <Subtitle
                style={{
                  color: study.ventaEspecial === 1 ? PINK_OCTOBER : "#3BD1E6",
                }}
              >
                Descripción
              </Subtitle>
              <RegularP
                dangerouslySetInnerHTML={{ __html: study.description }}
              ></RegularP>
              {(study.categoria !== 10 && study.categoria !== 8 && study.ventaOnline!==0) || isDoctor ? (
              <Row className="justify-content-between" style={{ padding: '0 100px 10px 0' }}>
                <BlButton onClick={() => addStudy()} pink={study.ventaEspecial === 1}>
                  Agregar estudio
                </BlButton>
                <BluButton onClick={() => payStudy()} pink={study.ventaEspecial === 1}>Pagar ahora</BluButton>
              </Row>
              ): null
              }
            </Col>
            <Col xs={12} lg={5}>
              <Info>
                <Row className="justify-content-center">
                  {study.tEntrega && (
                    <>
                      <Subtitle>Tiempo de entrega</Subtitle>
                      <RegularP
                        dangerouslySetInnerHTML={{ __html: study.tEntrega }}
                      ></RegularP>
                    </>
                  )}
                  {study.muestra && (
                    <>
                      <Subtitle>Tipo de muestra</Subtitle>
                      <RegularP
                        dangerouslySetInnerHTML={{ __html: study.muestra }}
                      ></RegularP>
                    </>
                  )}
                  {study.indicaciones && (
                    <>
                      <Subtitle>Indicaciones</Subtitle>
                      <RegularP
                        dangerouslySetInnerHTML={{ __html: study.indicaciones }}
                      ></RegularP>
                    </>
                  )}
                  {study.info && (
                    <>
                      <Subtitle>Información</Subtitle>
                      <RegularP
                        dangerouslySetInnerHTML={{ __html: study.info }}
                      ></RegularP>
                    </>
                  )}
                </Row>
              </Info>
            </Col>
          </Row>
          {study.sucursales?.length !== 0 && (
            <Subtitle
              style={{
                color: study.ventaEspecial === 1 ? "#000" : "#3BD1E6",
                paddingLeft: "12px",
              }}
            >
              Sucursales
            </Subtitle>
          )}
          <Row className="d-flex justify-content-center">
            {study.sucursales?.map((sucursal) => {
              const branch = branches.find(
                (e) => e.baseId === sucursal?.sucursal
              );
              return (
                branch && (
                  <BranchItem key={branch.id} xs={12} sm={6} md={4} xl={3}>
                    <UilLocationPinAlt color="#3BD1E6"/>
                    <h1 style={{color:"#3BD1E6"}}> {branch.name}</h1>
                    <p>Lunes a viernes</p>
                    {local && <p>9:00 AM - 5:00 PM hrs.</p>}
                    {!local && (
                      <>
                        <p>7:00 AM - 3:00 PM hrs.</p>
                        <p>Sábado</p>
                        <p>7:00 AM - 1:00 PM hrs.</p>
                      </>
                    )}
                    <br />
                  </BranchItem>
                )
              );
            })}
          </Row>
          <Doubts />
          
            <FloatingTest limit={limit ? 1 : 0} id="floatingActions">
              <FloatingTestContainer>
                <FloatingRow className="align-items-center">
                  <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={6}
                    xxl={7}
                    className="ps-0"
                  >
                    <FloatingTitle>{study.name}</FloatingTitle>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={6}
                    xxl={5}
                    className="pe-0"
                  >
                    {(study.categoria !== 10 && study.categoria !== 8 && study.ventaOnline!==0) || isDoctor ? (
                    <Row className="p-0 justify-content-around">
                      <BlButton onClick={() => addStudy()} pink={study.ventaEspecial === 1}>
                        Agregar estudio
                      </BlButton>
                      <BluButton onClick={() => payStudy()} pink={study.ventaEspecial === 1}>
                        Pagar ahora
                      </BluButton>
                    </Row>
                    ): (
                      <OrangeButton onClick={()=>callToSchedule()} pink={study.ventaEspecial === 1}>Llamar {!isMobile && "para agendar"}</OrangeButton>
                    )}
                  </Col>
                </FloatingRow>
              </FloatingTestContainer>
            </FloatingTest>
          </MainContainer>
        </div>
      </div>
      ) : (
        <div>
        <div
          className="container"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <MainContainer>
          <HelmetTitle
            title={
              study?.detalleCategoria?.nombre
                ? study?.name + " | " + study?.detalleCategoria?.nombre
                : "Estudio"
            }
            name="description"
            content="content"
          />
          <br />
          {!study.detalleCategoria && (
            <Top>
              <BackBtn variant="light" onClick={() => window.history.back()}>
                <UilArrowLeft color="#00AD90" size="26" />
                Regresar
              </BackBtn>
              {study.tag ? (
                <Tag
                  style={{
                    backgroundColor: "#FFE769",
                    color: "#000000",
                    marginTop: "6px",
                  }}
                >
                  Etiqueta
                  {study.tag.content}
                </Tag>
              ) : null}
            </Top>
          )}
          <Row>
            <Col
              xs={12}
              lg={7}
              style={{ paddingRight: width <= 992 ? "12px" : "60px" }}
            >
              <Title
                pink={study.ventaEspecial === 1}
                // style={{
                //   color: study.ventaEspecial === 1 ? "#000" : "#30DF99",
                // }}
              >
                {study.name}
              </Title>
              <Prices>
                {idClienteLabopat && idClienteLabopat !== "0" ? (
                  <p id="sucursal">
                    Precio preferencial {currency(study.normal_price)} MXN
                  </p>
                ) : (
                  <>
                  <p id="sucursal">
                        Pago en sucursal {currency(study.normal_price)} MXN
                      </p>
                      {!isMobile && <p id="pipe">&nbsp;&nbsp;|&nbsp;&nbsp;</p>}
                    {study.categoria !== 10 &&
                    study.categoria !== 8 &&
                    study.ventaOnline !== 0 ? (
                      <>
                      <p
                      id="linea"
                      style={{
                        color: study.ventaEspecial === 1 ? PINK_OCTOBER : "#0CAC8C",
                      }}
                    >
                      Pago en línea {currency(study.promo_price)} MXN
                    </p>
                    
                    { study.ventaEspecial === 1 && study.regular_price!==0 ?
                    <>
                    <p
                    id="sucursal"
                    style={{
                      color: "#C4C4C4",
                    }}
                  >
                    Precio regular { currency(study.regular_price)} MXN
                   </p>
                   {!isMobile && <p id="pipe">&nbsp;&nbsp;|&nbsp;&nbsp;</p>}
                    <p
                      id="sucursal"
                    >
                      Ahorras { currency(study.regular_price - study.promo_price)} MXN
                    </p>
                    </>
                    :
                    <p
                      id="descuento"
                      style={{
                        color: study.ahorroEspecial === 1 ? "#000" : "#C4C4C4",
                      }}
                    >
                      Ahorras { currency(study.normal_price - study.promo_price)} MXN
                    </p>
                    }
                    </>
                    ) : ( 
                       study.ventaEspecial === 1 && study.regular_price!==0 ?
                        <>
                        <p
                        id="sucursal"
                        style={{
                          color: "#C4C4C4",
                        }}
                      >
                        Precio regular { currency(study.regular_price)} MXN
                       </p>
                        <p
                          id="sucursal"
                        >
                          Ahorras { currency(study.regular_price - study.promo_price)} MXN
                        </p>
                        </>
                        :null 
                    )}
                  
                  </>
                )}
              </Prices>
              <Subtitle
                style={{
                  color: study.ventaEspecial === 1 ? PINK_OCTOBER : "#30DF99",
                }}
              >
                Descripción
              </Subtitle>
              <RegularP
                dangerouslySetInnerHTML={{ __html: study.description }}
              ></RegularP>
              {(study.categoria !== 10 && study.categoria !== 8 && study.ventaOnline!==0) || isDoctor ? (
              <Row className="justify-content-between" style={{ padding: '0 100px 10px 0' }}>
                <WhiteButton onClick={() => addStudy()} pink={study.ventaEspecial === 1}>
                  Agregar estudio
                </WhiteButton>
                <BlueButton onClick={() => payStudy()} pink={study.ventaEspecial === 1}>Pagar ahora</BlueButton>
              </Row>
              ): null
              }
            </Col>
            <Col xs={12} lg={5}>
              <Info>
                <Row className="justify-content-center">
                  {study.tEntrega && (
                    <>
                      <Subtitle>Tiempo de entrega</Subtitle>
                      <RegularP
                        dangerouslySetInnerHTML={{ __html: study.tEntrega }}
                      ></RegularP>
                    </>
                  )}
                  {study.muestra && (
                    <>
                      <Subtitle>Tipo de muestra</Subtitle>
                      <RegularP
                        dangerouslySetInnerHTML={{ __html: study.muestra }}
                      ></RegularP>
                    </>
                  )}
                  {study.indicaciones && (
                    <>
                      <Subtitle>Indicaciones</Subtitle>
                      <RegularP
                        dangerouslySetInnerHTML={{ __html: study.indicaciones }}
                      ></RegularP>
                    </>
                  )}
                  {study.info && (
                    <>
                      <Subtitle>Información</Subtitle>
                      <RegularP
                        dangerouslySetInnerHTML={{ __html: study.info }}
                      ></RegularP>
                    </>
                  )}
                </Row>
              </Info>
            </Col>
          </Row>
          {study.sucursales?.length !== 0 && (
            <Subtitle
              style={{
                color: study.ventaEspecial === 1 ? "#000" : "#00AD90",
                paddingLeft: "12px",
              }}
            >
              Sucursales
            </Subtitle>
          )}
          <Row className="d-flex justify-content-center">
            {study.sucursales?.map((sucursal) => {
              const branch = branches.find(
                (e) => e.baseId === sucursal?.sucursal
              );
              return (
                branch && (
                  <BranchItem key={branch.id} xs={12} sm={6} md={4} xl={3}>
                    <UilLocationPoint size={25}/>
                    <h1> {branch.name}</h1>
                    <p>Lunes a viernes</p>
                    {local && <p>9:00 AM - 5:00 PM hrs.</p>}
                    {!local && (
                      <>
                        <p>7:00 AM - 3:00 PM hrs.</p>
                        <p>Sábado</p>
                        <p>7:00 AM - 1:00 PM hrs.</p>
                      </>
                    )}
                    <br />
                  </BranchItem>
                )
              );
            })}
          </Row>
          <Doubts />
          
            <FloatingTest limit={limit ? 1 : 0} id="floatingActions">
              <FloatingTestContainer>
                <FloatingRow className="align-items-center">
                  <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={6}
                    xxl={7}
                    className="ps-0"
                  >
                    <FloatingTitle>{study.name}</FloatingTitle>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={6}
                    xxl={5}
                    className="pe-0"
                  >
                    {(study.categoria !== 10 && study.categoria !== 8 && study.ventaOnline!==0) || isDoctor ? (
                    <Row className="p-0 justify-content-around">
                      <WhiteButton onClick={() => addStudy()} pink={study.ventaEspecial === 1}>
                        Agregar estudio
                      </WhiteButton>
                      <BlueButton onClick={() => payStudy()} pink={study.ventaEspecial === 1}>
                        Pagar ahora
                      </BlueButton>
                    </Row>
                    ): (
                      <OrangeButton onClick={()=>callToSchedule()} pink={study.ventaEspecial === 1}>Llamar {!isMobile && "para agendar"}</OrangeButton>
                    )}
                  </Col>
                </FloatingRow>
              </FloatingTestContainer>
            </FloatingTest>
          </MainContainer>
        </div>
      </div>
      )}
      
    </>
  );
};
