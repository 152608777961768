import exampleBanner from '../../assets/blog/bannerExampleCategory.png'

export const TEXT_BLACK = '#474A4B';
export const ORANGE = '#30DF99';
export const AQUA = '#30DF99';
export const FOSFO_GREEN = '#CEF719';

export const CATEOGORIES = [
    {
        id: 0,
        name: 'Laboratorio clínico',
        link: 'laboratorio-clinico',
        color: '#0CAC8C',
        desc: 'Los laboratorios clínicos <span>son esenciales para el sistema de atención médica y brindan importantes herramientas de diagnóstico y monitoreo</span> que ayudan a mejorar los resultados de los pacientes y promueven una mejor salud.',
        banner: exampleBanner,
    },
    {
        id: 1,
        name: 'Servicios genéticos',
        link: 'servicios-geneticos',
        color: '#4D99E5',
        desc: 'Al brindar acceso a pruebas genéticas, asesoramiento y otros tipos de apoyo, los servicios genéticos pueden <span>ayudar a mejorar los resultados de salud y promover una mejor salud para todos.</span>',
        banner: exampleBanner,
    },
    {
        id: 2,
        name: 'Patología quirúrgica',
        link: 'patologia-quirurgica',
        color: 'linear-gradient(90deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)',
        desc: 'Además del diagnóstico, la patología quirúrgica también puede desempeñar un papel importante en la <span>investigación y el desarrollo de nuevos tratamientos</span> para enfermedades.',
        banner: exampleBanner,
    },
    {
        id: 3,
        name: 'Biología molecular',
        link: 'biologia-molecular',
        color: '#A28EDC',
        desc: 'Mediante el estudio de las bases moleculares de la actividad biológica, los biólogos moleculares están <span>desarrollando nuevas terapias y tratamientos</span> para una amplia gama de enfermedades.',
        banner: exampleBanner,
    },
    {
        id: 4,
        name: 'Nutrición',
        link: 'nutricion',
        color: '#30DF99',
        desc: 'La investigación sobre nutrición está en constante evolución. La genómica nutricional, por ejemplo, se centra en comprender cómo las <span>variaciones genéticas pueden afectar el metabolismo</span> de los nutrientes y los resultados de salud.',
        banner: exampleBanner,
    },
    {
        id: 5,
        name: 'Covid-19',
        link: 'covid-19',
        color: '#3BD1E6',
        desc: 'Es importante tener en cuenta que <span>la situación de COVID-19 puede cambiar rápidamente</span> y se recomienda verificar periódicamente la información actualizada de fuentes confiables.',
        banner: exampleBanner,
    },
    
    
]

export const allowedUsersToPost = [
    'yipFBpVPlJe47NnUwNmSekBRJ3l1',
    'jFwF6DzENqUb8Ya5hZSvPYS242X2',
    'qV85lUsgI9U8K2VRGmOK3Ci8fhs2',
    'd0lpxmUyc7SfUG2sk2B8Lc8EiyJ3',
    'v2g9SPEEwlSV1gPvXZIlbLbkho92',
    'mp3EP3WrR1hHULdWM0pyoBlwDdm1'
]